<template>
	<form v-if="customer" @input="$emit('change', customer)" @submit.prevent>
		<div class="bg-white rounded-sm border border-gray-200">
			<!-- Body -->
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-user-3-line" class="text-gray-400 mr-1" />
				<div>Datos de cliente</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
						<div>
							<label class="block text-sm font-medium mb-1">Tipo de cliente</label>
							<select v-model="customer.customerType" class="form-select w-full">
								<option :value="undefined" disabled="true">Elige el tipo de cliente</option>
								<option
									v-for="opt in [
										{ label: 'Empresa', value: 'business' },
										{ label: 'Autónomo', value: 'freelance' },
										{ label: 'Residencial', value: 'individual' }
									]"
									:key="opt.value"
									:value="opt.value"
								>
									{{ opt.label }}
								</option>
							</select>
						</div>
						<div v-if="customer.customerType == 'business'">
							<label class="block text-sm font-medium mb-1">Razón social</label>
							<input class="form-input w-full" type="text" v-model="customer.companyName" />
						</div>
						<div>
							<div class="flex items-center justify-between">
								<label class="block text-sm font-medium mb-1" for="tooltip">Nombre </label>
							</div>
							<input class="form-input w-full" v-model="customer.name" type="text" required="true" />
						</div>
						<div><label class="block text-sm font-medium mb-1" for="mandatory">Apellidos</label><input class="form-input w-full" v-model="customer.lastname" type="text" required="true" /></div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white rounded-sm border border-gray-200">
			<!-- Body -->
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-map-pin-line" class="text-gray-400 mr-1" />
				<div>Ubicación</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
						<div><label class="block text-sm font-medium mb-1">Dirección</label><input class="form-input w-full" type="text" v-model="customer.address.full" /></div>
						<div><label class="block text-sm font-medium mb-1">Provincia</label><input class="form-input w-full" type="text" v-model="customer.address.state" /></div>
						<div><label class="block text-sm font-medium mb-1">Municipio</label><input class="form-input w-full" type="text" v-model="customer.address.city" /></div>
						<div><label class="block text-sm font-medium mb-1">Código Postal</label><input class="form-input w-full" type="text" v-model="customer.address.zipcode" /></div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<!-- PHONES -->
	<div class="bg-white rounded-sm border border-gray-200">
		<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<div class="flex items-center">
				<icon name="ri-phone-line" class="text-gray-400 mr-1" />
				<div>Teléfonos ({{ customer.phones.length }})</div>
			</div>
			<div>
				<Button color="secondary" size="sm" @click="togglePhoneForm">
					<icon name="ri-add-circle-line" class="mr-1" />
					{{ !isAddPhoneOpen ? 'Añadir Teléfono' : 'Cancelar' }}
				</Button>
			</div>
		</div>

		<div v-show="isAddPhoneOpen" class="p-5">
			<div class="mb-4">
				<label for="number" class="block text-sm font-medium mb-1">Introduce el Teléfono</label>
				<input class="form-input w-full" type="text" v-model="number" id="number" name="number" />
				<p class="text-red-600">
					<small>{{ getErrorMessage('number', phoneErrors) }}</small>
				</p>
			</div>
			<div class="flex flex-wrap gap-5">
				<Button @click="$emit('addPhone', { number })" :loading="isUpdatingPhoneNumber" :disabled="isUpdatingPhoneNumber" loadingLabel="Añadiendo teléfono...">
					<icon name="ri-save-2-line" class="mr-1" />
					Guardar teléfono
				</Button>
				<Button color="secondary" size="xs" @click="cancelAddPhone" :disabled="isUpdatingPhoneNumber"> Cancelar </Button>
			</div>
		</div>

		<div class="text-gray-800 border-b border-gray-200 max-h-[300px] overflow-hidden">
			<div v-if="!customer.phones.length && !isAddPhoneOpen" class="text-sm p-5">
				<p>No se ha registrado ningún teléfono de cliente.</p>
			</div>
			<div class="overflow-x-auto" v-show="customer.phones.length">
				<table class="table-auto w-full">
					<!-- Table header -->
					<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-gray-200">
						<tr>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Teléfono</div>
							</th>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Fecha creación</div>
							</th>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Acciones</div>
							</th>
						</tr>
					</thead>
					<!-- Table body -->
					<tbody class="text-sm divide-y divide-gray-200 text-gray-500">
						<tr v-for="phone in customer.phones" :key="phone.id" class="even:bg-gray-200">
							<td class="pl-5 p-2">{{ phone.number }}</td>
							<td class="pl-5 p-2">{{ dayjs(phone.createdAt).format('DD-MM-YYYY HH:mm') }}</td>
							<td class="pl-5 p-2">
								<Button size="sm" color="danger" :loading="isDeletingPhoneNumber" loadingLabel="Eliminando" @click="$emit('deletePhone', { id: phone.id })"> Eliminar </Button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- EMAILS -->
	<div class="bg-white rounded-sm border border-gray-200">
		<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<div class="flex items-center">
				<icon name="ri-mail-line" class="text-gray-400 mr-1" />
				<div>Emails ({{ customer.emails.length }})</div>
			</div>
			<div>
				<Button color="secondary" size="sm" @click="toggleEmailForm" :disabled="isUpdatingEmail">
					<icon name="ri-add-circle-line" class="mr-1" />
					{{ !isAddEmailOpen ? 'Añadir email' : 'Cancelar' }}
				</Button>
			</div>
		</div>

		<div v-show="isAddEmailOpen" class="p-5 mb-4">
			<div class="mb-4">
				<label class="block text-sm font-medium mb-1" for="address">Introduce el email</label>
				<input class="form-input w-full" type="text" v-model="address" name="address" id="address" />
				<p class="text-red-600">
					<small>{{ getErrorMessage('address', emailErrors) }}</small>
				</p>
			</div>
			<div class="flex flex-wrap gap-5">
				<Button @click="$emit('addEmail', { address })" :loading="isUpdatingEmail" :disabled="isUpdatingEmail" loadingLabel="Añadiendo email...">
					<icon name="ri-save-2-line" class="mr-1" />
					Guardar email
				</Button>
				<Button color="secondary" size="xs" @click="cancelAddEmail" :disabled="isUpdatingEmail"> Cancelar </Button>
			</div>
		</div>

		<div class="text-gray-800 border-b border-gray-200 max-h-[300px] overflow-hidden">
			<div v-if="!customer.emails.length && !isAddEmailOpen" class="text-sm p-5">
				<p>No se ha registrado ningún email del cliente.</p>
			</div>
			<div class="overflow-x-auto" v-show="customer.emails.length">
				<table class="table-auto w-full">
					<!-- Table header -->
					<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-gray-200">
						<tr>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Email</div>
							</th>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Fecha creación</div>
							</th>
							<th class="pl-5 p-2 whitespace-nowrap">
								<div class="font-semibold text-left">Acciones</div>
							</th>
						</tr>
					</thead>
					<!-- Table body -->
					<tbody class="text-sm divide-y divide-gray-200 text-gray-500">
						<tr v-for="(email, key) in customer.emails" :key="key" class="even:bg-gray-200">
							<td class="pl-5 p-2">{{ email.address }}</td>
							<td class="pl-5 p-2">{{ dayjs(email.createdAt).format('DD-MM-YYYY HH:mm') }}</td>
							<td class="pl-5 p-2">
								<button @click="$emit('deleteEmail', { id: email.id })" class="btn-xs border-red-200 hover:border-red-300 text-white bg-red-600 disabled:bg-red-400 disabled:cursor-not-allowed" :disabled="isDeletingEmail">
									<icon v-if="isDeletingEmail" name="ri-loader-4-line" class="mr-1" animation="spin" />
									<span>Eliminar</span>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="bg-white rounded-sm border border-gray-200">
		<!-- Body -->
		<div class="flex flex-column items-center text-xs text-gray-800 font-semibold uppercase p-5 border-b border-gray-200">
			<icon name="ri-message-2-line" class="text-gray-400 mr-1" />
			<div>Comentarios ({{ customer.comments.length }})</div>
		</div>

		<div class="flex flex-col items-center text-gray-800 border-b border-gray-100 max-h-[300px] overflow-hidden">
			<div class="v-h-full overflow-y-auto w-full">
				<template v-if="customer.comments.length">
					<div v-for="(comment, key) in customer.comments" :key="key" class="p-5 first:pt-5 pt-2 w-full odd:bg-gray-200">
						<div class="text-xs text-gray-500 font-medium" :title="dayjs(comment.createdAt).format('DD/MM/YYYY HH:mm')">{{ dayjs(comment.createdAt).fromNow() }}</div>
						<div class="flex items-center justify-between mb-1.5">
							<div class="truncate">
								<!-- | TODO add user data if logged in user has permissions-->
								<!-- <span class="text-sm font-semibold text-gray-800">{{comment.user.fullName}}</span> -->
							</div>
						</div>
						<div class="text-xs font-medium text-gray-800 truncate mb-0.5">Comentario:</div>
						<div class="text-xs whitespace-normal">{{ comment.content }}</div>
					</div>
				</template>
			</div>
		</div>

		<div class="p-3">
			<div class="mb-2">
				<textarea v-model="comment" rows="4" class="form-input w-full" :class="{ 'border-red-600': !!getErrorMessage('content', commentErrors) }"></textarea>
				<p class="text-red-600">
					<small>{{ getErrorMessage('content', commentErrors) }}</small>
				</p>
			</div>
			<Button color="secondary" @click="$emit('addComment', { content: comment })" loadingLabel="...guardando" :loading="updatingComment" :disabled="updatingComment">
				<icon name="ri-save-2-line" class="mr-1" />
				Guardar comentario
			</Button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { computed, ref, watch } from 'vue'
import { Customer } from '../../interfaces/Customer'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Button from '../Button.vue'

const emit = defineEmits(['change', 'addComment', 'addPhone', 'addEmail', 'deletePhone', 'deleteEmail'])
const getErrorMessage = useValidationErrorsMessages()

const props = defineProps<{
	customer: Customer
	updatingComment: boolean
	commentSaved: boolean
	isUpdatingPhoneNumber: boolean
	isPhoneNumberUpdated: boolean
	isUpdatingEmail: boolean
	isEmailUpdated: boolean
	errors: Record<string, Record<string, any>[]>
	isDeletingPhoneNumber: boolean
	isDeletingEmail: boolean
}>()
const customer = computed(() => props.customer)
const comment = ref('')
const number = ref('')
const address = ref('')
const isAddPhoneOpen = ref(false)
const isAddEmailOpen = ref(false)
const phoneErrors = ref([])
const emailErrors = ref([])
const commentErrors = ref([])
const generalErrors = ref([])
const isDeletingPhoneNumber = ref(false)

const togglePhoneForm = () => {
	isAddPhoneOpen.value = !isAddPhoneOpen.value
	if (!isAddPhoneOpen.value) {
		number.value = ''
	}
	phoneErrors.value = []
}

const cancelAddPhone = () => {
	number.value = ''
	isAddPhoneOpen.value = false
	phoneErrors.value = []
}

const toggleEmailForm = () => {
	isAddEmailOpen.value = !isAddEmailOpen.value
	if (!isAddEmailOpen.value) {
		address.value = ''
	}
	emailErrors.value = []
}

const cancelAddEmail = () => {
	address.value = ''
	isAddEmailOpen.value = false
	emailErrors.value = []
}

watch(props, () => {
	commentErrors.value = props.errors?.commentErrors || []
	phoneErrors.value = props.errors?.phoneErrors || []
	emailErrors.value = props.errors?.emailErrors || []
	generalErrors.value = props.errors?.generalErrors || []

	if (props.isPhoneNumberUpdated) {
		cancelAddPhone()
	}
	if (props.isEmailUpdated) {
		cancelAddEmail()
	}
	if (props.commentSaved) {
		comment.value = ''
		commentErrors.value = []
	}
})
</script>
