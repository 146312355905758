import Login from '@/apps/go/pages/Login.vue'
import Logout from '@/apps/go/pages/Logout.vue'
import MapPage from '@/apps/go/pages/MapPage.vue'
import PageNotFound from '@/apps/go/pages/PageNotFound.vue'
import RecoverPassword from '@/apps/go/pages/RecoverPassword.vue'
import ResetPassword from '@/apps/go/pages/ResetPassword.vue'
import SetPassword from '@/apps/go/pages/SetPassword.vue'
import Cups from '@/apps/go/pages/deal/Cups.vue'
import DealActionList from '@/apps/go/pages/deal/DealActionList.vue'
import DealPage from '@/apps/go/pages/deal/DealPage.vue'
import DealAdminActionForm from '@/apps/go/pages/deal/DealAdminActionForm.vue'
import DealContract from '@/apps/go/pages/deal/DealContract.vue'
import DealCustomer from '@/apps/go/pages/deal/DealCustomer.vue'
import DealHistory from '@/apps/go/pages/deal/DealHistory.vue'
import DealNotifications from '@/apps/go/pages/deal/DealNotifications.vue'
import DealSupply from '@/apps/go/pages/deal/DealSupply.vue'
import DealLocation from '@/apps/go/pages/deal/DealLocation.vue'
import LeadLocation from '@/apps/go/pages/deal/LeadLocation.vue'
import PermissionForm from '@/apps/go/pages/permission/PermissionForm.vue'
import PermissionList from '@/apps/go/pages/permission/PermissionList.vue'
import ProjectForm from '@/apps/go/pages/project/ProjectForm.vue'
import ProjectList from '@/apps/go/pages/project/ProjectList.vue'
import RoleForm from '@/apps/go/pages/role/RoleForm.vue'
import RoleList from '@/apps/go/pages/role/RoleList.vue'
import UserForm from '@/apps/go/pages/users/UserForm.vue'
import UserGroupForm from '@/apps/go/pages/users/UserGroupForm.vue'
import UserGroupList from '@/apps/go/pages/users/UserGroupList.vue'
import UserList from '@/apps/go/pages/users/UserList.vue'
import UserProfile from '@/apps/go/pages/users/UserProfile.vue'
import RoutesApiList from './pages/Routes/RoutesApiList.vue'
import RoutesList from './pages/Routes/RoutesList.vue'
import LeadImport from './pages/lead/LeadImport.vue'
import LeadGeolocalize from './pages/lead/LeadGeolocalize.vue'
import PortfolioPage from './pages/deal/PortfolioPage.vue'
import PortfolioAdminPage from './pages/deal/PortfolioAdminPage.vue'
import ErrorPage from './pages/error/ErrorPage.vue'
import { auth } from '@/auth'

export const routes = [
	{
		name: 'go.login',
		path: '/login',
		component: Login
	},
	{
		name: 'go.reset-password',
		path: '/reset-password/:token',
		component: ResetPassword
	},
	{
		name: 'go.set-password',
		path: '/set-password/:token',
		component: SetPassword
	},
	{
		name: 'go.recover-password',
		path: '/recover-password',
		component: RecoverPassword
	},
	{
		name: 'go.logout',
		path: '/logout',
		component: Logout
	},
	{
		name: 'go.map',
		path: '/',
		component: MapPage,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.users',
		path: '/users',
		component: UserList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.newUser',
		path: '/users/new',
		component: UserForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.leadImport',
		path: '/lead/import',
		component: LeadImport,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.leadGeolocalize',
		path: '/lead/geolocalize',
		component: LeadGeolocalize,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.leadLocation',
		path: '/lead/location',
		component: LeadLocation,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealDashboard',
		path: '/deal',
		component: DealPage,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealCustomer',
		path: '/deal/customer',
		component: DealCustomer,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealContract',
		path: '/deal/contract',
		component: DealContract,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealSupply',
		path: '/deal/supply',
		component: DealSupply,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealLocation',
		path: '/deal/location',
		component: DealLocation,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.deals',
		path: '/portfolio',
		component: PortfolioPage,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.opportunities',
		path: '/manager/portfolio',
		component: PortfolioAdminPage,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealActions',
		path: '/deal-actions/',
		component: DealActionList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealHistory',
		path: '/deal/history',
		component: DealHistory,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealNotifications',
		path: '/deal/notifications',
		component: DealNotifications,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.dealAdminStatusForm',
		path: '/deal/admin-status-form',
		component: DealAdminActionForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.userProfile',
		path: '/user/profile',
		component: UserProfile,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.cupsGet',
		path: '/cups',
		component: Cups,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.userGroupList',
		path: '/user/groups',
		component: UserGroupList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.newUserGroup',
		path: '/user/group/new',
		component: UserGroupForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.userGroupUpdate',
		path: '/user/group/:_id',
		component: UserGroupForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.permissionList',
		path: '/permissions',
		component: PermissionList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.newPermission',
		path: '/permission/new',
		component: PermissionForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.permission',
		path: '/permission/:_id',
		component: PermissionForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.projectList',
		path: '/projects',
		component: ProjectList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.newProject',
		path: '/project/new',
		component: ProjectForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.project',
		path: '/project/:_id',
		component: ProjectForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.roleList',
		path: '/roles',
		component: RoleList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.newRole',
		path: '/role/new',
		component: RoleForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.role',
		path: '/role/:_id',
		component: RoleForm,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.routesList',
		path: '/routes/front',
		component: RoutesList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.routesApiList',
		path: '/routes/api',
		component: RoutesApiList,
		meta: {
			auth: true
		}
	},
	{
		name: 'go.error',
		path: '/error',
		component: ErrorPage,
		meta: {
			env: 'development'
		}
	},
	{
		path: '/:pathMatch(.*)*',
		component: PageNotFound
	}
]
