<template>
	<div v-show="activeTab.name == name" :class="`${activeTab.name == name ? 'fadeIn' : 'fadeOut'}`">
		<slot v-if="activeTab.name == name" />
	</div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

defineProps<{
	name: String
	title: String
	iconName?: String
}>()
const activeTab: any = inject('activeTab')
</script>
<style lang="scss" scoped>
.fadeIn {
	animation: fadeIn 0.7s forwards;
}
.fadeOut {
	animation: fadeIn 0.7s reverse;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
