<template>
	<Default :loading="loading">
		<div class="relative w-full h-full">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button icon="ri-arrow-left-line" color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="lead">
							<Button :loading="updating" :disabled="updating" icon="ri-save-2-line" @click="update">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>
				<div class="grow p-3 bg-gray-100">
					<Banner type="success" class="mb-3" :open="isUpdated"> Actualizado correctamente </Banner>
					<Banner type="error" class="mb-3" :open="!!errors.length">
						<ul>
							<li v-for="error in errors" :key="error">
								{{ error.message }}
							</li>
						</ul>
					</Banner>
					<form v-if="lead">
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-map-pin-line" class="text-gray-400 mr-1" />
								<div>Coordenadas</div>
							</div>
							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div><label class="block text-sm font-medium mb-1">Latitud</label><input class="form-input w-full" type="number" step="0.0001" v-model="lead.loc.coordinates[1]" /></div>
										<div><label class="block text-sm font-medium mb-1">Longitud</label><input class="form-input w-full" type="number" step="0.0001" v-model="lead.loc.coordinates[0]" /></div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'
import { get, put } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()
const errors = ref([])
const loading = ref(false)
const updating = ref(false)
const isUpdated = ref(false)
const lead = ref(null)
const error = ref({})

const back = () => {
	history.back()
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'lead.preview', params: { project: route.query.project, _id: route.query._id }, query: { skipSync: 'true' } })
		lead.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	fetch()
})

const update = async () => {
	loading.value = true
	updating.value = true
	isUpdated.value = false
	errors.value = []
	try {
		await put({ name: 'lead.loc', params: { project: route.query.project, _id: route.query._id } }, {
			lat: lead.value.loc.coordinates[1],
			lng: lead.value.loc.coordinates[0]
		})
	} catch (error) {
		errors.value = error.errors || []
	}

	isUpdated.value = errors.value.length ? false : true
	updating.value = false
	loading.value = false
}
</script>
