<template>
	<Default>
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Rutas</h1>
						<!-- More actions -->
					</div>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								<div>
									<p>
										Resultados:
										<span class="text-slate-400">{{ routes.length }}</span>
									</p>
								</div>
							</header>
						</div>

						<!-- Table -->
						<div class="overflow-x-auto" v-show="routes.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('name')">
													<p class="uppercase mr-1">Nombre</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'name'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('path')">
													<p class="uppercase mr-1">Path</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'path'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('component')">
													<p class="uppercase mr-1">Componente</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'component'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Meta</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr class="even:bg-gray-100 hover:bg-gray-200" v-for="item in routes" :key="item._id">
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.name }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.path }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.component }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="flex flex-wrap items-center gap-2">
												<badge randomColor v-for="[key, value] in Object.entries(item.meta || {})" :cached-random-color-key="key" class="m-[.1em]">{{ key }}: {{ value }}</badge>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import Badge from '../../components/Badge.vue'
import Button from '../../components/Button.vue'
import Default from '../Default.vue'

const router = useRouter()
const sort = ref('')
const routes = ref([])
routes.value = router.options.routes.map(route => ({
	name: route.name,
	path: route.path,
	component: (<any>route.component).__name ? (<any>route.component).__name + '.vue' : '-',
	meta: route.meta
}))

const sortBy = reactive({ field: (<string>sort.value || '')?.split('-')[0], type: sort.value?.includes('-') ? '-' : '' })

const applySort = () => {
	routes.value?.sort((a, b) => {
		if (!sortBy.type) {
			if (a[sortBy.field] < b[sortBy.field]) return -1
			if (a[sortBy.field] > b[sortBy.field]) return 1
		} else {
			if (b[sortBy.field] < a[sortBy.field]) return -1
			if (b[sortBy.field] > a[sortBy.field]) return 1
		}
		return 0
	})
}
const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	sort.value = `${sortBy.type}${sortBy.field}`
	applySort()
}
</script>
