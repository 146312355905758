<template>
	<Default :loading="loading">
		<div class="relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-2 sm:px-6 md:px-5 h-14">
						<div class="flex">
							<Button icon="ri-arrow-left-line" color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="payload">
							<Button :loading="saving" icon="ri-save-2-line" @click="save" :disabled="saving || roleExists">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
					<Banner type="error" :open="!!error.message" @onClose="onCloseBanner"> {{ error.message }} </Banner>
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Rol {{ route.params._id ? 'actualizado' : 'creado' }} correctamente </Banner>
				</div>
				<div class="grow h-full p-3 bg-gray-100" v-if="payload">
					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-shield-line" class="text-gray-400 mr-1" />
								<p>Datos de Rol</p>
							</div>
							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="role">Rol <span class="text-red-500">*</span> </label>
												<Tooltip bg="dark" size="sm" position="left">
													<!-- <Button>
													</Button> -->
													<template v-slot:button>
														<icon name="ri-information-line" />
													</template>

													<template v-slot:tooltip>
														<div class="text-sm text-gray-200">El rol deberá ser escrito en ingles, sin acentos, caracteres especiales ni espacios. Para más de una palabra, iniciar con minúsculas y las siguientes palabra en mayúscula (camelCase). Ej: <b>userAdmin</b>, <b>territorialDirector</b></div>
													</template>
												</Tooltip>
											</div>
											<input class="form-input w-full disabled:text-gray-600 disabled:bg-gray-200" type="text" name="role" v-model="payload.role" id="role" required="true" @input="checkRole" :readonly="!!_id" :disabled="!!_id" :class="{ 'border-red-600': !!getErrorMessage('role') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('role') }}</small>
											</p>
											<p v-if="payload.role">
												<small v-if="checkingRoleExists"> <icon name="ri-loader-4-line" scale=".8" class="mr-1" animation="spin" /> Chequeando rol... </small>
												<small v-else-if="!roleExists" class="text-green-600"> <icon scale=".8" name="ri-check-line" class="mr-1" />Rol válido </small>
												<small v-else class="text-red-600"> <icon name="ri-close-line" scale=".8" class="mr-1" /> El rol ya existe </small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="label">Nombre a mostrar <span class="text-red-500">*</span></label
											><input class="form-input w-full" type="text" name="label" v-model="payload.label" id="label" required="true" :class="{ 'border-red-600': !!getErrorMessage('label') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('label') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="description">Descripción <span class="text-red-500">*</span></label
											><textarea class="form-input w-full" name="description" v-model="payload.description" id="description" required="true" :class="{ 'border-red-600': !!getErrorMessage('description') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('description') }}</small>
											</p>
											<!-- End -->
										</div>

										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="maxInactivityTime">Máximo tiempo de inactividad <span class="text-red-500">*</span> </label>
												<Tooltip bg="dark" size="sm" position="left">
													<!-- <Button>
													</Button> -->
													<template v-slot:button>
														<icon name="ri-information-line" />
													</template>

													<template v-slot:tooltip>
														<div class="text-sm text-gray-200">El tiempo de inactividad se expresa en segundos e indica que pasado X tiempo la sesión del usuario se cerrará automáticamente.</div>
													</template>
												</Tooltip>
											</div>
											<div class="flex w-full rounded border" :class="{ 'border-red-600': !!getErrorMessage('config.maxInactivityTime') }">
												<input class="relative form-input w-full border-none" type="number" step="60" min="0" name="maxInactivityTime" v-model="payload.config.maxInactivityTime" id="maxInactivityTime" required="true" @input="onInputInactivity" />
												<div class="flex-1 rounded flex items-center whitespace-nowrap pr-2">
													<small>{{ humanizedTime }} </small>
												</div>
											</div>
											<p class="text-red-600">
												<small>{{ getErrorMessage('config.maxInactivityTime') }}</small>
											</p>
											<!-- End -->
										</div>

										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="maxMetersToReport">Máxima distancia para reportar <span class="text-red-500">*</span> </label>
												<Tooltip bg="dark" size="sm" position="left">
													<!-- <Button>
													</Button> -->
													<template v-slot:button>
														<icon name="ri-information-line" />
													</template>

													<template v-slot:tooltip>
														<div class="text-sm text-gray-200">La distancia máxima en metros se usará como el límite para determinar si un usuario puede realizar un reporte en una oportunidad.</div>
													</template>
												</Tooltip>
											</div>
											<div class="flex w-full rounded border" :class="{ 'border-red-600': !!getErrorMessage('config.maxMetersToReport') }">
												<input class="relative form-input w-full border-none" type="number" step="60" min="0" name="maxMetersToReport" v-model="payload.config.maxMetersToReport" id="maxMetersToReport" required="true" @input="onInputInactivity" />
											</div>
											<p class="text-red-600">
												<small>{{ getErrorMessage('config.maxMetersToReport') }}</small>
											</p>
											<!-- End -->
										</div>

										<div class="col-span-full">
											<!-- Start -->
											<div class="mb-3">
												<p class="text-gray-800 font-semibold uppercase">Mapa</p>
												<p>Configura las opciones del mapa</p>
											</div>

											<div class="grid text-sm gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
												<div>
													<!-- Start -->
													<label class="block text-sm font-medium mb-1" for="distance">Radio por defecto (metros) <span class="text-red-500">*</span></label
													><input type="number" class="form-input w-full" min="0" name="distance" v-model="payload.config.distance" id="distance" required="true" step="1" :class="{ 'border-red-600': !!getErrorMessage('config.distance') }" />
													<p class="text-red-600">
														<small>{{ getErrorMessage('config.distance') }}</small>
													</p>
													<!-- End -->
												</div>
												<div>
													<!-- Start -->
													<label class="block text-sm font-medium mb-1" for="maxDistance">Radio Máximo (metros) <span class="text-red-500">*</span></label
													><input type="number" class="form-input w-full" min="0" name="maxDistance" v-model="payload.config.maxDistance" id="maxDistance" required="true" step="1" :class="{ 'border-red-600': !!getErrorMessage('config.maxDistance') }" />
													<p class="text-red-600">
														<small>{{ getErrorMessage('config.maxDistance') }}</small>
													</p>
													<!-- End -->
												</div>

												<div>
													<label class="block text-sm font-medium mb-1" for="limit">Límite por defecto <span class="text-red-500">*</span></label
													><input type="number" class="form-input w-full" min="0" name="limit" v-model="payload.config.limit" id="limit" required="true" step="1" :class="{ 'border-red-600': !!getErrorMessage('config.limit') }" />
													<p class="text-red-600">
														<small>{{ getErrorMessage('config.limit') }}</small>
													</p>
												</div>

												<div>
													<label class="block text-sm font-medium mb-1" for="maxLimit">Límite máximo<span class="text-red-500">*</span></label
													><input type="number" class="form-input w-full" min="0" name="maxLimit" v-model="payload.config.maxLimit" id="maxLimit" required="true" step="1" :class="{ 'border-red-600': !!getErrorMessage('config.maxLimit') }" />
													<p class="text-red-600">
														<small>{{ getErrorMessage('config.maxLimit') }}</small>
													</p>
												</div>
											</div>

											<!-- End -->
										</div>

										<div class="col-span-full">
											<!-- Start -->
											<div class="mb-3">
												<p class="text-gray-800 font-semibold uppercase">Permisos</p>
												<p>Selecciona los permisos que tendrá el rol</p>
											</div>

											<div class="grid text-sm gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
												<div v-for="[category, permissions] in Object.entries(availablePermissions)" :key="category">
													<Accordion :title="permissionCategories[category]">
														<div class="mt-2">
															<div v-for="permission in permissions" :key="permission">
																<label class="block text-sm mb-1" :for="permission._id">
																	<input :name="permission._id" :id="permission._id" type="checkbox" class="form-checkbox" v-model="payload.permissions" :value="permission.permission" />
																	<span class="text-sm ml-2">
																		{{ permission.label }}
																	</span>
																</label>
															</div>
														</div>
													</Accordion>
												</div>
											</div>

											<!-- End -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { user } from '@/user'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { dayjs } from '../../../../services/dayjs'
import Accordion from '../../components/Accordion.vue'
import Button from '../../components/Button.vue'
import Tooltip from '../../components/Tooltip.vue'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'
import { Role } from '../../interfaces/Role'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const roles = ref([])
const availablePermissions = ref<Record<string, any>>({})

const loading = ref(false)
const saving = ref(false)
const isSaved = ref(false)
const roleExists = ref(false)
const checkingRoleExists = ref(false)
const initialRoleEdit = ref(null)
const humanizedTime = ref('')
const _id = route.params._id

let payload = reactive<{
	_id?: string
	role: string
	label: string
	description?: string
	config: any
	permissions: string[]
}>(<any>{ config: { maxInactivityTime: 1800 }, permissions: [] })

const getErrorMessage = useValidationErrorsMessages(error)

const back = () => {
	history.back()
}

const onInputInactivity = () => {
	humanizedTime.value = dayjs.duration((payload.config.maxInactivityTime || 0) * 1000).humanize()
}

const fetchRoles = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'role', query: { limit: 1000, projection: 'role,label' } })
		roles.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = !(!!roles.value.length && !!Object.keys(availablePermissions.value).length)
}

const permissionCategories = {
	users: 'Gestión de usuarios',
	deals: 'Gestión de oportunidades',
	extra: 'Funcionalidades adicionales',
	permissions: 'Gestión de roles y permisos',
	others: 'Otros'
}
const fetchPermissions = async () => {
	loading.value = true

	error.value = <any>{}
	try {
		availablePermissions.value = {}
		const { data } = await get({ name: 'permission', query: { projection: 'permission,label,private,category' } })
		availablePermissions.value = data.reduce((acc, permission) => {
			if (user.value?.role != 'root' && permission.private) {
				return acc
			}
			if (!permission.category) {
				permission.category = 'others'
			}
			if (!acc[permission.category]) {
				acc[permission.category] = []
			}
			acc[permission.category].push(permission)
			return acc
		}, {})
	} catch (err) {
		error.value = err
	}
	loading.value = !(!!roles.value.length && !!Object.keys(availablePermissions.value).length)
}

let timer = null
const checkRole = ({ target }) => {
	if (timer) {
		clearInterval(timer)
	}
	checkingRoleExists.value = true
	timer = setInterval(() => {
		roleExists.value = !!roles.value.find(({ role }) => role === target.value && target.value !== initialRoleEdit.value)
		checkingRoleExists.value = false
	}, 300)
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'role', params: { _id: route.params._id }})
		const { createdAt, updatedAt, ...restData } = data
		payload = reactive(restData)
		initialRoleEdit.value = payload.role
		loading.value = !(!!roles.value.length && !!Object.keys(availablePermissions.value).length)
	} catch (err) {
		error.value = err
	}
}

onMounted(async () => {
	fetchPermissions()
	fetchRoles()
	onInputInactivity()
	if (_id) {
		fetch()
	}
})

const onCloseBanner = () => {
	isSaved.value = false
}

const save = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}

	try {
		if (_id) {
			await put({ name: 'role', params: { _id } }, payload)
			fetch()
		} else {
			await post({ name: 'role' }, payload)
			payload = reactive(<any>{ config: {}, permissions: [] })
		}
	} catch (err) {
		error.value = err
	}

	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}
</script>
