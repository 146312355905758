<template>
	<Default :loading="loading">
		<div class="relative flex h-full">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<Button icon="ri-arrow-left-line" color="tertiary" @click="back">
							<icon name="ri-arrow-left-line" class="mr-1" />
							Volver
						</Button>

						<Button type="submit" @click="sendForm" :loading="loading" :disabled="!!configHasErrors || loading">
							<icon name="ri-dave-2-line" class="mr-1" />
							Guardar
						</Button>
					</div>
				</div>

				<div v-if="!loading && status" class="grow p-3 bg-gray-100">
					<div class="mb-3">
						<h1 class="text-xl md:text-2xl text-gray-800 font-bold mb-3 flex-1">Editar acción</h1>
					</div>
					<div class="mb-4">
						<Banner type="success" :open="!!success" class="mb-2" @onClose="closeSuccessBanner">Acción actualizada correctamente.</Banner>
						<Banner type="error" :open="!!error.message">{{ error.message }}</Banner>
					</div>
					<div v-if="!loading" class="grid md:grid-cols-2 xl:grid-cols-3 gap-3">
						<div>
							<label for="type">Type <span class="text-red-500">*</span></label>
							<input class="form-input w-full pr-8 bg-yellow-100" type="text" v-model="status.type" disabled />
						</div>
						<div>
							<label for="label">Label <span v-if="configRef?.isStatus" class="text-red-500">*</span></label>
							<input class="form-input w-full pr-8" type="text" v-model="formData.label" />
						</div>
						<div class="col-span-full overflow-y-auto">
							<label for="config">Config <span class="text-red-500">*</span></label>
							<VueJsonEditor name="config" v-model.json="configRef" mode="text" class="jse-theme-dark" :darkTheme="true" @change="onChange" @input="onInput" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import VueJsonEditor from 'json-editor-vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import { get, put } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const status = ref(null)
const error = ref(null)
const configHasErrors = ref(null)
const configRef: Record<string, any> = ref({})
const formData = reactive<Record<string, any>>({})
const success = ref(false)

const onChange: (...args: any) => any = (_updatedContent, _previousContent, { contentErrors, patchResult }) => {
	configHasErrors.value = !!contentErrors
}

const onInput = (e: any) => {
	if (!configHasErrors.value) {
		formData.config = e.target.textContent
	}
}
const back = () => {
	history.back()
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'action', params: { _id: route.query._id } })
		status.value = data
		configRef.value = data.config
		Object.assign(formData, {
			label: data.label,
			config: data.config
		})
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const sendForm = async () => {
	loading.value = true

	if (typeof configRef.value === 'string') {
		formData.config = JSON.parse(formData.config)
	}

	try {
		const res = await put({ name: 'action', params: { _id: route.query._id }}, formData)
		success.value = true
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	fetch()
})
const closeSuccessBanner = () => {
	success.value = false
}
</script>
