<template>
	<Default :loading="loading">
		<div class="relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="payload">
							<Button :loading="saving" @click="save" :disabled="saving || projectExists">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>

				<div class="grow h-full p-3 bg-gray-100" v-if="payload">
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Proyecto {{ route.params._id ? 'actualizado' : 'creado' }} correctamente </Banner>
					<Banner type="error" class="mb-3" :open="!!error.message" @onClose="onCloseBanner"> {{ error.message }} </Banner>

					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-shield-line" class="text-gray-400 mr-1" />
								<div>Datos de Proyecto</div>
							</div>
							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="name">Proyecto <span class="text-red-500">*</span></label>
											</div>
											<input class="form-input w-full disabled:text-gray-600 disabled:bg-gray-200" type="text" name="name" v-model="payload.name" id="name" required="true" @input="checkProject" :class="{ 'border-red-600': !!getErrorMessage('name') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('name') }}</small>
											</p>
											<p v-if="payload.name">
												<small v-if="checkingProjectExists"> <icon name="ri-loader-4-line" scale=".8" class="mr-1" animation="spin" /> Chequeando proyecto... </small>
												<small v-else-if="!projectExists" class="text-green-600"> <icon scale=".8" name="ri-check-line" class="mr-1" /> Proyecto válido </small>
												<small v-else class="text-red-600"> <icon name="ri-close-line" scale=".8" class="mr-1" /> El proyecto ya existe </small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="label">Label <span class="text-red-500">*</span></label
											><input class="form-input w-full" type="text" name="label" v-model="payload.label" id="label" required="true" :class="{ 'border-red-600': !!getErrorMessage('label') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('label') }}</small>
											</p>
											<!-- End -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const projects = ref([])

const loading = ref(false)
const saving = ref(false)
const isSaved = ref(false)
const projectExists = ref(false)
const checkingProjectExists = ref(false)
const initialProjectEdit = ref(null)
const _id = route.params._id

let payload = reactive<{
	_id?: string
	name: string
	label: string
}>(<any>{})

const getErrorMessage = useValidationErrorsMessages(error)

const back = () => {
	history.back()
}

const fetchPermissions = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { limit: 1000, projection: 'label,name' } })
		projects.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

let timer = null
const checkProject = ({ target }) => {
	if (timer) {
		clearInterval(timer)
	}
	checkingProjectExists.value = true
	timer = setInterval(() => {
		projectExists.value = !!projects.value.find(({ name }) => name === target.value && target.value !== initialProjectEdit.value)
		checkingProjectExists.value = false
	}, 300)
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', params: { _id: route.params._id } })
		payload = reactive(data)
		initialProjectEdit.value = data.name
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	if (_id) {
		await fetch()
	}
	fetchPermissions()
})

const onCloseBanner = () => {
	isSaved.value = false
}

const save = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}

	try {
		if (_id) {
			await put({ name: 'project', params: { _id } }, { label: payload.label, name: payload.name })
			fetch()
		} else {
			await post({ name: 'project' }, payload)
			payload = reactive(<any>{})
		}
	} catch (err) {
		error.value = err
	}
	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}
</script>
