<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="payload">
							<Button :loading="saving" @click="save" :disabled="saving || permissionExists">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>

				<div class="grow h-full p-3 bg-gray-100" v-if="payload">
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Permiso {{ route.params._id ? 'actualizado' : 'creado' }} correctamente </Banner>
					<Banner type="error" class="mb-3" :open="!!error.message" @onClose="onCloseBanner"> {{ error.message }} </Banner>

					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-shield-line" class="text-gray-400 mr-1" />
								<div>Datos de permiso</div>
							</div>
							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="name">Permiso <span class="text-red-500">*</span></label>
											</div>
											<input class="form-input w-full disabled:text-gray-600 disabled:bg-gray-200" type="text" name="permission" v-model="payload.permission" id="permission" required="true" @input="checkPermission" :readonly="!!_id" :disabled="!!_id" :class="{ 'border-red-600': !!getErrorMessage('permission') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('permission') }}</small>
											</p>
											<p v-if="payload.permission">
												<small v-if="checkingPermissionExists"> <icon name="ri-loader-4-line" scale=".8" class="mr-1" animation="spin" /> Chequeando permiso... </small>
												<small v-else-if="!permissionExists" class="text-green-600"> <icon scale=".8" name="ri-check-line" class="mr-1" /> Permiso válido </small>
												<small v-else class="text-red-600"> <icon name="ri-close-line" scale=".8" class="mr-1" /> El permiso ya existe </small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="label">Label <span class="text-red-500">*</span></label
											><input class="form-input w-full" type="text" name="label" v-model="payload.label" id="label" required="true" :class="{ 'border-red-600': !!getErrorMessage('label') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('label') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<label class="block w-full" for="category">Categoría</label>
											<select name="category" id="category" v-model="payload.category" class="form-select w-full">
												<option :value="null" selected="true">Seleccionar categoría</option>
												<option v-for="[key, value] in Object.entries(permissionCategories)" :key="key" :value="key">
													{{ value }}
												</option>
											</select>
										</div>
										<div>
											<label class="block text-sm font-medium mb-1">El permiso es privado?</label>
											<label>
												<input type="checkbox" v-model="payload.private" class="form-checkbox" />
												<span class="text-sm ml-3">Si, es privado</span>
											</label>
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="description">Descripción <span class="text-red-500">*</span></label>
											<textarea class="form-input w-full" name="description" v-model="payload.description" id="description" required="true" :class="{ 'border-red-600': !!getErrorMessage('description') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('description') }}</small>
											</p>
											<!-- End -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const permissions = ref([])

const loading = ref(false)
const saving = ref(false)
const isSaved = ref(false)
const permissionExists = ref(false)
const checkingPermissionExists = ref(false)
const initialPermissionEdit = ref(null)
const _id = route.params._id

let payload = reactive<{
	_id?: string
	permission: string
	label: string
	description?: string
	private: boolean
	category: string
	createdAt?: string
	updatedAt?: string
	usersCount?: number
}>(<any>{ private: false })

const getErrorMessage = useValidationErrorsMessages(error)

const back = () => {
	history.back()
}

const permissionCategories = {
	users: 'Gestión de usuarios',
	deals: 'Gestión de oportunidades',
	extra: 'Funcionalidades adicionales',
	permissions: 'Gestión de roles y permisos'
}

const fetchPermissions = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'permission', query: { limit: 1000, projection: 'label,permission' } })
		permissions.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

let timer = null
const checkPermission = ({ target }) => {
	if (timer) {
		clearInterval(timer)
	}
	checkingPermissionExists.value = true
	timer = setInterval(() => {
		permissionExists.value = !!permissions.value.find(({ permission }) => permission === target.value && target.value !== initialPermissionEdit.value)
		checkingPermissionExists.value = false
	}, 300)
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'permission', params: { _id: route.params._id } })
		payload = reactive(data)
		initialPermissionEdit.value = data.permission
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	if (_id) {
		await fetch()
	}
	fetchPermissions()
})

const onCloseBanner = () => {
	isSaved.value = false
}

const save = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}

	try {
		if (_id) {
			const { createdAt, updatedAt, usersCount, ...restPayload } = payload
			await put ({ name: 'permission', params: { _id } }, restPayload)
			fetch()
		} else {
			await post({ name: 'permission' }, payload)
			payload = reactive(<any>{})
		}
	} catch (err) {
		error.value = err
	}
	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}
</script>
