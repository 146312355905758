<template>
	<button :disabled="disabled" class="btn" :class="[colorTypeClasses[color] || '', `btn-${size}`, { 'border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed': disabled }]">
		<icon v-if="loading" name="ri-loader-4-line" class="mr-1" animation="spin" /><span v-if="loading">{{ loadingLabel }}</span
		><slot v-if="!loading"></slot>
	</button>
</template>

<script setup lang="ts">
type ColorType = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success' | 'warning'
type Size = 'xs' | 'sm' | 'md' | 'lg'

withDefaults(
	defineProps<{
		loading?: boolean
		disabled?: boolean
		color?: ColorType
		size?: Size
		loadingLabel?: string
	}>(),
	{
		color: 'primary',
		size: 'md',
		disabled: false,
		loading: false,
		loadingLabel: 'Cargando...'
	}
)

const colorTypeClasses: Record<ColorType, string> = {
	primary: 'bg-primary-600 hover:bg-primary-500 text-white',
	secondary: 'border-gray-200 bg-secondary-600 hover:bg-secondary-400 text-white',
	tertiary: 'border-gray-200 hover:border-gray-300 text-gray-600',
	danger: 'bg-red-500 hover:bg-red-600 text-white',
	success: 'bg-green-500 hover:bg-green-600 text-white',
	warning: 'bg-yellow-300 hover:bg-yellow-400 text-gray-600'
}
</script>
