<template>
	<teleport to="body">
		<Loading v-show="loading" />
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="!loading">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-2/4" @click.stop>
						<div class="bg-gray-50 px-4 py-3 border-b">
							<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Datos contrato</h3>
						</div>
						<Banner type="success" :open="isUpdated"> Actualizado correctamente </Banner>
						<Banner type="error" :open="!!errors.length">
							<ul>
								<li v-for="error in errors" :key="error">
									{{ error.message }}
								</li>
							</ul>
						</Banner>
						<ContractForm v-if="deal?.contract" :data="deal.contract" @change="updateContract" @addComment="addComment" :updatingComment="updatingComment" :commentSaved="commentSaved" :error="commentError" />
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button class="ml-2" type="submit" @click="update" :loading="loading" :disabled="updating">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar
							</Button>
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cancelar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { onMounted, ref } from 'vue'
import Button from '../../components/Button.vue'
import ContractForm from '../../components/forms/ContractForm.vue'
import { get, post, put } from '../../services/api'
import Loading from '../../components/Loading.vue'
import { Deal } from '../../interfaces/Deal'

const errors = ref([])
const loading = ref(false)
const updating = ref(false)
const isUpdated = ref(false)
const deal = ref(null)
const updatingComment = ref(false)
const commentSaved = ref(false)
const commentError = ref(<any>{})
const error = ref({})
const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'deal', params: { project: props.project, _id: props.deal._id } })
		data.contract.comments.reverse()
		deal.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	fetch()
})

const updateContract = contract => {
	deal.value.contract = contract
}

const update = async (data: Record<string, any>) => {
	loading.value = true
	updating.value = true
	isUpdated.value = false
	errors.value = []
	try {
		await put({ name: 'deal.contract', params: { project: props.project, _id: props.deal._id } }, deal.value.contract)
	} catch (error) {
		errors.value = error.errors || []
	}
	isUpdated.value = errors.value.length ? false : true
	updating.value = false
	loading.value = false
}

const addComment = async (comment: { content: string }) => {
	updatingComment.value = true
	commentSaved.value = false
	commentError.value = {}
	try {
		const { data } = await post({ name: 'deal.contract.comment', params: { project: props.project, _id: props.deal._id } }, {  content: comment.content })
		deal.value.contract.comments.unshift(data)
		commentSaved.value = true
	} catch (error) {
		commentError.value = error
	}
	updatingComment.value = false
}
</script>
