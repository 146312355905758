<template>
	<main class="bg-white">
		<div class="relative flex">
			<!-- Content -->
			<div class="w-full md:w-1/2">
				<div class="min-h-screen h-full flex flex-col after:flex-1">
					<div class="flex-1">
						<div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
					</div>

					<div class="max-w-[480px] mx-auto px-4 py-8">
						<h1 class="text-3xl text-gray-800 font-bold mb-6">Restablecer contraseña</h1>
						<p>Introduce tu correo electrónico.</p>
						<Banner type="success" colo :open="requestSent"> Se ha enviado un email con las instrucciones para restablecer su contraseña </Banner>
						<!-- Form -->
						<form action="" @submit.prevent="submit">
							<div class="space-y-4 mt-2">
								<div>
									<label class="block text-sm font-medium mb-1" for="email">Email</label>
									<input v-model="email" class="form-input w-full" type="email" name="email" id="email" />
								</div>
							</div>
							<div class="flex items-center justify-between mt-6">
								<Button :loading="loading" :disabled="loading" loadingLabel="Enviando...">Enviar</Button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<!-- Image -->
			<div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
				<img class="object-cover object-center w-full h-full" :src="bgImage" width="760" height="1024" alt="Authentication" />
				<img class="absolute top-1/4 left-0 transform -translate-x-1/2 hidden lg:block" src="./../images/auth-decoration.png" width="218" height="224" alt="Authentication decoration" />
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { auth } from '@/auth'
import { user } from '@/user'
import { ref } from 'vue'
import Banner from '../components/Banner.vue'
import Button from '../components/Button.vue'
import { post } from '../services/api'
const product = import.meta.env.VITE_PRODUCT || 'energy'
const bgImage = new URL(`../../../apps/go/images/${product}/auth-bg.png`, import.meta.url).toString()
const email = ref(null)
const requestSent = ref(false)
const loading = ref(false)

localStorage.removeItem('auth')
localStorage.removeItem('user')
auth.value = null
user.value = null

const submit = async () => {
	loading.value = true
	try {
		await post({ name: 'auth.recover'}, { email: email.value})

		email.value = null
		requestSent.value = true
	} catch (error) {
		alert('Error al internar establecer contraseña.')
	}
	loading.value = false
}
</script>
