<template>
	<div class="bg-white rounded-sm border border-gray-200 mt-2">
		<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<icon name="ri-information-line" class="text-gray-400 mr-1" />
			<p>Información</p>
		</div>
		<div class="px-3">
			<ul class="flex flex-wrap gap-4 mb-2">
				<li v-if="props.agent">
					<sub class="text-xs">Gestor</sub>
					<div class="flex items-center">
						<icon name="ri-user-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold"> {{ props.agent.name }} </span>
					</div>
				</li>
				<li v-if="props.agent">
					<sub class="text-xs">Email</sub>
					<div class="flex items-center">
						<icon name="ri-mail-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold"> {{ props.agent.email }} </span>
					</div>
				</li>
				<li>
					<sub class="text-xs">Estado</sub>
					<div class="flex items-center">
						<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold"> {{ deal.statusLabel }} </span>
					</div>
				</li>
				<li v-if="deal.contract.customer">
					<sub class="text-xs">Cliente</sub>
					<div class="flex items-center">
						<icon name="ri-user-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold"> {{ `${deal.contract.customer.name} ${deal.contract.customer.lastname || ''}` }} </span>
					</div>
				</li>
			</ul>
		</div>
		<hr />
		<div class="px-3">
			<div class="mb-3">
				<ul class="flex flex-wrap gap-4 mb-2">
					<li v-if="deal.contract.status">
						<sub class="text-xs">Estado Crédito</sub>
						<div class="flex items-center">
							<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ deal.contract.status }} </span>
						</div>
					</li>
					<li v-if="props.distance">
						<sub class="text-xs">Distancia</sub>
						<div class="flex items-center">
							<icon name="ri-pin-distance-line" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ props.distance.toFixed(1) }}m </span>
						</div>
					</li>
					<li v-if="deal.contract.hasOwnProperty('createdAt')">
						<sub class="text-xs">Fecha otorgamiento</sub>
						<div class="flex items-center text-green-600" :class="{ 'text-red-600': deal.visitCount == 0 }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ dayjs(deal.contract.createdAt).format('DD-MM-YYYY') }} </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">Saldo inicial</sub>
						<div class="flex items-center text-red-600">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ parseCurrency(+deal.contract.amount, 'en-US', 'USD') }} </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">Saldo adeudado</sub>
						<div class="flex items-center text-red-600">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ parseCurrency(+deal.contract.amountToPay, 'en-US', 'USD') }} </span>
						</div>
					</li>
					<li v-if="deal.hasOwnProperty('amountPaid')">
						<sub class="text-xs">Monto pagado</sub>
						<div class="flex items-center text-red-600" :class="{ 'text-green-600': +deal.contract.amount == deal.contract.amountToPay }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ parseCurrency(+deal.contract.amountPaid, 'en-US', 'USD') }} </span>
						</div>
					</li>
					{{}}
					<li v-if="deal.hasOwnProperty('visitCount')">
						<sub class="text-xs">Nº Visitas agendadas</sub>
						<div class="flex items-center text-green-600" :class="{ 'text-red-600': !deal.visitCount }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ deal.visitCount }} </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">Nº localizadas</sub>
						<div class="flex items-center text-green-600" :class="{ 'text-red-600': !deal.notLocatedCount }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ deal.visitsDoneCount - deal.notLocatedCount }} </span>
						</div>
					</li>
					<li v-if="deal.hasOwnProperty('notLocatedCount')">
						<sub class="text-xs">Nº sin localizar</sub>
						<div class="flex items-center text-green-600" :class="{ 'text-red-600': !deal.notLocatedCount }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ deal.notLocatedCount }} </span>
						</div>
					</li>
					<li v-if="deal.hasOwnProperty('unpaidVisitCount')">
						<sub class="text-xs">Nº sin pagar</sub>
						<div class="flex items-center text-green-600" :class="{ 'text-red-600': !deal.unpaidVisitCount }">
							<icon name="ri-radio-button-fill" class="mr-1" />
							<span class="mr-1 font-bold"> {{ deal.unpaidVisitCount }} </span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Deal } from '@/apps/go/interfaces/Deal'
import { parseCurrency } from '@/apps/go/utils/parseCurrency'
import { dayjs } from '@/services/dayjs'
import { User } from '@sentry/vue'
const props = defineProps<{ deal: Deal; agent: User; distance?: number }>()
const emit = defineEmits(['onToggleOpen'])
</script>
