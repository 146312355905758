<template>
	<Default :loading="loading">
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Roles de Usuarios</h1>
					</div>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								Resultados:
								<span class="text-slate-400">{{ items.length }}</span>
							</header>
						</div>

						<!-- Table -->
						<div class="overflow-x-auto" v-show="items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Path</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Métodos</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Middlewares</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr class="even:bg-gray-100 hover:bg-gray-200" v-for="item in items" :key="item._id">
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.path }}</td>
										<td class="px-2">
											<div class="flex flex-wrap items-center gap-2">
												<Badge size="xs" randomColor v-for="value in item.methods" :cached-random-color-key="value" class="m-[.1em]">{{ value }}</Badge>
											</div>
										</td>
										<td class="px-2">
											<div class="flex gap-2">
												<Badge size="xs" randomColor v-for="value in item.middlewares" :cached-random-color-key="value" class="m-[.1em]">{{ value }}</Badge>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-show="(!loading && !items.length) || errors.length">
						<Banner type="error" :open="!!errors?.length" fixed>
							<ul>
								<li v-for="(error, i) in errors" :key="i">{{ error.message }}</li>
							</ul>
						</Banner>

						<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
							<div class="text-center py-6">
								<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
									<icon name="ri-information-line" scale="2" />
								</div>
								<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado rutas.</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { Ref, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Badge from '../../components/Badge.vue'
import Banner from '../../components/Banner.vue'
import { get } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()

const options: Record<string, any> = reactive({
	...route.query,
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || 'label'
})

const loading = ref(false)
const items = ref([])
const router = useRouter()

const errors = ref([])
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })

const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const search = async () => {
	loading.value = true
	errors.value = []
	try {
		items.value = []
		const { data } = await get({ name: 'system.routes' })
		items.value = data
	} catch (error) {
		if (error instanceof ValidationError) {
			errors.value = error.errors || []
		}
	}

	loading.value = false
}

onMounted(() => {
	search()
})

const onChangeLimit = () => {
	options.skip = 0
}

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
watch(options, () => {
	router.replace({
		query: options
	})
})
</script>
