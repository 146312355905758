<template>
	<div class="bg-white rounded-sm border border-gray-200">
		<div>
			<div class="flex items-center justify-between border-b border-gray-200 p-1">
				<span class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3">
					<icon name="ri-map-pin-line" class="text-gray-400 mr-1" />
					<div>Dirección</div>
				</span>
				<div>
					<Button
						v-if="user.permissions?.includes('lead.update.loc')"
						@click="
							router.push({
								name: 'go.leadLocation',
								query: {
									_id: props.lead._id,
									project: props.project
								}
							})
						"
						class="mr-1"
					>
						<icon name="ri-map-pin-line" class="mr-1" />
						Editar coordenadas
					</Button>
					<a :href="`https://www.google.com/maps/place/${props.lead.loc.coordinates[1]},${props.lead.loc.coordinates[0]}`" target="_blank" rel="noopener noreferrer" class="text-light-blue-500 underline">
						<button class="btn bg-green-600 hover:bg-green-500 text-white mr-1">
							<icon name="ri-road-map-line" class="opacity-70 w-5 h-5 mr-1" />
							Google Maps
						</button>
					</a>
				</div>
			</div>
			<div class="text-sm text-gray-800 p-3 flex flex-wrap gap-2">{{ props.lead.contract.address.state }} {{ props.lead.contract.address.city }} {{ props.lead.contract.address.full }} {{ props.lead.contract.address.zipcode }}</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import Button from '../../components/Button.vue'
import router from '@/router/router'
import { user } from '@/user'
import { Lead } from '../../interfaces/Lead'
const props = defineProps<{ project: string; lead: Lead }>()
</script>
