<template>
	<div v-if="duration" class="flex items-right justify-end space-x-3">
		<div class="flex flex-col items-center justify-center">
			<span class="text-2xl w-12 h-12 font-semibold text-gray-300 bg-gray-700 rounded-xl p-2"> {{ String(duration.days()).padStart(2, '0') }} </span>
			<span class="text-xs text-gray-600 mt-1"> días </span>
		</div>
		<div class="flex flex-col items-center justify-center">
			<span class="text-2xl w-12 h-12 font-semibold text-gray-300 bg-gray-700 rounded-xl p-2"> {{ String(duration.hours()).padStart(2, '0') }} </span>
			<span class="text-xs text-gray-600 mt-1"> horas </span>
		</div>
		<div class="flex flex-col items-center justify-center">
			<span class="text-2xl w-12 h-12 font-semibold text-gray-300 bg-gray-700 rounded-xl p-2"> {{ String(duration.minutes()).padStart(2, '0') }} </span>
			<span class="text-xs text-gray-600 mt-1"> min </span>
		</div>
		<div class="flex flex-col items-center justify-center">
			<span class="text-2xl w-12 h-12 font-semibold text-gray-300 bg-gray-700 rounded-xl p-2"> {{ String(duration.seconds()).padStart(2, '0') }} </span>
			<span class="text-xs text-gray-600 mt-1"> seg </span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { ref, onMounted, onUnmounted } from 'vue'
const duration = ref(null)
const interval = ref(null)
import { Deal } from '../../interfaces/Deal'
const props = defineProps<{ deal: Deal }>()
const update = () => {
	const diff = dayjs(props.deal.expireDate).diff(dayjs())
	if (diff <= 1000) {
		finish()
	} else {
		duration.value = dayjs.duration(diff)
	}
}

const finish = () => {
	clearInterval(interval.value)
	interval.value = null
}

onMounted(() => {
	update()
	interval.value = setInterval(update, 1000)
})

onUnmounted(() => {
	finish()
})
</script>
