import { point } from 'leaflet'
const product = import.meta.env.VITE_PRODUCT || 'energy'

export const mapIconBase = {
	iconSize: point(41, 60),
	iconAnchor: point(14, 74),
	shadowAnchor: point(4, 62),
	shadowUrl: new URL(`../images/map/${product}/marker_shadow.png`, import.meta.url),
	iconUrl: new URL(`../images/map/${product}/marker_unknow.png`, import.meta.url)
}
