import App from '@/App.vue'
import '@/assets/css/style.scss'
import router from '@/router/router'
import * as Sentry from '@sentry/vue'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import * as heroicons from 'oh-vue-icons/icons/hi'
import * as ionicons from 'oh-vue-icons/icons/io'
import * as remixicons from 'oh-vue-icons/icons/ri'
import { createApp } from 'vue'

import { user } from '@/user'

addIcons(...Object.values({ ...heroicons, ...remixicons, ...ionicons }))

const app = createApp(App)
app.component('icon', OhVueIcon)
app.use(router)

if (import.meta.env.VITE_SENTRY_DSN) {
	Sentry.init({
		app,
		release: import.meta.env.PACKAGE_VERSION,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration(),
		],
		trackComponents: true,
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['localhost', import.meta.env.VITE_API, /^\//],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		beforeSend: (event, hint) => {
			if (hint.originalException === 'Timeout') return null
			return event
		}
	})
	Sentry.setUser({
		email: user.value.email,
		username: `${user.value.name} ${user.value.surname}`,
		id: user.value._id
	})
}

app.mount('#app')
