<template>
	<Default :loading="loading">
		<div class="relative flex h-full">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button icon="ri-arrow-left-line" color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center">
							<Button :loading="loading" :disabled="loading" @click="save">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>
				<div class="grow p-3 bg-gray-100">
					<div class="mb-4">
						<Banner type="success" class="mb-3" :open="success"> Grupo creado correctamente </Banner>
						<Banner type="error" :open="!!error.message">{{ error.message }}</Banner>
					</div>

					<div class="bg-white rounded-sm border border-gray-200 mb-3">
						<!-- Body -->
						<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
							<icon name="ri-user-line" class="text-gray-400 mr-1" />
							<div>Datos de usuario</div>
						</div>
						<div class="text-sm text-gray-800 p-5">
							<div>
								<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
									<div>
										<!-- Start -->
										<div class="flex items-center justify-between">
											<label class="block text-sm font-medium mb-1" for="name">Nombre de grupo<span class="text-red-500">*</span></label>
										</div>
										<input class="form-input w-full" type="text" name="label" id="label" required="true" v-model="label" :class="{ 'border-red-600': !!getErrorMessage('label') }" />
										<p class="text-red-600">
											<small>{{ getErrorMessage('label') }}</small>
										</p>
										<!-- End -->
									</div>

									<div class="flex flex-col">
										<div class="text-sm font-medium mb-1">Proyecto <span class="text-red-500">*</span></div>
										<!-- Start -->
										<div class="flex gap-4 h-full">
											<Multiselect @select="fetchUsers" @remove="onRemoveProject" :disabled="!projects.length" id="project" name="project" v-model="selectedProject" track-by="name" label="label" placeholder="Seleccionar proyecto" :options="projects" :showNoResults="true" :multiple="false" :closeOnSelect="true" :clearOnSelect="false" :preserveSearch="true" :hideSelected="false" :multiselect="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover" :class="{ 'border border-red-600': !!getErrorMessage('project') }">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado proyectos. </template>
											</Multiselect>
										</div>
										<p class="text-red-600">
											<small>{{ getErrorMessage('project') }}</small>
										</p>
										<!-- End -->
									</div>
									<div class="flex flex-col">
										<div class="text-sm font-medium mb-1">Usuarios</div>
										<!-- Start -->
										<div class="flex gap-4 h-full">
											<Multiselect :disabled="!users.length" id="users" name="users" v-model="selectedUsers" track-by="_id" :custom-label="u => `${u.name} ${u.surname}`" placeholder="Seleccionar usuario" :closeOnSelect="true" :options="users" :showNoResults="true" :clearOnSelect="false" :preserveSearch="true" :hideSelected="true" :multiple="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado usuarios. </template>
											</Multiselect>
										</div>
										<!-- End -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { user } from '@/user'
import { onMounted, ref } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute } from 'vue-router'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const projects = ref([])
const users = ref([])
const label = ref('')
const loading = ref(false)
const success = ref(false)
const _id = route.params._id
const selectedProject = ref(null)
const selectedUsers = ref([])

const back = () => {
	history.back()
}

const getErrorMessage = useValidationErrorsMessages(error)

const fetch = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		const { data } = await get({ name: 'group', params: { _id } })
		label.value = data.label
		selectedProject.value = projects.value.find(p => p.name == data.project)
		await fetchUsers()
		selectedUsers.value = data.users.filter(Boolean)
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const onRemoveProject = async () => {
	users.value = []
}
const save = async () => {
	loading.value = true
	success.value = false
	error.value = <any>{}
	try {
		const payload = {
			label: label.value,
			project: selectedProject.value?.name || null,
			users: selectedUsers.value?.map(u => u._id) || []
		}

		if (_id) {
			await put({ name: 'group', params: { _id } }, payload)
		} else {
			await post({ name: 'group' }, payload)
			label.value = ''
			selectedUsers.value = []
			selectedProject.value = []
		}
		success.value = true
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { projection: 'name,label' } })
		projects.value = data.filter(project => user.value?.projects?.includes(project.name))
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchUsers = async () => {
	error.value = <any>{}

	try {
		users.value = []
		const query = { projects: selectedProject.value.name, limit: '5999', active: 'true', sort: 'name,surname', projection: 'name,surname' }
		const { data } = await get({ name: 'user', query: query })
		users.value = data
	} catch (err) {
		error.value = err
	}
}
onMounted(async () => {
	await fetchProjects()
	if (_id) {
		fetch()
	}
})
</script>
