import Compressor from 'compressorjs'
import { ref } from 'vue'

export default function (accept: string[]) {
	const files = ref([])
	const mimeTypes = accept
	let done = (...args: any) => {}

	function addFiles(newFiles: File[]) {
		for (const file of [...newFiles]) {
			if (!mimeTypes?.includes(file.type)) continue
			if (file.type.startsWith('image')) {
				new Compressor(file, {
					convertSize: 1000000,
					quality: 0.6,
					success(result) {
						const objFile = new UploadableFile(<File>result, file)
						if (!fileExists(objFile.id)) {
							files.value.push(objFile)
						}
						done(files.value)
					}
				})
			} else {
				const objFile = new UploadableFile(file)
				if (!fileExists(objFile.id)) {
					files.value.push(objFile)
				}
				done(files.value)
			}
		}
	}

	function fileExists(otherId) {
		return files.value.some(({ id }) => id === otherId)
	}

	function removeFile(file) {
		const index = files.value.indexOf(file)

		if (index > -1) files.value.splice(index, 1)
		done(files.value)
	}
	function updateFile(data) {
		const newName = data.newData.name ? `${data.newData.name}.${data.newData.extension}` : null
		const index = files.value.indexOf(data.file)

		Object.defineProperty(data.file.file, 'name', {
			writable: true,
			value: newName
		})
		Object.defineProperty(data.file.file, 'description', {
			writable: true,
			value: data.newData.description || null
		})

		files.value[index] = data.file
		done(files.value)
	}

	const onResult = (cb: (...args: any) => void | any) => (done = cb)

	return { files, addFiles, removeFile, updateFile, onResult }
}

class UploadableFile {
	file: any
	id: string
	url: string
	status: null
	constructor(file: File, original?: File) {
		if (!original) original = file
		this.file = file
		this.id = `${original.name}-${original.size}-${original.lastModified}-${original.type}`
		this.url = URL.createObjectURL(file)
		this.status = null
	}
}
