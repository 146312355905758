<template>
	<div>
		<div v-if="props.contract.powerP1" class="bg-white rounded-sm border border-gray-200 mt-2">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3">
				<icon name="hi-lightning-bolt" class="text-gray-400 mr-1" />
				<div class="sm:flex justify-between items-end w-full">
					<p>Potencia contratada - kW</p>
					<p class="text-[0.8em] font-normal" v-if="props.contract.syncAt">actualizado {{ dayjs(contract.syncAt).format('DD-MM-YYYY HH:mm') }}</p>
				</div>
			</div>
			<div>
				<table class="table-auto w-full">
					<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
						<tr>
							<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P1</div>
							</th>
							<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P2</div>
							</th>
							<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P3</div>
							</th>
							<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P4</div>
							</th>
							<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P5</div>
							</th>
							<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-semibold text-left">P6</div>
							</th>
						</tr>
					</thead>
					<tbody class="text-sm divide-y divide-gray-200">
						<tr>
							<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP1 }}
								</div>
							</td>
							<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP2 }}
								</div>
							</td>
							<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP3 }}
								</div>
							</td>
							<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP4 }}
								</div>
							</td>
							<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP5 }}
								</div>
							</td>
							<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
								<div class="font-medium text-light-blue-500">
									{{ contract.powerP6 }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<Banner v-if="props.contract.hasOwnProperty('powerP1') && !contract.powerP1" type="warning" :open="true" class="my-2" fixed> <span>No disponemos de Información sobre la potencia.</span></Banner>

		<div v-if="props.contract.kWhP1" class="bg-white rounded-sm border border-gray-200 mt-2">
			<div>
				<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3">
					<icon name="ri-bar-chart-2-line" class="text-gray-400 mr-1" />
					<div class="sm:flex justify-between items-end w-full">
						<p>Consumo de energía - kWh Anual</p>
						<p class="text-[0.8em] font-normal" v-if="props.contract.syncAt">actualizado {{ dayjs(contract.syncAt).format('DD-MM-YYYY HH:mm') }}</p>
					</div>
				</div>

				<div>
					<table class="table-auto w-full">
						<!-- Table header -->
						<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
							<tr>
								<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P1</div>
								</th>
								<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P2</div>
								</th>
								<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P3</div>
								</th>
								<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P4</div>
								</th>
								<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P5</div>
								</th>
								<th v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-semibold text-left">P6</div>
								</th>
							</tr>
						</thead>
						<!-- Table body -->

						<tbody class="text-sm divide-y divide-gray-200">
							<tr>
								<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP1 }}
									</div>
								</td>
								<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP2 }}
									</div>
								</td>
								<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP3 }}
									</div>
								</td>
								<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP4 }}
									</div>
								</td>
								<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP5 }}
									</div>
								</td>
								<td v-if="props.contract.rate > 2" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
									<div class="font-medium text-light-blue-500">
										{{ contract.kWhP6 }}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<Banner v-if="props.contract.hasOwnProperty('kWhP1') && !contract.kWhP1" type="warning" :open="true" class="my-2" fixed> <span>No disponemos de Información sobre el consumo de energía.</span></Banner>

		<div v-if="props.contract.measureEquipmentProperty" class="bg-white rounded-sm border border-gray-200 mt-2 mb-4">
			<!-- Body -->
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-tools-line" class="text-gray-400 mr-1" />
				<div>Equipo de medida</div>
			</div>
			<div class="text-sm text-gray-800 p-3">
				{{ contract.measureEquipmentProperty == 'supplier' ? 'Equipo de medida propiedad del distribuidor' : 'Equipo de medida propiedad del cliente' }}
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { Contract } from '@/apps/go/interfaces/Contract'
import Banner from '@/apps/go/components/Banner.vue'
const props = defineProps<{ contract: Contract }>()
</script>
