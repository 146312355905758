<template>
	<DealCustomer v-if="shorcuts['dealCustomer'].show" @abort="shorcuts['dealCustomer'].show = false" :project="props.project" :deal="props.deal" />
	<DealContract v-if="shorcuts['dealContract'].show" @abort="shorcuts['dealContract'].show = false" :project="props.project" :deal="props.deal" />
	<DealHistory v-if="shorcuts['dealHistory'].show" @abort="shorcuts['dealHistory'].show = false" :project="props.project" :deal="props.deal" />
	<DealSupply v-if="shorcuts['dealSupply'].show" @abort="shorcuts['dealSupply'].show = false" :project="props.project" :deal="props.deal" />
	<DealNotifications v-if="shorcuts['dealNotifications'].show" @abort="shorcuts['dealNotifications'].show = false" :project="props.project" :deal="props.deal" />
	<DealLocation v-if="shorcuts['dealLocation'].show" @abort="shorcuts['dealLocation'].show = false" :project="props.project" :deal="props.deal" />
	<div v-if="props.deal" class="bg-white rounded-sm border border-gray-200">
		<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<icon name="ri-settings-line" class="text-gray-400 mr-1" />
			<div>Accesos disponibles</div>
		</div>
		<div class="text-sm text-gray-800 p-3">
			<div>
				<div v-for="key in Object.keys(shorcuts)" :key="key">
					<Button v-if="shorcuts[key].hasOwnProperty('condition') ? shorcuts[key].condition : true" class="w-full mt-2" @click="shorcuts[key].show = true">
						<icon :name="shorcuts[key].icon" class="mr-1" />
						{{ shorcuts[key].label }}
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import Button from '../../components/Button.vue'
import { Deal } from '../../interfaces/Deal'
import { ref } from 'vue'
import DealContract from './DealContract.vue'
import DealCustomer from './DealCustomer.vue'
import DealHistory from './DealHistory.vue'
import DealNotifications from './DealNotifications.vue'
import DealLocation from './DealLocation.vue'
import DealSupply from './DealSupply.vue'

const props = defineProps<{ deal: Deal; project: string }>()
const isFinance = import.meta.env.VITE_PRODUCT == 'finance'

const shorcuts = ref({
	dealCustomer: {
		label: 'Datos Titular',
		icon: 'ri-shield-user-line',
		show: false
	},
	dealContract: {
		label: 'Datos Contrato',
		icon: 'ri-lightbulb-flash-line',
		show: false
	},
	dealSupply: {
		label: 'Datos Distribuidora',
		icon: 'ri-base-station-line',
		condition: !isFinance,
		show: false
	},
	dealHistory: {
		label: 'Historial Cambios',
		icon: 'ri-history-line',
		show: false
	},
	dealNotifications: {
		label: 'Historial Notificaciones',
		icon: 'ri-notification-3-line',
		show: false
	},
	dealLocation: {
		label: 'Editar coordenadas',
		icon: 'ri-map-pin-line',
		permissions: ['deal.update.loc'],
		show: false
	}
})
</script>
