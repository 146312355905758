import { auth } from '@/auth'
import { dayjs } from '@/services/dayjs'
export default function authMiddleware({ next, router }) {
	const expired = auth.value?.expiresAt && dayjs(auth.value?.expiresAt).isBefore(dayjs())
	if (!auth.value.token || expired) {
		return router.push({ name: 'go.login' })
	} else {
		return next()
	}
}
