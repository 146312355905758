<template>
	<main class="bg-white">
		<div class="relative flex">
			<div class="w-full md:w-1/2">
				<div class="min-h-screen h-full flex flex-col after:flex-1">
					<div class="flex-1">
						<div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
					</div>
					<div class="max-w-sm mx-auto px-4 py-8">
						<h1 class="text-3xl text-primary-500 font-bold mb-6">GO</h1>
						<Banner class="mb-4" type="warning" fixed :open="!!$route?.query?.authenticationRequired"> Tu sesión ha caducado. Vuelve a iniciar sesión. </Banner>
						<form action="" @submit.prevent="submit">
							<div class="space-y-4">
								<div>
									<label class="block text-sm font-medium mb-1" for="email">Email</label>
									<input v-model="credentials.email" class="form-input w-full" type="text" />
								</div>
								<div>
									<label class="block text-sm font-medium mb-1" for="password">Password</label>
									<input v-model="credentials.password" class="form-input w-full" type="password" autoComplete="off" />
								</div>
							</div>
							<div class="flex items-center justify-between mt-6">
								<div class="mr-1"></div>
								<Button :disabled="loginDisabled" :loading="loading" loading-label="Comprobando..." class="btn bg-primary-600 hover:bg-primary-600 text-white ml-3">Login</Button>
							</div>
							<div class="mt-3 text-center">
								<RouterLink :to="{ name: 'go.recover-password' }" class="text-light-blue-500 underline"><small>¿Has olvidado tu contraseña?</small></RouterLink>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
				<img class="object-cover object-center w-full h-full" :src="bgImage" width="760" height="1024" alt="Authentication" />
				<img class="absolute top-1/4 left-0 transform -translate-x-1/2 hidden lg:block" src="./../images/auth-decoration.png" width="218" height="224" alt="Authentication decoration" />
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { auth } from '@/auth'
import router from '@/router/router'
import { user } from '@/user'
import { onMounted, reactive, ref, watch } from 'vue'
import { RouterLink } from 'vue-router'
import Banner from '../components/Banner.vue'
import Button from '../components/Button.vue'
import { login } from './../services/api'

declare const grecaptcha: any
let credentials = reactive<{ email: string; password: string; recaptcha?: string }>({ email: '', password: '', recaptcha: undefined })
const loginDisabled = ref(true)
const siteKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_V3_SITE_KEY
const product = import.meta.env.VITE_PRODUCT || 'energy'
const bgImage = new URL(`../../../apps/go/images/${product}/auth-bg.png`, import.meta.url).toString()

localStorage.removeItem('auth')
localStorage.removeItem('user')
localStorage.removeItem('mapFilterOptions')
localStorage.removeItem('mapAdminFilterOptions')
auth.value = null
user.value = null

const loading = ref(false)

const submit = async () => {
	loading.value = true
	try {
		const res = await login(credentials)
		auth.value = res.auth
		user.value = res.data
		localStorage.auth = JSON.stringify(auth.value)
		localStorage.user = JSON.stringify(user.value)
		credentials = reactive(<any>{})
		router.push({ name: 'go.map' })
	} catch (error) {
		alert('error')
		alert(error.message)
	}
	loading.value = false
}

const executeRecaptcha = () => {
	grecaptcha.ready(async () => {
		try {
			credentials.recaptcha = await grecaptcha.execute(siteKey)
			const badge: HTMLElement = document.querySelector('.grecaptcha-badge')
			if (badge) {
				badge.style.visibility = 'hidden'
			}
		} catch (error) {
			window.alert('No se ha podido generar el token de seguridad. Refresca la ventana para iniciar sesión.')
		}
	})
}

onMounted(async () => {
	if (!!siteKey) {
		if (typeof grecaptcha === 'undefined') {
			const script = document.createElement('script')
			script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
			script.async = true
			script.onload = () => {
				executeRecaptcha()
			}
			document.head.appendChild(script)
		} else {
			executeRecaptcha()
		}
	}
})

watch(credentials, () => {
	loginDisabled.value = !(!!credentials.email && !!credentials.password && !!(!siteKey || credentials.recaptcha))
})
</script>
