<template>
	<div v-show="props.contract" class="col-span-full xl:col-span-4 bg-white rounded-sm border border-gray-200">
		<div class="border-b border-gray-200">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200 mb-2">
				<icon name="ri-information-line" class="text-gray-400 mr-1" />
				Información
			</div>
			<!-- <Agent class="px-3" v-show="showAgent" v-if="agent" :agent="agent" /> -->
			<div class="px-3 mb-3">
				<ul class="flex flex-wrap gap-4">
					<li class="flex items-center">
						<icon name="ri-money-dollar-box-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold">{{ contract.amount }} $</span>
						<sub class="text-xs">Deuda inicial</sub>
					</li>
					<li class="flex items-center">
						<icon name="ri-information-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold">{{ contract.status }}</span>
						<sub class="text-xs">Estado interno</sub>
					</li>
					<li class="flex items-center">
						<icon name="ri-pin-distance-line" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold">{{ props.distance.toFixed(2) }} m</span>
						<sub class="text-xs">Distancia</sub>
					</li>
				</ul>
			</div>
		</div>

		<div class="px-4 pt-4 pb-3" v-if="props.contract.lastSupplierChange || contract.lastReading">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase mb-2">
				<icon name="ri-list-check" class="text-gray-400 mr-1" />
				<div>Detalles</div>
			</div>
			<ul>
				<li v-if="props.contract.lastSupplierChange" class="flex items-center py-1">
					<icon name="ri-check-line" class="text-green-500 mr-1" />
					<div class="text-sm">
						Cambio comercializadora hace
						{{ dayjs().diff(contract.lastSupplierChange, 'month') }}
						meses
					</div>
				</li>
				<li v-if="props.contract.lastReading" class="flex items-center py-1">
					<icon name="ri-check-line" class="text-green-500 mr-1" />
					<div class="text-sm">
						Registro actualizado
						{{ dayjs(contract.lastReading).fromNow() }}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup lang="ts">
import { Contract } from '@/apps/go/interfaces/Contract'
import { dayjs } from '@/services/dayjs'
const props = defineProps<{ contract: Contract; distance: number }>()
</script>
