<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center gap-2" v-if="payload">
							<Button :loading="loading" @click="geolocateFile" :disabled="loading || !!!payload.file?.length" loadingLabel="...Cargando Leads"> Geolocalizar </Button>
						</div>
					</div>
				</div>

				<div class="w-full h-full relative grow p-3 bg-gray-100" v-if="payload">
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Leads cargados correctamente </Banner>

					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-shield-line" class="text-gray-400 mr-1" />
								<div>Adjuntar archivo</div>
							</div>

							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div class="col-span-full">
											<label class="block text-sm font-medium mb-1" for="label">Carga masiva de Oportunidades<span class="text-red-500">*</span></label>
											<small>Los archivos permitidos son con las siguientes extensiones: .txt, .xlsx, .xls, .csv</small>
											<p :class="getErrorMessage('file') && 'text-red-600'">
												<InputFile @update:model-value="updateModelValue" :accept="['text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']" :modelValue="payload.file" required :errors="error?.errors || []"> </InputFile>
												<small>{{ getErrorMessage('file') }}</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="error && error.errors" class="mt-4 bg-red-100 border-t-4 border-red-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
							<div class="flex">
								<div class="py-1">
									<svg class="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
								</div>
								<div>
									<p class="font-bold">Se han detectado {{ error.errors.length }} error(es)</p>
									<p class="text-sm">Revisa los errores para poder continuar con la importación.</p>
									<ul class="text-sm">
										<li v-for="item in error.errors">
											<strong>{{ item.field }}</strong> - {{ item.message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { dayjs } from '@/services/dayjs'
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'
import InputFile from '../../components/inputFile/InputFile.vue'
import { post } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const error = ref<ValidationError>(null)
const getErrorMessage = useValidationErrorsMessages(error)

const loading = ref(false)
const saving = ref(false)
const isSaved = ref(false)

let payload = reactive<{
	file: Record<string, any>
}>(<any>{})

const back = () => {
	history.back()
}

const onCloseBanner = () => {
	isSaved.value = false
}
const updateModelValue = file => (payload.file = file)

const geolocateFile = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}

	try {
		const body = payload.file[0]?.file?.body
		const type = payload.file[0]?.file?.type

		let typeStr = ''
		let base64Uri = ''

		switch (type) {
			case 'text/plain':
				typeStr = `txt`
				base64Uri = `data:text/plain;base64,`
				break
			case 'text/csv':
				typeStr = `csv`
				base64Uri = `data:text/csv;base64,`
				break
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				typeStr = 'xlsx'
				base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`
				break
			case 'application/vnd.ms-excel':
				typeStr = `xls`
				base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`
				break
			default:
				typeStr = `xls`
				break
		}

		const { data } = await post({ name: 'lead.file.geolocation' }, { type: typeStr, file: { body } })
		base64Uri = `${base64Uri}${data}`

		const xlsxBlob = await fetch(base64Uri).then(res => res.blob())
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(xlsxBlob)
		link.download = `LEADS_LOCATION_${dayjs().format('DD-MM-YYYY')}.${typeStr}`
		link.click()
		link.remove()
	} catch (err) {
		error.value = err
	}
	isSaved.value = !Boolean(error.value?.message)
	saving.value = false
	loading.value = false
}
</script>
