import { Icon } from 'leaflet'
import { mapIconBase as base } from './mapIconBase'
const product = import.meta.env.VITE_PRODUCT || 'energy'

export const userIcon = new Icon({
	...base,
	iconUrl: new URL(`../images/map/${product}/marker_user.png`, import.meta.url),
	className: 'animate-pulse z-10',
	iconSize: [38, 55],
	iconAnchor: [19, 55]
})
