<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="user && editFormOpen">
							<Button :loading="saving" :disabled="saving" @click="save">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>

				<div class="relative grow h-full p-3 bg-gray-100">
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Usuario {{ route.query.user ? 'actualizado' : 'creado' }} correctamente </Banner>
					<Banner type="error" class="mb-3" :open="!!(error.message && !error?.errors)" @onClose="onCloseBanner"> {{ error.message }}</Banner>

					<template v-if="user">
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-user-line" class="text-gray-400 mr-1" />
									<div>Perfil</div>
								</div>
								<div>
									<Button size="xs" @click="toggleEditForm('profile')" :disabled="!userLS.permissions?.includes('user.update')">Editar</Button>
								</div>
							</div>
							<div class="grid md:grid-cols-3 items-center justify-center md:justify-between">
								<div class="flex items-center m-3 md:col-span-2">
									<div class="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 justify-center gap-3">
										<div>
											<p><icon name="ri-user-line" /> {{ user.name }} {{ user.surname }}</p>
										</div>
										<div>
											<p class="whitespace-nowrap"><icon class="mr-1" name="ri-mail-line" />{{ user.email }}</p>
										</div>
										<div>
											<p><icon class="mr-1" name="ri-shield-user-line" />Rol: {{ roles.find(role => role._id === user.role)?.label }}</p>
										</div>
										<div>
											<p><icon class="mr-1" name="ri-shield-check-line" />Posición: {{ user.position }}</p>
										</div>
										<div>
											<p><icon class="mr-1" name="ri-building-line" />Compañía: {{ getOrganizationName(user.organization) }}</p>
										</div>
										<div>
											<p><icon class="mr-1" name="ri-map-pin-line" />Territorio: {{ getTerritoryName(user.territory) }}</p>
										</div>
										<div>
											<p><icon class="mr-1" name="ri-links-line" />Tipo de red: {{ getNetworkTypeName(user.networkType) }}</p>
										</div>
										<div class="col-span-full">
											<p>
												<icon class="mr-1" name="ri-links-line" />Proyectos:
												<Badge randomColor class="uppercase m-1" :cachedRandomColorKey="project" v-for="project in user.projects" :key="project">
													<template v-for="pr in projects" :key="pr">
														<small v-if="pr.name === project" :title="`${pr.count || 0} contratos bloqueados`">
															{{ pr.label + ` (${pr.count || 0})` }}
														</small>
													</template>
												</Badge>
											</p>
										</div>
									</div>
								</div>
								<div class="inline-flex m-3 justify-center md:col-span-1 order-first md:order-last">
									<img class="rounded-full border-4 border-white" src="@/apps/go/images/user-avatar-80.png" width="128" height="128" alt="Avatar" />
								</div>
							</div>
						</div>

						<div class="w-full h-full relative md:flex" v-if="_id">
							<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
								<div class="bg-white rounded-sm border border-gray-200 mb-3">
									<!-- Page header -->
									<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
										<icon :name="`ri-user-settings-line`" class="text-gray-400 mr-1" />
										<div>Acciones de usuario</div>
									</div>
									<div class="p-3" v-if="!loading && !loadingProjectCount">
										<div v-if="dealsCount && user.active">
											<Banner :open="true" type="warning" :fixed="true" class="mb-3"
												>El usuario tiene <b>{{ dealsCount }}</b> oportunidades y no es posible desactivarlo. Primero deberás reasignar las oportunidades a otro usuario.</Banner
											>
											<Button
												@click="
													$router.push({
														name: 'go.opportunities',
														query: {
															user: user._id,
															project: projectMaxDealsCount
														}
													})
												"
												>Reasignar Deals</Button
											>
										</div>
										<div v-else>
											<Button :loading="saving" :disabled="saving" @click="activateDeactivateUser" :color="user.active ? 'danger' : 'success'">{{ !user.active ? 'Activar' : 'Desactivar' }} usuario</Button>
										</div>
									</div>
									<div v-else class="flex justify-center py-4">
										<icon name="ri-loader-4-line" class="mr-1" animation="spin" scale="1.5" />
									</div>
								</div>
							</div>
						</div>

						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-team-line" class="text-gray-400 mr-1" />
									<div>Grupos - Pertenece a</div>
								</div>
								<div>
									<Button size="xs" @click="toggleEditForm('belongsTo')" :disabled="!userLS.permissions?.includes('user.update') || !user.projects?.length">Editar</Button>
								</div>
							</div>
							<div class="p-3">
								<Banner :open="!user.belongsTo?.length" type="warning" fixed>El usuario no pertenece a ningún grupo. {{ !user.projects?.length ? 'Para editar los grupos primero deberás seleccionar un proyecto' : '' }}</Banner>

								<Badge v-if="user.belongsTo?.length" randomColor class="uppercase m-1" :cachedRandomColorKey="group" v-for="group in user.belongsTo" :key="group">
									{{ userGroups.find(({ _id }) => _id === group)?.label }}
								</Badge>
							</div>
						</div>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-team-line" class="text-gray-400 mr-1" />
									<div>Grupos - Puede ver a</div>
								</div>
								<div>
									<Button size="xs" @click="toggleEditForm('groups')" :disabled="!userLS.permissions?.includes('user.update') || !user.projects?.length">Editar</Button>
								</div>
							</div>
							<div class="p-3">
								<Banner :open="!user.groups?.length" type="warning" fixed>El usuario no puede ver ningún grupo. {{ !user.projects?.length ? 'Para editar los grupos primero deberás seleccionar un proyecto' : '' }}</Banner>
								<Badge randomColor class="uppercase m-1" :cachedRandomColorKey="group" v-for="group in user.groups" :key="group">
									{{ userGroups.find(({ _id }) => _id === group)?.label }}
								</Badge>
							</div>
						</div>

						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-group-line" class="text-gray-400 mr-1" />
									<div>Usuarios - Puede ver a</div>
								</div>
								<div>
									<Button size="xs" @click="toggleEditForm('users')" :disabled="!userLS.permissions?.includes('user.update')">Editar</Button>
								</div>
							</div>
							<div class="p-3">
								<Banner :open="!user.users?.length" type="warning" fixed>El usuario no puede ver otros usuarios</Banner>
								<Badge v-if="user.users?.length" randomColor class="uppercase m-1" :cachedRandomColorKey="userItem" v-for="userItem in user.users" :key="userItem">
									{{ getUserName(userItem) }}
								</Badge>
							</div>
						</div>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-group-line" class="text-gray-400 mr-1" />
									<div>Permisos específicos</div>
								</div>
								<div>
									<Button size="xs" @click="toggleEditForm('permissions')" :disabled="!Object.keys(availableCustomPermissions).length || !userLS.permissions?.includes('user.update')">Editar</Button>
								</div>
							</div>
							<div class="p-3">
								<Banner :open="!user.permissions?.length" type="warning" fixed>El usuario no tiene permisos específicos.</Banner>
								<template v-if="user.permissions?.length" v-for="permission in user.permissions" :key="permission">
									<Badge randomColor class="uppercase m-1" :cachedRandomColorKey="permission">
										{{ getPermissionLabel(permission) }}
									</Badge>
								</template>
							</div>
						</div>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<div class="flex justify-between items-center">
									<icon name="ri-group-line" class="text-gray-400 mr-1" />
									<div>Permisos de rol</div>
								</div>
							</div>
							<div class="p-3">
								<Banner :open="!rolePermissions?.length" type="warning" fixed>El usuario no tiene permisos.</Banner>
								<template v-if="rolePermissions?.length" v-for="permission in rolePermissions" :key="permission">
									<Badge randomColor class="uppercase m-1" :cachedRandomColorKey="permission">
										{{ getPermissionLabel(permission) }}
									</Badge>
								</template>
							</div>
						</div>

						<div class="fixed w-full max-w-[100%] sm:max-w-[750px] xl:max-w-[1000px] top-[7.5em] -right-[100%] h-screen bg-gray-100 z-50 overflow-y-auto p-3 border border-gray-200 shadow-xl pb-[8em] transform transition-all duration-200 ease-in-out" :class="{ 'right-0': editFormOpen }">
							<div class="pb-2 mb-2 border-b border-gray-200">
								<div class="flex justify-between">
									<h3 class="text-xl font-semibold">Editar</h3>
									<Button @click="toggleEditForm" size="xs" color="secondary">Cerrar</Button>
								</div>
								<p v-if="formSubTitle" class="font-semibold">{{ formSubTitle }}</p>
							</div>
							<form @submit.prevent class="mb-4">
								<Banner :open="!!error.message" type="error" class="mb-4">
									<p>{{ error.message }}. <span v-if="hasObjectStrictErrorMessage()">No se ha podido guardar la información.</span></p>
								</Banner>
								<div class="grid gap-3 sm:grid-cols-2" v-if="editFormPart == 'profile'">
									<!-- Body -->
									<div>
										<!-- Start -->
										<div class="flex items-center justify-between">
											<label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-red-500">*</span></label>
										</div>

										<input class="form-input w-full" type="text" name="name" v-model="userDataForm.name" id="name" required="true" :class="{ 'border-red-600': !!getErrorMessage('name') }" />
										<p class="text-red-600">
											<small>{{ getErrorMessage('name') }}</small>
										</p>
										<!-- End -->
									</div>
									<div>
										<!-- Start -->
										<label class="block text-sm font-medium mb-1" for="surname">Apellidos <span class="text-red-500">*</span></label
										><input class="form-input w-full" type="text" name="surname" v-model="userDataForm.surname" id="surname" required="true" :class="{ 'border-red-600': !!getErrorMessage('surname') }" />
										<p class="text-red-600">
											<small>{{ getErrorMessage('surname') }}</small>
										</p>
										<!-- End -->
									</div>
									<div>
										<!-- Start -->
										<label class="block text-sm font-medium mb-1" for="email">Email <span class="text-red-500">*</span></label
										><input class="form-input w-full" type="email" name="email" v-model="userDataForm.email" id="email" required="true" :class="{ 'border-red-600': !!getErrorMessage('email') }" />
										<p class="text-red-600">
											<small>{{ getErrorMessage('email') }}</small>
										</p>
										<!-- End -->
									</div>
									<div>
										<!-- Start -->
										<label class="block text-sm font-medium mb-1" for="position">Posición <span class="text-red-500">*</span></label>
										<input class="form-input w-full" type="text" name="position" v-model="userDataForm.position" id="position" required="true" :class="{ 'border-red-600': !!getErrorMessage('position') }" />
										<p class="text-red-600">
											<small>{{ getErrorMessage('position') }}</small>
										</p>
										<!-- End -->
									</div>
									<div>
										<!-- Start -->

										<label class="block text-sm font-medium mb-1" for="position">Rol <span class="text-red-500">*</span></label>
										<select name="role" id="role" class="form-select w-full" v-model="userDataForm.role" required :class="{ 'border-red-600': !!getErrorMessage('role') }">
											<option value="null">Seleccionar</option>
											<option v-for="role in roles" :value="role._id">{{ role.label }}</option>
										</select>
										<p class="text-red-600">
											<small>{{ getErrorMessage('role') }}</small>
										</p>

										<!-- End -->
									</div>
									<div>
										<label class="block text-sm font-medium mb-1" for="organization">Organización <span class="text-red-500">*</span></label>

										<select name="organization" id="organization" class="form-select w-full" v-model="userDataForm.organization" :class="{ 'border-red-600': !!getErrorMessage('organization') }">
											<option value="null">Seleccionar</option>
											<option v-for="organization in organizations" :value="organization._id">{{ organization.name }}</option>
										</select>
										<p class="text-red-600">
											<small>{{ getErrorMessage('organization') }}</small>
										</p>
									</div>
									<div>
										<label class="block text-sm font-medium mb-1" for="territory">Territorio<span class="text-red-500">*</span></label>
										<select name="territory" id="territory" class="form-select w-full" v-model="userDataForm.territory" :class="{ 'border-red-600': !!getErrorMessage('territory') }">
											<option value="null">Seleccionar</option>
											<option v-for="territory in territories" :value="territory.name">{{ territory.label }}</option>
										</select>
										<p class="text-red-600">
											<small>{{ getErrorMessage('territory') }}</small>
										</p>
									</div>
									<div>
										<label class="block text-sm font-medium mb-1" for="networkType">Tipo de red<span class="text-red-500">*</span></label>
										<select name="networkType" id="networkType" class="form-select w-full" v-model="userDataForm.networkType" :class="{ 'border-red-600': !!getErrorMessage('networkType') }">
											<option value="null">Seleccionar</option>
											<option v-for="networkType in networkTypes" :value="networkType.name">{{ networkType.label }}</option>
										</select>
										<p class="text-red-600">
											<small>{{ getErrorMessage('networkType') }}</small>
										</p>
									</div>
									<div class="flex flex-col col-span-full">
										<div class="text-sm font-medium">
											<p>Proyectos <span class="text-red-500">*</span></p>
										</div>
										<div class="flex gap-4 h-full">
											<Multiselect name="projects" id="projects" v-model="selectedProjects" track-by="name" label="label" placeholder="Seleccionar proyectos" :options="projects" :showNoResults="true" :clearOnSelect="false" :closeOnSelect="false" :preserveSearch="true" :hideSelected="true" :multiple="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para eliminar" :class="{ 'border border-red-600': !!getErrorMessage('projects') }">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado proyectos. </template>
											</Multiselect>
										</div>
										<p class="text-red-600">
											<small>{{ getErrorMessage('projects') }}</small>
										</p>
										<Banner :fixed="true" :open="true" type="warning" class="mt-3"> Si quita o cambia proyectos, esto podrá afectar a la selección de grupos que pertenece el usuario o puede ver. Si un grupo pertenece a un proyecto que se está quitando, el grupo también desaparecerá. </Banner>
									</div>
								</div>

								<div class="" v-if="editFormPart == 'belongsTo'">
									<!-- Page header -->
									<div class="p-3" v-if="!loading">
										<Button v-if="userGroups.length" size="xs" class="text-xs" @click="toggleAllBelongsTo">{{ userDataForm.belongsTo.length === userGroups.length ? 'Quitar selección' : 'Seleccionar todos' }}</Button>
										<div class="grid text-sm gap-3 md:grid-cols-2 mt-4">
											<div v-for="userGroup in userGroups" :key="userGroup.label">
												<label class="flex items-center">
													<input v-model="userDataForm.belongsTo" :value="userGroup._id" type="checkbox" class="form-checkbox" />
													<span class="text-sm ml-2">{{ userGroup.label }} ({{ userGroup.usersCount }})</span>
												</label>
											</div>
										</div>
										<Banner v-if="!userGroups.length" :open="true" type="warning" :fixed="true" class="mb-3">Selecciona uno o vario proyectos para poder buscar los grupos correspondientes.</Banner>
									</div>
								</div>
								<div class="" v-if="editFormPart == 'groups'">
									<!-- Page header -->
									<div class="p-3" v-if="!loading">
										<Button v-if="userGroups.length" size="xs" class="text-xs ml-2" @click="toggleAllGroups">{{ userDataForm.groups.length === userGroups.length ? 'Quitar selección' : 'Seleccionar todos' }}</Button>
										<div class="grid text-sm gap-3 md:grid-cols-2 mt-4">
											<div v-for="userGroup in userGroups" :key="userGroup.label">
												<label class="flex items-center">
													<input v-model="userDataForm.groups" :value="userGroup._id" type="checkbox" class="form-checkbox" />
													<span class="text-sm ml-2">{{ userGroup.label }} ({{ userGroup.usersCount }})</span>
												</label>
											</div>
										</div>
										<Banner v-if="!userGroups.length" :open="true" type="warning" :fixed="true" class="mb-3">Selecciona uno o vario proyectos para poder buscar los grupos correspondientes.</Banner>
									</div>
								</div>

								<!-- Page header -->

								<div class="p-3" v-if="!loading && editFormPart == 'users'">
									<div class="flex flex-col">
										<div class="flex gap-4 h-full">
											<Multiselect :multiple="true" name="usersFilter" v-model="selectedUsers" track-by="_id" :custom-label="u => `${u.name} ${u.surname}`" placeholder="Seleccionar usuarios" :closeOnSelect="true" :options="users" :showNoResults="true" :clearOnSelect="false" :preserveSearch="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado usuarios. </template>
											</Multiselect>
										</div>
									</div>
								</div>

								<!-- TODO Mostrar permisos que NO estén dentro de los permisos de rol -->
								<div class="p-3" v-if="!loading && editFormPart == 'permissions'">
									<div class="grid text-sm gap-3 md:grid-cols-2 mt-4">
										<div v-for="[category, permissions] in Object.entries(availableCustomPermissions)" :key="category">
											<Accordion :title="permissionCategories[category]">
												<div class="mt-2">
													<div v-for="permission in permissions" :key="permission">
														<label class="flex items-center">
															<input v-model="userDataForm.permissions" :value="permission.permission" type="checkbox" class="form-checkbox" />
															<span class="text-sm ml-2">{{ permission.label }} </span>
														</label>
													</div>
												</div>
											</Accordion>
										</div>
									</div>
								</div>
							</form>
						</div>
					</template>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { User } from '@/apps/go/interfaces/User'
import { ValidationError } from '@/errors/ValidationError'
import { user as userLS } from '@/user'
import { Ref, onMounted, reactive, ref } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute } from 'vue-router'
import Accordion from '../../components/Accordion.vue'
import Badge from '../../components/Badge.vue'
import Button from '../../components/Button.vue'
import { Project } from '../../interfaces/Project'
import { get, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const projects: Ref<Project[]> = ref([])
const organizations = ref([])
const permissions = ref([])
const roles = ref([])
const users = ref([])
const userGroups = ref([])
const territories = ref([])
const networkTypes = ref([])

const selectedProjects: Ref<Project[]> = ref([])
const selectedUsers = ref([])
const loading = ref(false)
const loadingProjectCount = ref(false)

const saving = ref(false)
const isSaved = ref(false)
const formSubTitle = ref('')
const editFormOpen = ref(false)
const editFormPart = ref(null)
const _id = route.query.user
const dealsCount = ref(0)
const projectMaxDealsCount = ref(null)
const rolePermissions = ref([])
const availableCustomPermissions = ref<Record<string, any>>({})

const getErrorMessage = useValidationErrorsMessages(error)
const hasObjectStrictErrorMessage = () => {
	return !!error.value?.errors?.find(e => e.type == 'objectStrict')
}

const toggleEditForm = (form?: string) => {
	const subTitles: Record<string, any> = {
		profile: 'Perfil',
		belongsTo: '¿A qué grupos pertenece?',
		groups: '¿Qué grupos puede ver?',
		users: '¿Qué usuarios específicos puede ver?',
		permissions: 'Permisos de usuario'
	}
	if (!editFormOpen.value) {
		editFormOpen.value = true
		editFormPart.value = form
		formSubTitle.value = form && subTitles[form]
		userDataForm = reactive({ ...user })
		setSelectedUsers(user)
		setSelectedProjects(user)
	} else {
		editFormOpen.value = false
		editFormPart.value = null
		error.value = <any>{}
	}
}

let user: User | null = null

let userDataForm: typeof user = reactive(<User>{ permissions: [] })

const toggleAllBelongsTo = () => {
	userDataForm.belongsTo = userDataForm.belongsTo.length === userGroups.value.length ? [] : userGroups.value.map(group => group._id)
}
const toggleAllGroups = () => {
	userDataForm.groups = userDataForm.groups.length === userGroups.value.length ? [] : userGroups.value.map(group => group._id)
}

const getUserName = userId => {
	const userFound = users.value.find(({ _id }) => _id === userId) || null
	if (userFound) {
		return `${userFound.name} ${userFound.surname}`
	}
}
const getPermissionLabel = permissionKey => {
	const permissionFound = permissions.value.find(({ permission }) => permission === permissionKey) || null
	if (permissionFound) {
		return permissionFound.label
	}
}

const getUserDealsCount = async () => {
	loadingProjectCount.value = true
	error.value = <any>{}
	try {
		const max = 0
		if (user.projects) {
			for (const project of <Array<string>>user.projects) {
				const { data } = await get({ name: 'deal.count', params: { project }, query: { user: route.query.user, finishedAt: 'false' } })
				const projectFound = projects.value.find(pr => pr.name === project)
				if (projectFound) {
					projectFound.count = data
				}
				if (data.count > max) {
					projectMaxDealsCount.value = project
				}

				dealsCount.value += data
			}
		}
	} catch (err) {
		error.value = err
	}
	loadingProjectCount.value = false
}

const back = () => {
	history.back()
}
const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { sort: 'label', projection: 'name,label' } })
		projects.value = data
		if (user.projects?.length) {
			selectedProjects.value = data.filter((project: Project) => user.projects?.includes(project.name))
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const fetchOrganizations = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'organization', query: { sort: 'name' } })
		organizations.value = data
		userDataForm.organization = organizations.value.find(item => item._id === user.organization)?._id || null
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchTerritories = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'territory', query: { projection: 'label,name' } })
		territories.value = data
		userDataForm.territory = territories.value.find(item => item.name === user.territory)?.name || null
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchNetworkTypes = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'network-type', query: { projection: 'label,name' } })
		networkTypes.value = data

		userDataForm.networkType = networkTypes.value.find(item => item.name === user.networkType)?.name || null
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const getOrganizationName = _id => {
	return organizations.value.find(item => item._id === _id)?.name || '-'
}

const getTerritoryName = name => {
	return territories.value.find(item => item.name === name)?.label || '-'
}

const getNetworkTypeName = name => {
	return networkTypes.value.find(item => item.name === name)?.label || '-'
}

const permissionCategories = {
	users: 'Gestión de usuarios',
	deals: 'Gestión de oportunidades',
	extra: 'Funcionalidades adicionales',
	permissions: 'Gestión de roles y permisos',
	others: 'Otros'
}

const fetchPermissions = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'permission', query: { limit: 1000, projection: 'permission,label,category' } })
		permissions.value = data

		availableCustomPermissions.value = permissions.value
			.filter(permission => !rolePermissions.value?.includes(permission.permission))
			.reduce((acc, permission) => {
				if (userLS.value?.role != 'root' && permission.private) {
					return acc
				}
				if (!permission.category) {
					permission.category = 'others'
				}
				if (!acc[permission.category]) {
					acc[permission.category] = []
				}
				acc[permission.category].push(permission)
				return acc
			}, {})
		userDataForm.permissions = permissions.value.map(permission => user.permissions?.includes(permission.permission) && permission.permission).filter(Boolean)
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchRoles = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'role', query: { projection: 'name,label' } })
		roles.value = data
		const userRole = data.find(({ _id }) => user.role === _id)
		rolePermissions.value = userRole?.permissions || []
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const setSelectedUsers = from => {
	selectedUsers.value = users.value.filter(user => from.users?.includes(user._id))
}
const setSelectedProjects = from => {
	selectedProjects.value = projects.value.filter(project => from.projects?.includes(project.name))
}

const fetchUsers = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'user', query: { limit: 5999, sort: 'name,surname', projection: 'name,surname' } })
		users.value = data
		if (user.users?.length) {
			setSelectedUsers(userDataForm)
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchUserGroups = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'group', query: { projection: 'label,users', project: decodeURIComponent(user.projects?.join(',')) } })
		userGroups.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'user', params: { _id: route.query.user } })
		user = reactive(data)
		userDataForm = reactive(data)
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	userGroups.value = []
	await fetch()
	if (user) {
		fetchRoles()
		fetchProjects()
		fetchOrganizations()
		fetchTerritories()
		fetchNetworkTypes()
		getUserDealsCount()
		fetchUsers()
		fetchUserGroups()
		fetchPermissions()
	}
})

const onCloseBanner = () => {
	isSaved.value = false
}

const save = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}
	userDataForm.groups = userGroups.value.filter(g => userDataForm.groups?.includes(g._id)).map(g => g._id)
	userDataForm.belongsTo = userGroups.value.filter(g => userDataForm.belongsTo?.includes(g._id)).map(g => g._id)
	userDataForm.users = selectedUsers.value.map(u => u._id)

	userDataForm.projects = selectedProjects.value.map(p => p.name)
	// Remove null string values
	Object.keys(userDataForm).forEach(key => {
		if (userDataForm[key] === 'null') {
			delete userDataForm[key]
		}
	})
	error.value = <any>{}
	try {
		const { config, createdAt, lastLogin, noManagedDeals, updatedAt, deletedAt, ...restUserDataForm } = userDataForm
		await put({ name: 'user', params: { _id } }, restUserDataForm)
		await fetch()
		await fetchRoles()
		getUserDealsCount()
		fetchUserGroups()
		fetchPermissions()
		fetchUsers()
		fetchUserGroups()
		fetchPermissions()
		editFormOpen.value = false
	} catch (err) {
		error.value = err
	}

	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}

const activateDeactivateUser = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}
	try {
		// is Active
		if (user?.active) {
			await put({ name: 'user.deactivate', params: { _id } })
			user.active = false
		} else {
			await put({ name: 'user.activate', params: { _id } })
			user.active = true
		}
	} catch (err) {
		error.value = err || {}
	}
	await fetch()
	getUserDealsCount()
	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}
</script>
