<template>
	<teleport to="body">
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="props.deal">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-1/4" @click.stop>
						<div class="bg-gray-50 px-4 py-3 border-b">
							<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Notificaciones</h3>
						</div>
						<div>
							<div class="grow p-3 bg-gray-100">
								<div v-if="!notifications.length">
									<Banner type="info" :open="true" :fixed="true" class="mb-3">No hay notificaciones para esta oportunidad</Banner>
								</div>
								<div class="col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200" v-if="notifications.length">
									<div class="px-4 pt-4 border-b border-gray-200">
										<ul class="-my-2">
											<li class="relative py-2" :key="i" v-for="(notification, i) in notifications">
												<div class="flex items-center mb-1">
													<div class="absolute top-0 left-0 h-full w-0.5 bg-gray-200 self-start ml-2.5 translate-y-3" aria-hidden="true" :class="{ 'max-h-[50%] top-[50%]': !i, 'max-h-[40%]': i + 1 == props.deal.notifications.length }"></div>
													<icon :name="`${notification.error ? 'ri-close-circle-line' : !notification.executedAt ? 'ri-time-line' : 'ri-mail-check-line'}`" class="`text-white absolute left-0 bg-white rounded-full p-0 m-0" :class="{ 'fill-green-500': notification.executedAt, 'fill-red-500': notification.error, 'fill-gray-500': !notification.executedAt }" scale="1.2" />
													<div class="flex flex-col pl-9">
														<h3 class="font-bold text-gray-800" :class="{ 'text-red-600': notification.error }">
															{{ notification.label }}
														</h3>
														<div>
															<div v-if="notification.expiresAt && user.permissions?.includes('deal.notifications.expiresAt.show')" class="italic text-gray-600 text-xs">Expiración {{ dayjs(notification.expiresAt).format('DD/MM/YYYY HH:mm') }}</div>
															<span class="italic text-gray-600 text-xs">Creación {{ dayjs(notification.createdAt).format('DD/MM/YYYY HH:mm') }}</span>
															<div v-if="notification.executedAt">Enviado {{ dayjs(notification.executedAt).format('DD/MM/YYYY HH:mm') }}</div>
															<div v-else>Programado {{ dayjs(notification.date).format('DD/MM/YYYY HH:mm') }}</div>
															<div v-if="notification.error" class="text-red-600">Error {{ notification.error.message }}</div>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cerrar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import Button from '../../components/Button.vue'
import Banner from '../../components/Banner.vue'
import { Deal } from '../../interfaces/Deal'
import { computed } from 'vue'
const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()

const notifications = computed(() => {
	return props.deal.notifications.sort((a, b) => dayjs(a.date).get('millisecond') - dayjs(b.date).get('millisecond'))
})
</script>
