<template>
	<Default :loading="loading">
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Usuarios</h1>
						<!-- More actions -->
						<div class="flex flex-col md:flex-row gap-y-2 md:gap-x-2">
							<Button v-if="user.permissions?.includes('user.download')" :disabled="loadingDownload" size="md" color="primary" :loading="loadingDownload" @click="download" loadingLabel="...Descargando informe">
								<icon name="ri-download-2-line" class="mr-1" />
								Descargar informe
							</Button>
							<Button v-if="user.permissions?.includes('user.create')" @click="$router.push({ name: 'go.newUser' })">
								<icon name="ri-add-circle-line" class="mr-1" />
								Añadir usuario
							</Button>
						</div>
					</div>

					<div class="flex flex-col md:flex-row md:items-center justify-end mt-4 gap-2">
						<div>
							<input type="text" class="form-input" v-model="options.q" placeholder="Buscar por nombre, email..." @input="resetSkip" />
						</div>
						<div v-if="user.projects.length > 1" class="flex flex-col md:flex-row gap-y-2 md:gap-x-2 md:items-center">
							<Multiselect name="projects" id="projects" v-model="options.projects" track-by="name" label="label" placeholder="Seleccionar proyectos" :options="projects" :showNoResults="true" :clearOnSelect="false" :closeOnSelect="false" :preserveSearch="true" :hideSelected="true" :multiple="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
								<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
								<template #noOptions>No se han encontrado proyectos. </template>
							</Multiselect>
						</div>
					</div>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								Resultados:
								<span class="text-slate-400">{{ total }}</span>
							</header>
							<div class="gap-2 flex items-center">
								<div>
									Mostrar:
									<select v-model="options.limit" class="ml-2 form-select flex-1 md:flex-auto" @change="resetSkip">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="150">150</option>
									</select>
								</div>
							</div>
						</div>

						<!-- Table -->
						<div class="overflow-x-auto" v-if="!loading && items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('name')">
													<p class="font-semibold text-left uppercase mr-1">Usuario</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'name'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('active')">
													<p class="font-semibold text-left uppercase mr-1">Estado</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'active'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('email')">
													<p class="font-semibold text-left uppercase mr-1">Email</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'email'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('lastLogin')">
													<p class="font-semibold text-left uppercase mr-1">Último acceso</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'lastLogin'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr class="even:bg-gray-100 hover:bg-gray-200" v-for="item in items" :key="item._id" @click="goToUserProfile(item)">
										<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{{ item.name }} {{ item.surname }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="inline-flex font-medium rounded-full text-center px-2.5 py-0.5" :class="item.active ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-500'">
												<span v-if="item.active">Activo</span>
												<span v-else>Inactivo</span>
											</div>
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											{{ item.email }}
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<span v-if="item.lastLogin">
												{{ dayjs(item.lastLogin).format('D MMM H:mm') }}
											</span>
											<span v-else> No hay acceso </span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- Pagination -->
					<div class="mt-8 mb-24" v-if="!loading && items.length">
						<Pagination v-show="total > options.limit" :results="items.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-if="(!loading && !items.length) || !!error.message">
						<Banner type="error" :open="!!error?.message" fixed>
							<p>{{ error.message }}</p>
							<ul v-if="error?.errors">
								<li v-for="(err, i) in error?.errors" :key="i">
									<p class="p-3">- {{ err.message }}</p>
								</li>
							</ul>
						</Banner>
						<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
							<div class="text-center py-6">
								<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
									<icon name="ri-information-line" scale="2" />
								</div>
								<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado roles de usuarios.</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import { Ref, onMounted, reactive, ref, watch, computed } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute, useRouter } from 'vue-router'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination.vue'
import { Project } from '../../interfaces/Project'
import { get } from '../../services/api'
import Default from '../Default.vue'
import { queryRegExpToStr } from '../../utils/queryRegExpToStr'
import { strToRegExp } from '../../utils/strToRegExp'
const API = import.meta.env.VITE_API

const route = useRoute()
const options: Record<string, any> = reactive({
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || 'name',
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	q: route.query.q ? queryRegExpToStr(route.query.q.toString()) : undefined
})

const loadingDownload = ref(false)
const loading = ref(false)
const items = ref([])
const router = useRouter()
const total = ref(0)
const error = ref<ValidationError>(<any>{})
const projects: Ref<Project[]> = ref([])
const searchText = ref('')
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })

const query = computed(() => {
	return {
		...route.query,
		...options,
		projects: options.projects.length ? options.projects.map(p => p.name).join(',') : undefined,
		q: options.q ? strToRegExp(options.q) : undefined
	}
})

const goToUserProfile = item => {
	if (user.value.permissions.includes('user.update'))
		router.push({
			name: 'go.userProfile',
			query: {
				user: item._id
			}
		})
}

const search = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		await router.replace({
			query: query.value
		})
		items.value = []
		const { data, pagination } = await get({ name: 'user', query: route.query })
		total.value = pagination.total
		items.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { sort: 'name', projection: 'name,label' } })
		projects.value = data.filter((project: Project) => user.value?.projects?.includes(project.name)) || []
		options.projects = [...((<string>route.query.projects)?.split(',').length ? projects.value.filter(p => route.query.projects?.includes(p.name)) : [])]
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const handleSort = (column: string) => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const download = async () => {
	loadingDownload.value = true
	try {
		const { data } = await get({ name: 'user.download' })
		const base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
		const xlsxBlob = await fetch(base64Uri).then(res => res.blob())
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(xlsxBlob)
		link.download = `USERS_${dayjs().format('DD-MM-YYYY')}.xlsx`
		link.click()
		link.remove()
	} catch (err) {
		error.value = err
	}
	loadingDownload.value = false
}

watch(options, () => {
	search()
})

onMounted(() => {
	search()
	fetchProjects()
	if (route.query.name) {
		let searchSubText = (<string>route.query.name).substring(7)
		searchSubText = (<string>searchSubText).slice(0, searchSubText.length - 10)
		searchText.value = searchSubText.replace(/[\\]/g, '')
	}
})

const resetSkip = () => {
	options.skip = 0
}

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
</script>
