import dayjsLib from 'dayjs'
import 'dayjs/locale/es'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjsLib.locale('es') // use loaded locale globally
dayjsLib.extend(utc)
dayjsLib.extend(timezone)
dayjsLib.extend(advancedFormat)
dayjsLib.extend(relativeTime)
dayjsLib.extend(duration)
dayjsLib.extend(relativeTime)

export const dayjs = dayjsLib
