<template>
	<div class="relative inline-flex" ref="trigger">
		<button color="secondary" aria-haspopup="true" @click.stop="dropdownOpen = !dropdownOpen" :aria-expanded="dropdownOpen" iconRight="ri-arrow-down-s-line">
			<icon :name="iconLeft" class="opacity-70 w-5 h-5 mr-1" />
			{{ title }}
		</button>
		<transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0 -translate-y-2" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-out duration-200" leave-from-class="opacity-100" leave-to-class="opacity-0">
			<div v-show="dropdownOpen" class="origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 p-1.5 rounded overflow-hidden mt-1" :class="align === 'right' ? 'right-0' : 'left-0'">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

defineProps({
	title: { type: String, required: true },
	iconLeft: { type: String, required: false },
	align: { type: String, required: false, default: 'right' }
})

const emit = defineEmits(['onClose'])

const dropdownOpen = ref(false)
const trigger = ref(null)
const dropdown = ref(null)

// close on click outside
const clickHandler = ({ target }) => {
	if (!dropdownOpen.value || dropdown.value?.contains(target) || !trigger.value?.contains(target)) {
		emit('onClose')
		return (dropdownOpen.value = false)
	}
}

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
	if (!dropdownOpen.value || keyCode !== 27) return
	emit('onClose')
	dropdownOpen.value = false
}

onMounted(() => {
	document.addEventListener('click', clickHandler)
	document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
	document.removeEventListener('click', clickHandler)
	document.removeEventListener('keydown', keyHandler)
})
</script>
