<template>
	<div class="relative inline-flex" ref="trigger">
		<button aria-haspopup="true" class="flex justify-center w-8 h-8 items-center bg-primary-600 rounded-full text-white uppercase" @click.stop="dropdownOpen = !dropdownOpen" :aria-expanded="dropdownOpen">{{ user.name[0] }}</button>
		<transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0 -translate-y-2" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-out duration-200" leave-from-class="opacity-100" leave-to-class="opacity-0">
			<div v-show="dropdownOpen" class="origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded overflow-hidden mt-1" :class="align === 'right' ? 'right-0' : 'left-0'">
				<div class="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200" v-if="user">
					<div class="font-medium text-gray-800">
						{{ user.name }}
					</div>
					<div class="text-xs text-gray-500 italic">
						{{ user.surname }}
					</div>
				</div>
				<div class="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200 pointer-events-none" v-if="user">
					<div class="text-xs italic text-gray-400">Último login {{ dayjs(user.lastLogin).fromNow() }}</div>
				</div>
				<ul ref="dropdown">
					<li>
						<router-link class="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3" :to="{ name: 'go.logout' }" @click.stop="dropdownOpen = false">Salir</router-link>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import { onMounted, onUnmounted, ref } from 'vue'
import Button from './Button.vue'

defineProps({
	align: { type: String, required: true }
})

const dropdownOpen = ref(false)
const trigger = ref(null)
const dropdown = ref(null)

// close on click outside
const clickHandler = ({ target }) => {
	if (!dropdownOpen.value || dropdown.value?.contains(target) || !trigger.value?.contains(target)) {
		return (dropdownOpen.value = false)
	}
}

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
	if (!dropdownOpen.value || keyCode !== 27) return
	dropdownOpen.value = false
}

onMounted(() => {
	document.addEventListener('click', clickHandler)
	document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
	document.removeEventListener('click', clickHandler)
	document.removeEventListener('keydown', keyHandler)
})
</script>
