<template>
	<div
		class="inline-flex items-center text-sm font-medium text-gray-100 bg-gray-700 rounded-full text-center px-2 py-0.5"
		:class="{
			[`text-${size}`]: !!size,
			[colorTypeClasses[randomColor ? 'randomColor' : color]]: !!colorTypeClasses[randomColor ? 'randomColor' : color]
		}"
		:style="[randomColor ? { backgroundColor: getRandomColor() } : {}]"
	>
		<icon v-if="iconLeft || icon" :name="iconLeft || icon" class="opacity-70" :class="{ 'mr-1': !!hasSlots }" />
		<slot></slot>
		<icon v-if="iconRight" :name="iconRight" class="opacity-70" :class="{ 'ml-1': !!hasSlots }" />
	</div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { memory } from '../services/memory'

const hasSlots = !!(useSlots().default && useSlots().default()?.length)

type ColorType = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success' | 'warning' | 'randomColor'
type Size = 'xs' | 'sm' | 'md' | 'lg'

const props = withDefaults(
	defineProps<{
		color?: ColorType
		size?: Size
		icon?: string
		iconLeft?: string
		iconRight?: string
		randomColor?: boolean
		cachedRandomColorKey?: string
	}>(),
	{
		color: 'primary',
		size: 'md'
	}
)

const colorTypeClasses: Record<ColorType, string> = {
	primary: 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200',
	secondary: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
	tertiary: 'border-gray-200 hover:border-gray-300 text-gray-600',
	danger: 'bg-red-100 text-red-600 hover:bg-red-200',
	success: 'bg-green-100 text-green-600 hover:bg-green-200',
	warning: 'bg-yellow-100 text-yellow-600 hover:bg-yellow-200',
	randomColor: 'text-gray-600 hover:brightness-90'
}

const getRandomColor = () => {
	if (!memory.badgeCachedColorKey) {
		memory.badgeCachedColorKey = {}
	}
	if (!memory.badgeCachedColorKey[props.cachedRandomColorKey]) {
		memory.badgeCachedColorKey[props.cachedRandomColorKey] = `hsl(${290 * Math.random()}, ${25 + 70 * Math.random()}%, ${85 + 10 * Math.random()}%)`
		return memory.badgeCachedColorKey[props.cachedRandomColorKey]
	}

	return props.cachedRandomColorKey ? memory.badgeCachedColorKey[props.cachedRandomColorKey] : `hsl(${290 * Math.random()}, ${25 + 70 * Math.random()}%, ${85 + 10 * Math.random()}%)`
}
</script>
