export class InternalServerError extends Error {
	status: number
	meta: Record<string, any> | undefined

	constructor(message: string, meta?: Record<string, any>) {
		super(message)
		this.name = this.constructor.name
		this.status = 500
		this.meta = meta
		Object.setPrototypeOf(this, new.target.prototype)
	}
}
