<template>
	<li class="mb-1 last:mb-0 relative">
		<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(activeCondition && 'bg-indigo-400 bg-opacity-60') || (!expanded && 'hover:bg-[#ff6c2c] hover:bg-opacity-30')" href="#0" @click.prevent="handleClick">
			<div class="flex justify-between items-center">
				<div class="flex items-center">
					<icon :name="iconName" class="opacity-70 w-6 h-6" />
					<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">{{ title }}</span>
				</div>
				<!-- Icon -->
				<div class="flex shrink-0">
					<icon name="ri-arrow-right-s-line" class="transition-all duration-150" iconSize="16" :class="expanded && 'transform rotate-90'" />
				</div>
			</div>
		</a>
		<!-- content -->
		<div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
			<ul ref="sidebarContent" class="pl-10 mt-1 max-h-0 overflow-y-hidden transition-all duration-100 ease-in-out" :style="{ ...(expanded && { maxHeight: `${maxHeight}px` }) }">
				<slot></slot>
			</ul>
		</div>
	</li>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUpdated, ref, watch } from 'vue'

const props = defineProps({
	activeCondition: Boolean,
	title: String,
	iconName: String
})

const sidebarContent = ref(null)
const maxHeight = ref(0)
const expanded = ref(props.activeCondition)
const handleClick = () => {
	expanded.value = !expanded.value
}
onMounted(() => {
	maxHeight.value = sidebarContent.value?.scrollHeight
})
onUpdated(async () => {
	await nextTick()
	maxHeight.value = sidebarContent.value?.scrollHeight
})

// Watchers
watch(
	() => props.activeCondition,
	() => {
		expanded.value = props.activeCondition
	}
)
</script>
