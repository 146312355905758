<template>
	<teleport to="body">
		<div class="fixed top-0 left-0 w-full h-screen text-xl bg-gray-700 bg-opacity-50 z-50" ref="trigger">
			<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
				<div class="fixed inset-0 z-10 w-full overflow-y-auto">
					<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
						<div class="relative transform overflow-hidden rounded-sm bg-white text-left shadow-xl transition-all w-full md:w-3/4 lg:w-1/2">
							<div class="absolute -right-0 z-10 bg-white cursor-pointer" @click="$emit('close')">
								<icon name="ri-close-line" scale="1.5" />
							</div>
							<div class="bg-white p-3">
								<header>
									<div class="relative flex justify-center items-center mb-2">
										<img class="w-full max-w-[130px] object-contain" :src="data.vendorLogo" alt="" />
									</div>
									<div class="relative flex justify-center gap-2 flex-wrap sm:flex-nowrap text-sm">
										<div class="w-full sm:w-[50%]">
											<div class="bg-blue-900 bg-opacity-10 p-1 px-2">
												<h3 class="font-bold text-md">Resumen</h3>
											</div>
											<div class="mt-2">
												<p><b>Fecha factura: </b>{{ dayjs().format('DD/MM/YYYY') }}</p>
												<p v-if="invoicePeriod?.invoicePeriodBegin && invoicePeriod?.invoicePeriodEnd"><b>Período de facturación: </b>{{ invoicePeriod.invoicePeriodBegin }} al {{ invoicePeriod.invoicePeriodEnd }}</p>
												<p><b>Total factura: </b>{{ parseCurrency(data.vendorElectricalConsumption) }}</p>
												<p><b>Forma de pago: </b>Recibo domiciliado</p>
												<p><b>Fecha de cargo: </b>- días fecha de factura</p>
											</div>
										</div>
										<div class="w-full sm:w-[50%]">
											<div class="bg-blue-900 bg-opacity-10 p-1 px-2">
												<h3 class="font-bold text-md">Punto de Suministro</h3>
											</div>
											<div class="mt-2">
												<p><b>Población: </b>{{ props.data.customer.town || '--' }}</p>
												<p><b>Provincia: </b>{{ props.data.customer.province || '--' }}</p>
												<p><b>CP: </b>{{ props.data.customer.zipCode || '--' }}</p>
												<p>
													<b>Potencia: </b> <badge randomColor v-for="value in getPowerInfo(power) || []" :cached-random-color-key="value" class="m-[.1em] text-xs">{{ value }}</badge>
												</p>
											</div>
										</div>
									</div>
								</header>

								<div>
									<table class="w-full text-sm mt-4" v-for="detail in details">
										<th class="whitespace-nowrap w-full text-left"><span v-html="detail.title"></span></th>
										<th class="whitespace-nowrap w-full text-right">Importe</th>
										<tbody class="text-sm divide-y divide-gray-200">
											<template v-for="concept in detail.concepts">
												<tr class="even:bg-gray-100 hover:bg-gray-200 cursor-pointer">
													<td colspan="2" class="px-2 font-bold bg-blue-900 bg-opacity-10 p-1">
														<span v-html="concept.title"></span>
													</td>
												</tr>

												<tr v-for="item in concept.items" class="even:bg-gray-100 hover:bg-gray-200 cursor-pointer">
													<td class="px-2 align-top"><span v-html="item.calculations"></span></td>
													<td class="px-2 text-right align-top"><strong v-html="item.amount"></strong></td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>

								<footer class="mt-3 text-sm">
									<div class="border p-2">
										<div v-for="item in props.data.invoiceData.lineasFactura?.pie" :key="item.lineaDetalle.lineaDetalleCalculos" class="flex justify-between">
											<div v-html="item.lineaDetalle.lineaDetalleCalculos"></div>
											<div v-html="item.lineaDetalle.lineaDetalleImporte"></div>
										</div>
										<div v-for="item in props.data.invoiceData.lineasFactura?.total" :key="item.lineaDetalle.lineaDetalleCalculos" class="flex justify-between font-bold">
											<div v-html="item.lineaDetalle.lineaDetalleCalculos"></div>
											<div><span v-html="item.lineaDetalle.lineaDetalleImporte"></span></div>
										</div>
									</div>
									<div class="text-xs border p-2 mt-4" v-for="item in props.data.invoiceData.lineasFactura?.observaciones || []">
										<p class="font-bold" v-html="item.lineaTitulo"></p>
										<p v-html="item.lineaDetalle.lineaDetalleCalculos"></p>
									</div>
								</footer>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { parseCurrency } from '../../utils/parseCurrency'
import Badge from '@/apps/go/components/Badge.vue'
import Button from '@/apps/go/components/Button.vue'

const props = defineProps<{ data: any; cups: string; rate: string; power: Record<string, any>; invoicePeriod: any }>()

const trigger = ref(null)
const container = ref(null)
const emit = defineEmits(['close'])

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
	if (keyCode !== 27) return
	emit('close')
}

onMounted(() => {
	document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
	document.removeEventListener('keydown', keyHandler)
})

const details = computed(() => {
	const itemsGroup: any[] = []
	for (const element of props.data.invoiceData.lineasFactura?.cuerpo || []) {
		if (element.lineaTitulo?.length) {
			itemsGroup.push({
				title: element.lineaTitulo,
				concepts: [
					{
						title: element.lineaConcepto,
						items: [
							{
								calculations: element.lineaDetalle.lineaDetalleCalculos,
								amount: element.lineaDetalle.lineaDetalleImporte
							}
						]
					}
				]
			})
		} else if (element.lineaConcepto?.length) {
			itemsGroup[itemsGroup.length - 1].concepts.push({
				title: element.lineaConcepto,
				items: [
					{
						calculations: element.lineaDetalle.lineaDetalleCalculos,
						amount: element.lineaDetalle.lineaDetalleImporte
					}
				]
			})
		} else {
			itemsGroup[itemsGroup.length - 1].concepts[itemsGroup[itemsGroup.length - 1].concepts.length - 1].items.push({
				calculations: element.lineaDetalle.lineaDetalleCalculos,
				amount: element.lineaDetalle.lineaDetalleImporte
			})
		}
	}
	return itemsGroup
})

const getPowerInfo = power => {
	return Object.entries(power || {}).reduce((acc, [key, value]) => {
		if (value) {
			acc.push(`${key.slice(5, 8)} - ${value}`)
		}
		return acc
	}, [])
}
</script>
