import { divIcon } from 'leaflet'
import { MarkerGroup } from '../interfaces/MarkerGroup'

// export function _getMarkerGroupIcon(group: MarkerGroup) {
// 	const plus = group.items.length > 1 ? '_plus' : ''
// 	const status = group.meta.deals ? (group.meta.deals == group.items.length ? '_complete' : '_partial') : ''
// 	let index = group.meta.index

// 	if (group.meta.product == 'finance') {
// 		if (index % 1000 === 0) {
// 			index = index + 1
// 		}
// 		if (index > 10000) {
// 			index = 11000
// 		} else if (index > 5000) {
// 			index = 10000
// 		}
// 		index = Math.ceil(index / 1000)
// 		return new Icon({
// 			...base,
// 			iconUrl: new URL(`../images/map/finance/marker_${index}k${plus}${status}.png`, import.meta.url)
// 		})
// 	} else {
// 		const rate = [2, 3, 6.1, 6.2, 6.3, 6.4].includes(index) ? index.toFixed(1) : 'unknow'
// 		return new Icon({
// 			...base,
// 			iconUrl: new URL(`../images/map/energy/marker_${rate}${plus}${status}.png`, import.meta.url)
// 		})
// 	}
// }

export function getMarkerGroupIcon(group: MarkerGroup) {
	const icon = group.items.length > 1 ? 'plus' : group.meta.deals.length ? 'check' : null
	const iconColor = group.meta.deals.length ? (group.meta.deals.length == group.items.length ? 'green' : 'orange') : 'red'
	let index = group.meta.index
	let text = '?'
	let color = '#2158e8'

	const product = import.meta.env.VITE_PRODUCT || 'energy'

	if (product == 'finance') {
		if (index % 1000 === 0) {
			index = index + 1
		}
		if (index > 10000) {
			index = 11000
		} else if (index > 5000) {
			index = 10000
		}
		index = Math.ceil(index / 1000)

		const colors = {
			1: '#2158e8',
			2: '#17a03d',
			3: '#9e4bf6',
			4: '#601e9e',
			5: '#dd1b3f',
			10: '#951332',
			11: '#801b37'
		}

		text = colors[index] ? `${index == 11 ? '+10' : `<${index}`}k` : '?'
		color = colors[index] || color
		return makeIcon(text, color, icon, iconColor)
	} else {
		const colors = {
			2.0: '#2158e8',
			3.0: '#17a03d',
			3.1: '#063012',
			6.1: '#9e4bf6',
			6.2: '#601e9e',
			6.3: '#dd1b3f',
			6.4: '#951332'
		}

		text = colors[index] ? index.toFixed(1) : '?'
		color = colors[index] || color
		return makeIcon(text, color, icon, iconColor)
	}
}

export function getMarkerUserIcon(group: MarkerGroup) {
	const icon = group.meta.deals.length == group.meta.deals.filter(deal => deal.finishedAt).length ? 'check' : group.meta.reported ? 'reported' : null
	const iconColor = group.meta.deals.length ? (group.meta.deals.length == group.items.length ? '#33cc33' : 'orange') : 'red'
	let color = '#666666'
	const text = group.meta.users.map(user => ((user.name || '?')[0] || '').toUpperCase()).join(',')
	color = group.meta.users.length == 1 ? group.meta.users[0].config?.color || color : color
	return makeIcon(text, color, icon, iconColor)
}

function makeIcon(text: string, color: string, icon?: 'check' | 'plus' | 'reported', iconColor?: string) {
	const icons = {
		reported: '<polygon/>',
		check: '<polygon fill="white" points="107.1,144.2 92.9,130.1 97.3,125.7 107.1,135.5 129.7,112.8 134.1,117.2"/>',
		plus: '<path  fill="white" d="M109.9,146.2V133H99V124h10.8v-13.1h7.2V124H128v9.1h-10.9v13.1H109.9z"/>'
	}
	return divIcon({
		className: 'marker',
		html: `<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178"><path fill="${color}" d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/><text text-anchor="middle" xml:space="preserve" style="font-style:normal;font-weight:strong;font-size:50px;line-height:1.25;font-family:Inter; font-weight: 700;fill:#000000;fill-opacity:1;stroke:none" x="74.5" y="82" id="text1"><tspan sodipodi:role="line" x="74.5" y="82" style="fill:#f9f9f9">${text}</tspan></text>${icon ? `<circle fill="${iconColor}" cx="113.5" cy="128.5" r="32"/><g>` : ''}${icons[icon] || ''}${icon ? '</g>' : ''}</svg>`,
		iconAnchor: [22, 22],
		iconSize: [32, 30],
		popupAnchor: [0, 0]
	})
}
