<template>
	<Default>
		<div class="sticky top-14 bg-white z-10">
			<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
				<Button color="tertiary" @click="back">
					<icon name="ri-arrow-left-line" class="mr-1" />
					Volver
				</Button>
			</div>
		</div>
		<Deal v-if="route.query._id" :_id="route.query._id.toString()" :project="route.query.project.toString()" />
	</Default>
</template>

<script setup lang="ts">
import Deal from './Deal.vue'
import Default from '../Default.vue'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'

const route = useRoute()
const back = () => {
	history.back()
}
</script>
