/**
 *
 * EURO (default)
 * - country: es-ES
 * - currency: EUR
 * DOLLAR
 * - country: en-US
 * - currency: USD
 *
 * @param value
 * @param country
 * @param currency
 * @returns
 */
export const parseCurrency = (value: number, country: string = 'es-ES', currency: string = 'EUR') => {
	return new Intl.NumberFormat(country, { style: 'currency', currency }).format(Number(value))
}
