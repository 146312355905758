import { ValidationError } from '@/errors/ValidationError'

import { Ref } from 'vue'

export const useValidationErrorsMessages = (error?: Ref<ValidationError>) => {
	return (field?: string, errorsArray?: Record<string, any>[]) => {
		if ((errorsArray || error?.value?.errors) && field) {
			const errors = errorsArray || error?.value?.errors || []
			errors.forEach(err => err.field && (err.field = err.field?.split('data.').pop()))
			return errors.find(err => err.field === field)?.message
		}

		return error?.value?.message
	}
}
