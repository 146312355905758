<template>
	<teleport to="body">
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="props.deal">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1" v-show="deal">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-1/4" @click.stop>
						<div class="bg-gray-50 px-4 py-3">
							<h3 class="text-base font-semibold leading-6 text-gray-900">Datos Distribuidora</h3>
						</div>
						<div>
							<div class="grow bg-gray-100">
								<ContractSummaryEnergy v-if="deal" :contract="deal.contract" />
							</div>
						</div>
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cerrar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { get } from '../../services/api'
import { Deal } from '../../interfaces/Deal'
import ContractSummaryEnergy from '../../components/leads/ContractSummaryEnergy.vue'
import Button from '../../components/Button.vue'

const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()
const route = useRoute()
const loading = ref(false)
const deal = ref(null)
const error = ref({})

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'deal.preview', params: { project: props.project, _id: props.deal._id } })
		deal.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	fetch()
})
</script>
