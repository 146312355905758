<template>
	<teleport to="body">
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="props.deal">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-1/4"  @click.stop>
						<div class="bg-gray-50 px-4 py-3 border-b">
							<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Historial</h3>
						</div>
						<div>
							<div class="grow p-3 bg-gray-100">
								<div class="col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
									<div class="px-4 pt-4 border-b border-gray-200">
										<div class="grow pb-6 border-b border-gray-200">
											<!-- List -->
											<ul class="-my-2">
												<!-- List item -->
												<li class="relative py-2" :key="i" v-for="(item, i) in props.deal.history">
													<div class="flex items-center mb-1">
														<div class="absolute top-0 left-0 h-full w-0.5 bg-gray-200 self-start ml-2.5 translate-y-3" aria-hidden="true" :class="{ 'max-h-[50%] top-[50%]': !i, 'max-h-[40%]': i + 1 == props.deal.history.length }"></div>
														<icon name="ri-checkbox-circle-fill" class="`text-white absolute left-0 bg-white rounded-full p-0 m-0" :class="{ 'fill-indigo-500': i + 1 == props.deal.history.length, 'fill-green-500': i + 1 !== props.deal.history.length }" scale="1.2" />
														<div class="flex flex-col pl-9 gap-1">
															<h3 class="font-bold text-gray-800">
																{{ item.label }}
															</h3>
															<small class="text-gray-400">{{ dayjs(item.date).format('DD-MM-YYYY HH:mm') }} </small>
															<div v-if="item.data.options?.date">
																<p class="text-sm"><icon name="ri-calendar-check-line" class="mr-1" />{{ dayjs(item.data.options?.date).format('DD-MM-YYYY HH:mm') }}</p>
															</div>
															<div v-if="item.data.from && item.data.to && user?.permissions?.includes('props.deal.history.reassign.show')">
																<p class="text-sm"><icon scale="0.8" name="ri-user-line" class="mr-1" />{{ item.data.from.name }} {{ item.data.from.surname }} <icon name="ri-arrow-right-line" class="mr-1" /><icon scale="0.8" name="ri-user-line" class="mr-1" />{{ item.data.to.name }} {{ item.data.to.surname }}</p>
															</div>
														</div>
													</div>
													<blockquote v-if="item.data.options?.comments" class="italic pl-9 text-sm">
														<p><icon name="ri-message-2-line" class="mr-1" />"{{ item.data.options.comments }}"</p>
													</blockquote>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cerrar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script setup lang="ts">
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import Button from '../../components/Button.vue'
import { Deal } from '../../interfaces/Deal'
const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()
</script>
