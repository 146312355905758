<template>
	<Default :loading="loading">
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full w-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Permisos de Usuarios</h1>
						<!-- More actions -->
						<div class="flex flex-col md:flex-row gap-y-2 md:gap-x-2" v-if="user.permissions?.includes('permission.create')">
							<router-link :to="{ name: 'go.newPermission' }">
								<Button>
									<icon name="ri-add-circle-line" class="mr-1" />
									Añadir permiso
								</Button>
							</router-link>
						</div>
					</div>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								<div>
									<p>
										Resultados:
										<span class="text-slate-400">{{ total }}</span>
									</p>
								</div>
								<div v-if="user.permissions?.includes('permission.find.private')" class="mr-3">
									<label>
										<input type="checkbox" v-model="options.private" class="form-checkbox" />
										<span class="text-sm ml-3">Solo permisos privados</span>
									</label>
								</div>
							</header>

							<div class="gap-2 flex items-center">
								<div>
									Mostrar:
									<select v-model="options.limit" class="ml-2 form-select flex-1 md:flex-auto" @change="onChangeLimit">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="150">150</option>
									</select>
								</div>
							</div>
						</div>

						<Banner v-if="permissionDeleted" :type="permissionDeleted?.success ? 'success' : 'error'" class="mb-3" :open="permissionDeleted?.success"> {{ permissionDeleted?.message }} </Banner>
						<!-- Table -->
						<div class="overflow-x-auto" v-if="!loading && items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('permission')">
													<p class="uppercase mr-1">Permiso</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'permission'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('label')">
													<p class="uppercase mr-1">Label</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'label'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-center">
												<button class="flex items-center" @click="handleSort('description')">
													<p class="uppercase mr-1">Descripción</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'description'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-center">Nº de usuarios</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Acciones</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr class="even:bg-gray-100 hover:bg-gray-200" v-for="item in items" :key="item._id">
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.permission }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.label }}</td>
										<td class="px-2" align="center">
											{{ item.description }}
										</td>
										<td class="px-2" align="center">
											{{ item.usersCount }}
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-1 flex flex-wrap gap-2 min-w-[150px]">
											<Button
												@click="
													router.push({
														name: 'go.permission',
														params: { _id: item._id }
													})
												"
												color="warning"
												:disabled="!user.permissions?.includes('permission.update')"
											>
												<icon name="ri-pencil-line" />
											</Button>
											<Button v-if="user.permissions?.includes('permission.delete')" color="danger" @click="deletePermission(item._id)" :disabled="loading || !!item.usersCount">
												<icon name="ri-delete-bin-7-line" />
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- Pagination -->
					<div class="mt-8 mb-24" v-if="!loading && items.length">
						<Pagination v-show="total > options.limit" :results="items.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-if="(!loading && !items.length) || !!error.message">
						<Banner type="error" :open="!!error?.message" fixed>
							<p>{{ error.message }}</p>
							<ul v-if="error?.errors">
								<li v-for="(err, i) in error?.errors" :key="i">
									<p class="p-2">- {{ err.message }}</p>
								</li>
							</ul>
						</Banner>

						<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
							<div class="text-center py-6">
								<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
									<icon name="ri-information-line" scale="2" />
								</div>
								<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado permisos de usuarios.</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { user } from '@/user'
import { Ref, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination.vue'
import { del, get } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()
const router = useRouter()

const options: Record<string, any> = reactive({
	...route.query,
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || '-createdAt',
	usersCount: true
})

const loading = ref(false)
const items = ref([])
const total = ref(0)
const error = ref<ValidationError>(<any>{})
const permissionDeleted = ref(null)
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })

const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const search = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		await router.replace({
			query: options
		})
		items.value = []

		const query = { ...route.query, projection: 'permission,label,description' }
		const { data, pagination } = await get({ name: 'permission', query: query })
		
		total.value = pagination.total
		items.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const deletePermission = async (_id: string) => {
	if (window.confirm('¿Estás seguro que deseas eliminar el permiso?')) {
		loading.value = true
		try {
			await del({ name: 'permission', params: { _id } })
			await search()
			permissionDeleted.value = {
				success: true,
				message: 'Permiso eliminado correctamente.'
			}
		} catch (error) {
			permissionDeleted.value = {
				success: false,
				message: 'Error al eliminar el permiso.'
			}
		}
		loading.value = false
	}
}

onMounted(() => {
	search()
})

const onChangeLimit = () => {
	options.skip = 0
}

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
watch(options, () => {
	search()
})
</script>
