<template>
	<div v-show="open && !closed" class="px-4 py-2 rounded-sm text-sm" :class="typeColor[type || 'info']">
		<div class="flex w-full justify-between items-start">
			<div class="flex">
				<icon v-if="type === 'warning'" name="ri-error-warning-fill" class="opacity-70 mr-1" />
				<icon v-else-if="type === 'error'" name="ri-close-circle-fill" class="opacity-70 mr1" />
				<icon v-else-if="type === 'success'" name="ri-checkbox-circle-fill" class="opacity-70 mr-1" />
				<icon v-else name="ri-information-fill" class="opacity-70 mr-1" />
				<div class="font-medium">
					<slot />
				</div>
			</div>
			<button v-if="!fixed" class="opacity-70 hover:opacity-80 ml-3 mt-[3px]" @click="closeBanner">
				<div class="sr-only">Close</div>
				<icon name="ri-close-line" class="ml-1" />
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
	type: 'warning' | 'error' | 'success' | 'info'
	open: boolean
	fixed?: boolean
}>()

const emit = defineEmits(['onClose'])

const closed = ref(false)
const closeBanner = () => {
	closed.value = true
	emit('onClose')
}

watch(props, () => {
	if (props.open) {
		closed.value = !props.open
	}
})

const typeColor = {
	warning: 'bg-yellow-300 text-yellow-900',
	error: 'bg-red-500 text-white',
	success: 'bg-green-500 text-white',
	info: 'bg-indigo-500 text-white'
}
</script>
