<template></template>
<script setup lang="ts">
import router from '@/router/router'
import { useRoute } from 'vue-router'
localStorage.removeItem('auth')
localStorage.removeItem('user')
localStorage.removeItem('mapFilterOptions')
localStorage.removeItem('mapAdminFilterOptions')
const { query } = useRoute()
router.push({ name: 'go.login', query })
</script>
