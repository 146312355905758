<template>
	<GeolocationWarnings :error="error" :granted="granted" :support="support" />
	<Map :userMarker="userMarker" :circle="userCircle" :markers="props.markers" :dragging="props.dragging" :box-zoom="props.boxZoom" :zoom-control="props.zoomControl" v-show="granted && support && props.show && !error" :center="props.center" :minZoom="props.minZoom" :zoom="props.zoom" :maxZoom="props.maxZoom" :scrollWheelZoom="props.scrollWheelZoom" @click="$emit('click', $event)" @ready="$emit('ready', $event)" @zoom="$emit('zoom', $event)" />
</template>
<script setup lang="ts">
import { circle, marker } from 'leaflet'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Map from '../components/Map.vue'
import GeolocationWarnings from '../components/geolocation/GeolocationWarnings.vue'
import { userIcon } from './../icons/userIcon'

const props = defineProps({
	distance: {
		type: Number,
		default: 0
	},
	show: {
		type: Boolean,
		default: true
	},
	disableGeolocation: {
		type: Boolean,
		default: false
	},
	zoom: {
		type: Number,
		default: 18,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	minZoom: {
		type: Number,
		default: 5,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	maxZoom: {
		type: Number,
		default: 18,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	center: {
		type: Object,
		default: null
	},
	markers: {
		type: Array,
		default: [],
		validator(value) {
			return Array.isArray(value)
		}
	},
	userMarker: {
		type: Object,
		default: null
	},
	userMarkerAnimate: {
		type: Boolean,
		default: false
	},
	circle: {
		type: Object,
		default: null
	},
	dragging: {
		type: Boolean,
		default: false
	},
	tap: {
		type: Boolean,
		default: false
	},
	zoomControl: {
		type: Boolean,
		default: false
	},
	keyboard: {
		type: Boolean,
		default: false
	},
	boxZoom: {
		type: Boolean,
		default: false
	},
	doubleClickZoom: {
		type: Boolean,
		default: false
	},
	scrollWheelZoom: {
		type: Boolean,
		default: true
	},
	touchZoom: {
		type: Boolean,
		default: false
	},
	// geolocation
	maximumAge: {
		type: Number,
		default: 0
	},
	enableHighAccuracy: {
		type: Boolean,
		default: false
	},
	timeout: {
		type: Number,
		default: 5000
	}
})

const error = ref(null)
const support = ref(true)
const granted = ref(true)
const userMarker = ref(null)
const userCircle = ref(null)
const emit = defineEmits(['click', 'zoom', 'ready', 'center', 'error'])
const geolocationWatcher = ref(null)

const clearWatch = () => {
	if (geolocationWatcher.value) {
		navigator.geolocation.clearWatch(geolocationWatcher.value)
		geolocationWatcher.value = null
	}
}

watch(
	() => props.center,
	value => {
		userMarker.value?.setLatLng(value)
		userCircle.value?.setLatLng(value)
	}
)

watch(
	() => props.distance,
	value => {
		userCircle.value?.setRadius(value)
	}
)

watch(
	() => props.userMarkerAnimate,
	value => {
		userIcon.options.className = 'z-10'
		if (value) {
			userIcon.options.className += ' animate-pulse'
		}
		if (userMarker.value) {
			userMarker.value.setIcon(userIcon)
		}
	}
)

const initGeolocationWatcher = () => {
	geolocationWatcher.value = navigator.geolocation.watchPosition(
		({ coords }) => {
			if (!props.disableGeolocation) {
				emit('center', {
					lat: coords.latitude,
					lng: coords.longitude
				})
			}
		},
		onGeolocationErrorCallback,
		{
			enableHighAccuracy: props.enableHighAccuracy,
			timeout: props.timeout,
			maximumAge: props.maximumAge
		}
	)
}

const onGeolocationErrorCallback = (err: any) => {
	emit('error', err)
	error.value = err
	switch (err.code) {
		case err.PERMISSION_DENIED:
			granted.value = false
			clearWatch()
			break
		case err.POSITION_UNAVAILABLE:
		case err.TIMEOUT:
		case err.UNKNOWN_ERROR:
			console.error(err)
			break
	}
}

onMounted(async () => {
	if (navigator.geolocation) {
		if (props.timeout) {
			initGeolocationWatcher()
		} else {
			navigator.geolocation.getCurrentPosition(
				({ coords }) => {
					if (!props.disableGeolocation) {
						emit('center', {
							lat: coords.latitude.toFixed(6),
							lng: coords.longitude.toFixed(6)
						})
					}
				},
				error => {
					emit('error', error)
					granted.value = false
				}
			)
		}
	} else {
		support.value = false
	}
	userCircle.value = circle(props.center, { radius: props.distance })
	userMarker.value = marker(props.center, { icon: userIcon })
})

onUnmounted(() => {
	clearWatch()
})
</script>
