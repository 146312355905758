<template>
	<teleport to="body">
		<Loading v-show="loading" />
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="!loading">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-4/6" @click.stop>
						<div class="bg-gray-50 px-4 py-3 border-b">
							<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Datos contrato</h3>
						</div>
						<Banner type="success" :open="isUpdated"> Actualizado correctamente </Banner>
						<Banner type="error" :open="errors.generalErrors.length ? true : false">
							<ul>
								<li v-for="error in errors.generalErrors" :key="error">
									{{ error.message }}
								</li>
							</ul>
						</Banner>
						<customerForm v-if="deal?.contract" :customer="deal.contract.customer" @change="updateCustomer" @addComment="addComment" :updatingComment="updatingComment" :commentSaved="commentSaved" :isUpdatingPhoneNumber="updatingPhoneNumber" :isPhoneNumberUpdated="isPhoneNumberUpdated" @addPhone="addPhone" :errors="errors" :isUpdatingEmail="updatingEmail" :isEmailUpdated="isEmailUpdated" @addEmail="addEmail" @deleteEmail="deleteEmail" @deletePhone="deletePhone" :isDeletingPhoneNumber="deletingPhoneNumber" :isDeletingEmail="deletingEmail" />
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button class="ml-2" type="submit" @click="update" :loading="loading" :disabled="updating">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar
							</Button>
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cancelar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { onMounted, ref } from 'vue'
import Button from '../../components/Button.vue'
import { del, get, post, put } from '../../services/api'
import Loading from '../../components/Loading.vue'
import { Customer } from '../../interfaces/Customer'
import { Deal } from '../../interfaces/Deal'
import customerForm from '../../components/forms/CustomerForm.vue'
const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()

const errors = ref({
	generalErrors: [],
	phoneErrors: [],
	commentErrors: [],
	emailErrors: []
})
const loading = ref(false)
const updating = ref(false)
const isUpdated = ref(false)
const deal = ref(null)
const commentSaved = ref(false)
const updatingComment = ref(false)

const isPhoneNumberUpdated = ref(false)
const updatingPhoneNumber = ref(false)
const deletingPhoneNumber = ref(false)

const isEmailUpdated = ref(false)
const updatingEmail = ref(false)
const deletingEmail = ref(false)
const error = ref({})

const fetch = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'deal', params: { project: props.project, _id: props.deal._id } })
		data.contract.comments.reverse()
		data.contract.customer.comments.reverse()
		deal.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	fetch()
})

const updateCustomer = (customer: Customer) => {
	deal.value.contract.customer = customer
}

const addComment = async (comment: { content: string }) => {
	updatingComment.value = true
	commentSaved.value = false
	try {
		const { data } = await post({ name: 'deal.customer.comment', params: { project: props.project, _id: props.deal._id } }, { content: comment.content })
		deal.value.contract.customer.comments.unshift(data)
		commentSaved.value = true
	} catch (error) {
		errors.value = {
			generalErrors: [],
			commentErrors: error.errors || [],
			emailErrors: [],
			phoneErrors: []
		}
	}
	updatingComment.value = false
}

const addPhone = async (phone: { number: string }) => {
	updatingPhoneNumber.value = true
	isPhoneNumberUpdated.value = false
	errors.value.phoneErrors = []
	try {
		const { data } = await post({ name: 'deal.customer.phone', params: { project: props.project, _id: props.deal._id } }, { number: phone.number })
		deal.value.contract.customer.phones.unshift(data)

		isPhoneNumberUpdated.value = true
	} catch (error) {
		errors.value = {
			generalErrors: [],
			commentErrors: [],
			emailErrors: [],
			phoneErrors: error.errors || []
		}
	}
	updatingPhoneNumber.value = false
}

const deletePhone = async ({ id }) => {
	deletingPhoneNumber.value = true

	if (window.confirm('Estás seguro que deseas eliminar el teléfono?')) {
		try {
			await del({ name: 'deal.customer.phone', params: { project: props.project, _id: props.deal._id, phoneId: id } })
			window.alert('Teléfono eliminado correctamente.')
			const i = deal.value.contract.customer.phones.findIndex((phone: any) => phone.id === id)
			if (i > -1) {
				deal.value.contract.customer.phones.splice(i, 1)
			}
			deletingPhoneNumber.value = false
		} catch (error) {
			deletingPhoneNumber.value = false
			// SHOW ERROR
		}
	} else {
		deletingPhoneNumber.value = false
	}
}

const addEmail = async (email: { address: string }) => {
	updatingEmail.value = true
	isEmailUpdated.value = false
	errors.value.emailErrors = []
	try {
		const { data } = await post({ name: 'deal.customer.email', params: { project: props.project, _id: props.deal._id } }, { address: email.address })
		deal.value.contract.customer.emails.unshift(data)
		isEmailUpdated.value = true
	} catch (error) {
		errors.value = {
			generalErrors: [],
			commentErrors: [],
			emailErrors: error.errors || [],
			phoneErrors: []
		}
	}
	updatingEmail.value = false
}

const deleteEmail = async ({ id }) => {
	deletingEmail.value = true

	if (window.confirm('Estás seguro que deseas eliminar el email?')) {
		try {
			await del({ name: 'deal.customer.email', params: { project: props.project, _id: props.deal._id, emailId: id } })
			window.alert('Email eliminado correctamente.')
			const i = deal.value.contract.customer.emails.findIndex((email: any) => email.id === id)
			if (i > -1) {
				deal.value.contract.customer.emails.splice(i, 1)
			}

			deletingEmail.value = false
		} catch (error) {
			deletingEmail.value = false
			// SHOW ERROR
		}
	} else {
		deletingEmail.value = false
	}
}

const update = async (data: Record<string, any>) => {
	loading.value = true
	updating.value = true
	isUpdated.value = false
	errors.value.generalErrors = []

	try {
		await put({ name: 'deal.customer', params: { project: props.project, _id: props.deal._id } }, deal.value.contract.customer)
		isUpdated.value = true
	} catch (error) {
		errors.value = {
			generalErrors: error.errors || [],
			commentErrors: [],
			emailErrors: [],
			phoneErrors: []
		}
	}

	updating.value = false
	loading.value = false
}
</script>
