<template>
	<teleport to="body">
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="props.deal">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-1/4" @click.stop>
						<div class="bg-gray-50 px-4 py-3">
							<h3 class="text-base font-semibold leading-6 text-gray-900">Coordenadas</h3>
						</div>
						<div>
							<div class="grow bg-gray-100">
								<Banner type="success" class="mb-3" :open="isUpdated"> Actualizado correctamente </Banner>
								<Banner type="error" class="mb-3" :open="!!errors.length">
									<ul>
										<li v-for="error in errors" :key="error">
											{{ error.message }}
										</li>
									</ul>
								</Banner>
								<form v-if="deal">
									<div class="bg-white rounded-sm border border-gray-200">
										<div class="text-sm text-gray-800 p-5">
											<div>
												<div class="grid gap-5 grid-cols-1 md:grid-cols-2">
													<div><label class="block text-sm font-medium mb-1">Latitud</label><input class="form-input w-full" type="number" step="0.0001" v-model="lat" /></div>
													<div><label class="block text-sm font-medium mb-1">Longitud</label><input class="form-input w-full" type="number" step="0.0001" v-model="lng" /></div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button class="ml-2" type="submit" @click="update" :loading="loading" :disabled="updating">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar
							</Button>
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cerrar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script setup lang="ts">
import Button from '../../components/Button.vue'
import Banner from '../../components/Banner.vue'
import { Deal } from '../../interfaces/Deal'
import { onMounted, ref } from 'vue'
import { put } from '../../services/api'
const emit = defineEmits(['abort'])
const props = defineProps<{ deal?: Deal; project: string }>()
const loading = ref(false)
const updating = ref(false)
const lat = ref(null)
const lng = ref(null)
const isUpdated = ref(false)
const errors = ref([])

onMounted(() => {
	lat.value = props.deal.loc.coordinates[1]
	lng.value = props.deal.loc.coordinates[0]
})

const update = async () => {
	loading.value = true
	updating.value = true
	isUpdated.value = false
	errors.value = []
	try {
		await put({ name: 'deal.loc', params: { project: props.project, _id: props.deal._id } }, {
			lat: lat.value,
			lng: lng.value
		})
	} catch (error) {
		errors.value = error.errors || []
	}
	isUpdated.value = errors.value.length ? false : true
	updating.value = false
	loading.value = false
}
</script>
