<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 flex-1">Acciones de deals</h1>
						<!-- Add new -->
					</div>

					<!-- More actions -->
					<!-- <div></div> -->

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								Resultados:
								<span class="text-slate-400">{{ total }}</span>
							</header>
							<div class="gap-2 flex items-center">
								Mostrar:
								<select v-model="options.limit" class="ml-2 form-select flex-1 md:flex-auto" @change="onChangeLimit">
									<option :value="5">5</option>
									<option :value="10">10</option>
									<option :value="20">20</option>
									<option :value="50">50</option>
									<option :value="100">100</option>
									<option :value="150">150</option>
								</select>
							</div>
						</div>

						<!-- Table -->
						<div class="relative" v-if="!loading && statuses.length">
							<div class="absolute flex h-full top-0 max-w-[500px] min-w-[350px] bg-white z-10 p-3 shadow-md rounded transition-all ease-in-out duration-200" :class="{ '-right-[0%]': showConfig, '-right-[100%]': !showConfig }">
								<icon name="ri-close-circle-line" class="absolute z-10 top-[.8em] right-[.8em] hover:text-gray-200 text-gray-100 cursor-pointer" scale="1.2" @click.stop="closeConfig" />
								<VueJsonEditor v-model.text="selectedConfig" mode="text" class="h-full jse-theme-dark w-full rounded overflow-hidden" :readOnly="true" />
							</div>
							<!-- Pagination -->
							<div v-if="!loading && statuses.length">
								<Pagination v-show="total > options.limit" :results="statuses.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
							</div>
							<div>
								<table class="table-auto w-full">
									<!-- Table header -->
									<thead>
										<tr class="sticky top-0 text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200 -mt-[1px]">
											<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
												<div class="font-semibold text-left">
													<button class="flex items-center" @click="handleSort('type')">
														<p class="uppercase mr-1">Type</p>
														<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'type'" />
														<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
														<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
													</button>
												</div>
											</th>
											<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
												<div class="font-semibold text-left">
													<button class="flex items-center" @click="handleSort('label')">
														<p class="uppercase mr-1">Label</p>
														<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'label'" />
														<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
														<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
													</button>
												</div>
											</th>
											<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
												<div class="font-semibold text-left">Acciones</div>
											</th>
										</tr>
									</thead>
									<!-- Table body -->
									<tbody class="text-sm divide-y divide-gray-200">
										<tr v-for="item in statuses" :key="item._id" class="even:bg-gray-100 hover:bg-gray-200">
											<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
												<div v-if="item.type" class="font-medium text-light-blue-500">
													{{ item.type }}
												</div>
											</td>
											<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
												<div v-if="item.label" class="font-medium text-light-blue-500">
													{{ item.label }}
												</div>
											</td>

											<td class="px-2 first:pl-5 last:pr-5 py-1 flex flex-wrap gap-2">
												<Button @click.stop="() => openConfig(item.config)">Ver</Button>
												<Button color="warning" @click="() => goToStatusForm(item._id)">Editar</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!-- Pagination -->
						</div>
					</div>
					<div class="my-2" v-if="!loading && statuses.length">
						<Pagination v-show="total > options.limit" :results="statuses.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-if="!loading && !statuses.length">
						<div class="text-center py-6">
							<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
								<icon name="ri-information-line" scale="2" />
							</div>
							<h2 class="text-2xl text-gray-800 font-bold mb-2">Acciones de oportunidades.</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import VueJsonEditor from 'json-editor-vue'
import { Ref, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination.vue'
import { get } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()
const router = useRouter()

const options: Record<string, any> = reactive({
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0
})

const loading = ref(false)
const statuses = ref([])
const total = ref(0)
const error = ref(null)
const selectedConfig = ref(null)
const showConfig = ref(false)
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })

const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const searchStatuses = async () => {
	loading.value = true
	error.value = []
	try {
		await router.replace({ query: options })
		statuses.value = []
		const query = { ...route.query, projection: 'type,label,config' }
		const { data, pagination } = await get({ name: 'action', query })

		statuses.value = data
		total.value = pagination.total
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(() => {
	searchStatuses()
})

const onChangeLimit = () => {
	options.skip = 0
}

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
const openConfig = (config: Record<string, any>) => {
	selectedConfig.value = config
	showConfig.value = true
}

const closeConfig = () => {
	selectedConfig.value = null
	showConfig.value = false
}

const goToStatusForm = async (_id: string) => {
	await router.push({
		name: 'go.dealAdminStatusForm',
		query: {
			_id
		}
	})
}

watch(options, () => {
	searchStatuses()
})
</script>
