<template>
	<div>
		<h2 v-if="title" class="text-xl text-gray-800 font-bold mb-6">{{ title }}</h2>
		<div class="mb-1 border-b border-gray-200">
			<ul class="flex flex-nowrap items-center overflow-x-scroll no-scrollbar p-3 gap-2 md:gap-3">
				<li v-for="(tab, key) in tabList" :key="tab.name" class="flex justify-center items-center" @click="setActive(tab, key)">
					<Button :color="key === activeItem ? 'primary' : 'tertiary'">
						<icon v-if="tab.iconName" :name="tab.iconName" class="mr-1" />
						<span>{{ tab.title }}</span>
					</Button>
				</li>
			</ul>
		</div>
		<div class="relative p-3">
			<slot />
		</div>
	</div>
</template>
<script setup lang="ts">
import { provide, ref, useSlots } from 'vue'
import Button from '../Button.vue'

const props = defineProps({
	title: { type: String, default: '' },
	buttonClasses: { type: String, default: '' },
	activeClasses: { type: String, default: 'border border-orange-400 bg-orange-400 text-white shadow-md shadow-gray-300 hover:bg-orange-500 hover:border-orange-500' },
	defaultClasses: { type: String, default: 'border border-orange-500 text-black hover:bg-orange-500 shadow-md hover:shadow-gray-300 hover:text-white ' }
})

const slots = useSlots()
const tabList = ref(
	slots
		.default()
		.filter(tab => tab.props)
		.map(tab => ({ name: tab.props.name, title: tab.props.title, iconName: tab.props.iconName || '' }))
)
const selectedTab = ref(tabList.value[0])
const activeItem = ref(0)

const setActive = (tab, key) => {
	activeItem.value = key
	selectedTab.value = tab
}

provide('activeTab', selectedTab)
</script>
