<template>
	<div class="w-full h-full z-0" ref="mapContainer"></div>
</template>

<script setup lang="ts">
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
	zoom: {
		type: Number,
		default: 18,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	minZoom: {
		type: Number,
		default: 5,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	maxZoom: {
		type: Number,
		default: 18,
		validator(value) {
			return Number.isInteger(value)
		}
	},
	center: {
		type: Object,
		default: null
	},
	markers: {
		type: Array,
		default: [],
		validator(value) {
			return Array.isArray(value)
		}
	},
	userMarker: {
		type: Object,
		default: null
	},
	circle: {
		type: Object,
		default: null
	},
	dragging: {
		type: Boolean,
		default: false
	},
	tap: {
		type: Boolean,
		default: false
	},
	zoomControl: {
		type: Boolean,
		default: false
	},
	keyboard: {
		type: Boolean,
		default: false
	},
	boxZoom: {
		type: Boolean,
		default: false
	},
	doubleClickZoom: {
		type: Boolean,
		default: false
	},
	scrollWheelZoom: {
		type: Boolean,
		default: true
	},
	touchZoom: {
		type: Boolean,
		default: false
	}
})

const mapContainer = ref(null)
const map = ref(null)
const emit = defineEmits(['click', 'zoom', 'ready'])
onMounted(() => {
	map.value = L.map(mapContainer.value, {
		minZoom: props.minZoom,
		maxZoom: props.maxZoom,
		zoom: props.zoom,
		dragging: props.dragging,
		tap: props.tap,
		zoomControl: props.zoomControl,
		keyboard: props.keyboard,
		boxZoom: props.boxZoom,
		doubleClickZoom: props.doubleClickZoom,
		scrollWheelZoom: props.scrollWheelZoom,
		touchZoom: props.touchZoom,
		center: props.center
	})

	map.value.on('click', function (e) {
		emit('click', { lat: e.latlng.lat, lng: e.latlng.lng })
	})

	L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
		attribution: ''
	}).addTo(map.value)

	map.value.on('zoomend', function (e) {
		emit('zoom', map.value.getZoom())
	})
	map.value.whenReady(() => {
		props.markers.forEach((marker: Record<string, any>) => {
			marker.addTo(map.value)
			// marker.bindPopup('Popup content')
			marker.on('mouseover', function (e) {
				this.openPopup()
			})
			marker.on('mouseout', function (e) {
				this.closePopup()
			})
		})
		emit('ready')
	})
})

onUnmounted(() => {
	props.markers.forEach((marker: Record<string, any>) => {
		map.value?.removeLayer(marker)
	})
	map.value.remove()
	map.value = null
})

watch(
	() => props.markers,
	(markers, old) => {
		old.forEach(marker => {
			map.value?.removeLayer(marker)
		})
		markers.forEach((marker: Record<string, any>) => {
			marker.addTo(map.value)
		})
	}
)

watch(
	() => props.userMarker,
	marker => {
		if (!marker._map) {
			marker.addTo(map.value)
		}
	}
)

watch(
	() => props.circle,
	item => {
		if (!item._map) {
			item.addTo(map.value)
		}
	}
)

watch(
	() => props.center,
	center => {
		if (center) {
			map.value?.setView(center, map.value.getZoom())
		}
	}
)
</script>
