<template>
	<div class="flex h-screen overflow-hidden" v-if="user">
		<Loading v-show="loading" />
		<Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />
		<div class="relative flex flex-col flex-1 overflow-y-scroll overflow-x-hidden bg-gray-100">
			<Header :sidebarOpen="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />
			<main class="flex flex-1">
				<div class="w-full h-full relative">
					<slot />
				</div>
			</main>
		</div>
	</div>
</template>

<script setup lang="ts">
import { user } from '@/user'
import { ref } from 'vue'
import Loading from '../components/Loading.vue'
import Header from './../components/Header.vue'
import Sidebar from './../components/Sidebar.vue'
const sidebarOpen = ref(false)
defineProps<{ loading?: boolean }>()
</script>
