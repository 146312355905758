<template>
	<article class="flex flex-col bg-white border border-gray-200 rounded-sm overflow-hidden" :class="{ ' border-lime-600': props.item.recommended, 'border-gray-800': !props.item.recommended }">
		<header class="p-2 py-1 text-white flex justify-between items-center text-sm" :class="{ 'bg-lime-600 ': props.item.recommended, 'bg-gray-800': !props.item.recommended }">
			<p class="font-bold">
				<span> {{ props.item.vendorName }} </span>
				<span v-if="props.item.differenceFromLastInPercentage">
					<small> - Ahorro<sup>*</sup> {{ props.item.differenceFromLastInPercentage }}% <icon class="ml-1" name="hi-chevron-double-up" animation="float" speed="slow" /></small
				></span>
			</p>
			<button class="btn bg-white border-gray-200 hover:border-gray-300 text-gray-600 btn-sm">DETALLE</button>
		</header>
		<div class="py-1 px-2">
			<div class="flex justify-between items-center">
				<img class="h-[70px] object-contain" :src="props.item.vendorLogo" :alt="props.item.vendorName" />
				<div class="text-right pr-2">
					<small class="font-semibold">Total de factura</small>
					<p class="text-4xl font-bold leading-[1em]">{{ parseCurrency(props.item.vendorElectricalConsumption) }}</p>
				</div>
			</div>
			<div class="text-[.7em] font-semibold text-right px-2 py-1 flex flex-col gap-2 md:flex-row md:items-center md:gap-4 justify-end">
				<div>
					<span class="uppercase">Tu precio</span>

					<!-- <span class="uppercase" :class="{ 'text-lime-600 ': props.item.recommended, 'text-blue-700': !props.item.recommended }">{{ priceTypes[props.item.priceType] }}</span> -->

					<Badge class="ml-1" size="xs" :color="props.item.recommended ? 'success' : 'primary'">{{ priceTypes[props.item.priceType] }}</Badge>
				</div>
				<div>
					<span class="uppercase">Modalidad</span>

					<!-- <span :class="{ 'text-lime-600 ': props.item.recommended, 'text-blue-700': !props.item.recommended }">{{ props.item.offerType }}</span> -->

					<Badge class="ml-1" size="xs" :color="props.item.recommended ? 'success' : 'warning'">{{ props.item.offerType }}</Badge>
				</div>
			</div>
			<small v-if="props.item.differenceFromLastInPercentage" class="col-span-full text-[8px]">* Porcentaje de ahorro comparado con la opción más económica de la comparativa actual ({{ parseCurrency(props.item.differenceComparedTo) }})</small>
		</div>
		<footer v-show="false" class="p-2">
			<div class="flex flex-wrap props.items-center gap-2" :class="{ 'justify-end sm:justify-between': props.item.recommended, 'justify-end': !props.item.recommended }">
				<Badge v-if="props.item.recommended" :color="'success'" class="hidden sm:block uppercase bg-white border border-lime-600 text-xs rounded-md">Opción recomendada</Badge>
				<Button class="uppercase rounded-full text-xs" size="xs" :class="{ 'bg-pink-500': props.item.recommended, 'bg-gray-800': !props.item.recommended }" disabled>Contratar</Button>
			</div>
		</footer>
	</article>
</template>

<script setup lang="ts">
import { parseCurrency } from '../../utils/parseCurrency'
import Badge from '@/apps/go/components/Badge.vue'
import Button from '@/apps/go/components/Button.vue'
const priceTypes = ['Fijo', 'Plano', 'Indexado']
const props = defineProps<{ item: any }>()
</script>
