import Validator, { AsyncCheckFunction, SyncCheckFunction, ValidationSchema } from 'fastest-validator'

import * as aliases from './validatorAliases'

const validatorMessages = {
	required: 'El campo es requerido.',
	stringEmpty: 'El campo no puede estar vacío',
	stringLength: 'El campo debe tener {expected} caracteres',
	enumValue: 'El dato ingresado no es válido.'
}

/**
 * Custom class for validate data extending from fastest validator
 * REPO: https://github.com/icebob/fastest-validator#readme
 *
 */
export class CustomValidator extends Validator {
	private _cache: Record<string, SyncCheckFunction | AsyncCheckFunction> = {}
	constructor() {
		super({
			useNewCustomCheckerFunction: true, // using new version,
			messages: validatorMessages
		})
	}

	compile(schema: ValidationSchema | ValidationSchema[]) {
		const key = JSON.stringify(schema)
		if (!this._cache[key]) {
			this._cache[key] = super.compile(Object.assign({}, schema))
		}
		return this._cache[key]
	}
	async validate(value: any, schema: ValidationSchema) {
		const check = this.compile({ $$async: true, $$strict: true, ...schema })
		const errors = await check(value)
		return Array.isArray(errors) ? errors : []
	}
}

const validator = new CustomValidator()

/**
 * If you have aliases to validate data, you can import and inject your aliases.
 * @example import * as aliases from './yourAliasesFolder'
 *
 */
Object.entries(aliases).forEach(([alias, rules]) => validator.alias(alias, rules))

export { validator }
