<template>
	<div class="bg-white rounded-sm border border-gray-200 mb-4">
		<div class="flex justify-between items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<div class="flex items-center w-full">
				<icon name="ri-tools-line" class="text-gray-400 mr-1" />
				<div>Comparativa</div>
			</div>
		</div>
		<div class="bg-white w-full p-2" v-if="!items.length && !loading">
			<Banner type="warning" :open="true" fixed> <span>No se han encontrado opciones.</span></Banner>
		</div>
		<div class="bg-white w-full">
			<div v-if="loading" class="flex justify-center items-center col-span-full p-4">
				<icon name="ri-loader-4-line" class="mr-1" animation="spin" scale="1.5" />
			</div>
			<Tabs v-if="!loading">
				<Tab name="fixed" title="FIJO">
					<div v-if="items.filter(item => item.priceType === 0)?.length" class="grid xl:grid-cols-2 gap-1">
						<ComparativeCard v-for="item of items.filter(item => item.priceType === 0)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)" />
					</div>
					<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para FIJO.</span></Banner>
				</Tab>
				<Tab name="indexed" title="INDEXADO">
					<div v-if="items.filter(item => item.priceType === 2)?.length" class="grid xl:grid-cols-2 gap-1">
						<ComparativeCard v-for="item of items.filter(item => item.priceType === 2)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)"> </ComparativeCard>
					</div>
					<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para INDEXADO.</span></Banner>
				</Tab>
				<Tab name="flat" title="PLANO">
					<div v-if="items.filter(item => item.priceType === 1)?.length" class="grid xl:grid-cols-2 gap-1">
						<ComparativeCard v-for="item of items.filter(item => item.priceType === 1)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)" />
					</div>
					<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para PLANO.</span></Banner>
				</Tab>
			</Tabs>
		</div>
		<ComparativeInvoice v-if="current" :cups="contract.cups" :rate="parseRate(contract.rate)" :data="current" :power="power" :invoicePeriod="invoicePeriod" @close="current = null" />
	</div>
</template>

<script setup lang="ts">
import { Contract } from '@/apps/go/interfaces/Contract'
import { parseRate } from '@/apps/go/utils/parseRate'
import { onMounted, ref, watch, computed } from 'vue'
import Banner from '@/apps/go/components/Banner.vue'
import Tab from '@/apps/go/components/tabs/Tab.vue'
import Tabs from '@/apps/go/components/tabs/Tabs.vue'
import ComparativeCard from './ComparativeCard.vue'
import ComparativeInvoice from './ComparativeInvoice.vue'
import { compare } from '../../services/ahorreluz'

const loading = ref(true)
const invoicePeriod = ref(null)
const current = ref(null)
const items = ref([])

const props = defineProps<{
	contract: Contract
}>()

const onClick = item => {
	current.value = item
}

const fetch = async () => {
	if (props.contract?.cups) {
		loading.value = true
		try {
			const data = await compare({
				comparison_type: 'aprox',
				contract_type: '2',
				product_type: '1',
				cups: props.contract.cups,
				salesOrigin: 'appElectryGo',
				comercializadorasComparar: 'ALL'
			})

			if (data?.ofertas?.length) {
				items.value = data.ofertas
				invoicePeriod.value = data.periodoFacturacion
			}
		} catch (error) {
			console.log(error)
		}
		loading.value = false
	}
}

onMounted(() => {
	fetch()
})

watch(
	() => props.contract,
	() => {
		fetch()
	}
)

const power = computed(() => {
	const result = {}
	if (props.contract) {
		for (const key in props.contract) {
			if (key.includes('powerP')) {
				result[key] = props.contract[key]
			}
		}
	}
	return result
})
</script>
