import { ValidationRuleObject } from 'fastest-validator'
import { dayjs } from '../dayjs'

export const dateString: ValidationRuleObject = {
	type: 'custom',
	check(
		value: any,
		errors: any[],
		checker: any,
		path: string,
		data: Record<string, any>
	) {
		if (!dayjs(value).isValid()) {
			errors.push({
				message: `Cadena de texto inválida para fecha`,
				type: 'invalidDateString',
				actual: value
			})
			return value
		}
		if (checker.futureDate && dayjs().isAfter(dayjs(value))) {
			errors.push({
				message: `La fecha introducida debe ser mayor a la fecha actual`,
				type: 'invalidDateString',
				actual: value
			})
			return value
		}
		return value
	}
}
