<template>
	<div class="px-3 py-2 rounded-sm border border-gray-200">
		<button class="flex items-center justify-between w-full group" @click.prevent="onChangeOpenInfo" :aria-expanded="open">
			<p class="text-gray-800 font-medium font-semibold">
				{{ title }}
			</p>
			<icon name="ri-arrow-down-s-line" class="transition-all duration-200 ease-in-out ml-1" :class="{ '-rotate-180': open }" />
		</button>
		<div class="text-sm" v-show="open">
			<slot />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
const props = defineProps<{ title: string; open?: boolean }>()
const open = ref(!!props.open)

const emit = defineEmits(['onToggleOpen'])

const onChangeOpenInfo = () => {
	open.value = !open.value
	emit('onToggleOpen', open.value)
}

watch(
	() => props.open,
	() => {
		open.value = props.open
	}
)
</script>
