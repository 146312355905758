<template>
	<form v-if="data" @input="$emit('change', data)" @submit.prevent>
		<div class="bg-white rounded-sm border border-gray-200 mb-3">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-map-pin-line" class="text-gray-400 mr-1" />
				<div>Ubicación</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="grid gap-5 grid-cols-1 md:grid-cols-2">
						<div><label class="block text-sm font-medium mb-1">Calle</label><input class="form-input w-full" type="text" v-model="data.address.full" /></div>
						<div><label class="block text-sm font-medium mb-1">Provincia</label><input class="form-input w-full" type="text" v-model="data.address.state" /></div>
						<div><label class="block text-sm font-medium mb-1">Municipio</label><input class="form-input w-full" type="text" v-model="data.address.city" /></div>
						<div><label class="block text-sm font-medium mb-1">Código Postal</label><input class="form-input w-full" type="text" v-model="data.address.zipcode" /></div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!isFinance" class="bg-white rounded-sm border border-gray-200 mb-3">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="hi-lightning-bolt" class="text-gray-400 mr-1" />
				<div>Potencia contratada - kW</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="grid gap-5 xs:grid-cols-2">
						<div v-for="i in Array.from({ length: data.rate === 2 ? 2 : 6 }, (v, i) => i + 1)" :key="i">
							<label class="block text-sm font-medium mb-1">P{{ i }}</label
							><input class="form-input w-full" type="text" v-model="data[`powerP${i}`]" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!isFinance" class="bg-white rounded-sm border border-gray-200 mb-3">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-bar-chart-2-line" class="text-gray-400 mr-1" />
				<div>Consumo de energía - kWh Anual</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="grid gap-5 grid-cols-2">
						<div v-for="i in Array.from({ length: data.rate === 2 ? 3 : 6 }, (v, i) => i + 1)" :key="i">
							<label class="block text-sm font-medium mb-1">P{{ i }}</label
							><input class="form-input w-full" type="text" v-model="data[`kWhP${i}`]" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!isFinance" class="bg-white rounded-sm border border-gray-200 mb-3">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-tools-line" class="text-gray-400 mr-1" />
				<div>Propiedad equipo medida</div>
			</div>
			<div class="text-sm text-gray-800 p-5">
				<div>
					<div class="flex gap-3">
						<label class="flex items-center">
							<input type="radio" name="Propiedad_Equipo_Medida" v-model="data.measureEquipmentProperty" class="form-radio" value="supplier" />
							<span class="text-sm ml-1">Distribuidora</span>
						</label>
						<label class="flex items-center">
							<input type="radio" name="Propiedad_Equipo_Medida" v-model="data.measureEquipmentProperty" class="form-radio" value="customer" />
							<span class="text-sm ml-1">Cliente</span>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white rounded-sm border border-gray-200">
			<!-- Body -->
			<div class="flex flex-column items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-message-2-line" class="text-gray-400 mr-1" />
				<div>Comentarios ({{ data.comments.length }})</div>
			</div>

			<div class="flex flex-col items-center text-gray-800 border-b border-gray-200 max-h-[300px] overflow-hidden">
				<div class="v-h-full overflow-y-auto w-full">
					<template v-if="data.comments.length">
						<div v-for="(comment, i) in data.comments" :key="i" class="p-5 pt-2 w-full odd:bg-gray-100">
							<div class="text-xs text-gray-500 font-medium" :title="dayjs(comment.createdAt).format('DD/MM/YYYY HH:mm:ss')">{{ dayjs(comment.createdAt).fromNow() }}</div>
							<div class="flex items-center justify-between mb-1.5">
								<div class="truncate">
									<!-- | TODO add user data if logged in user has permissions-->
									<!-- <span class="text-sm font-semibold text-gray-800">{{comment.user.fullName}}</span> -->
								</div>
							</div>
							<div class="text-xs font-medium text-gray-800 truncate mb-0.5">Comentario:</div>
							<div class="text-xs whitespace-normal">{{ comment.content }}</div>
						</div>
					</template>
					<div class="text-sm p-5" v-else>
						<p>Aún no se han registrado comentarios.</p>
					</div>
				</div>
			</div>
			<div class="p-3">
				<div class="mb-2">
					<textarea v-model="comment" rows="4" class="form-input w-full" :class="{ 'border-red-600': !!getErrorMessage('content') }"></textarea>
					<p class="text-red-600">
						<small>{{ getErrorMessage('content') }}</small>
					</p>
				</div>
				<Button @click="$emit('addComment', { content: comment })" loadingLabel="...guardando" :loading="updatingComment" :disabled="updatingComment" color="secondary">
					<icon name="ri-save-2-line" class="mr-1" />
					Guardar comentario
				</Button>
			</div>
		</div>
	</form>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { dayjs } from '@/services/dayjs'
import { Ref, ref, toRef, watch } from 'vue'
import { Contract } from '../../interfaces/Contract'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Button from '../Button.vue'
const isFinance = import.meta.env.VITE_PRODUCT == 'finance'
const emit = defineEmits(['change', 'addComment'])
const props = defineProps<{ data: Contract; updatingComment: boolean; commentSaved: boolean; error: Ref<ValidationError> }>()
const getErrorMessage = useValidationErrorsMessages(toRef(props, 'error'))
const comment = ref('')

watch(
	() => props.commentSaved,
	() => {
		comment.value = ''
	}
)
</script>
