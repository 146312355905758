<template>
	<div v-if="props.deal">
		<div class="bg-white rounded-sm border border-gray-200 mt-2 mb-2">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-bookmark-line" class="text-gray-400 mr-1" />
				<div>Cambiar Estado</div>
			</div>
			<div class="text-sm text-gray-800 p-3 grid gap-2">
				<template v-for="action in actionsUpdateStatus" :key="action._id">
					<Button color="warning" @click="$emit('action', action)" :disabled="action.data.type == 'newVisitReported' && props.disableNewVisitReported ? true : false">
						<span class="ml-2">{{ action.actionLabel || action.label }}</span>
					</Button>
				</template>

				<Banner v-if="props.deal.finishedAt || (!actionsUpdateStatus.length && !props.deal.finishedAt)" :open="true" :type="`${!props.deal.finishedAt && !actionsUpdateStatus.length ? 'error' : 'success'}`" :fixed="true">
					<span v-if="props.deal.finishedAt">Ha finalizado la gestión de la oportunidad.</span>
					<span v-if="!props.deal.finishedAt && !actionsUpdateStatus.length">No se han encontrado acciones de estado para esta oportunidad.</span>
				</Banner>
			</div>
		</div>
		<div v-if="actions.length" class="bg-white rounded-sm border border-gray-200 mt-2 mb-2">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
				<icon name="ri-bookmark-line" class="text-gray-400 mr-1" />
				<div>Acciones administrativas</div>
			</div>
			<div class="text-sm text-gray-800 p-3 grid gap-2">
				<Button v-for="action in actions" :key="action._id" color="warning" @click="$emit('action', action)">
					<span class="ml-2">{{ action.label }}</span>
				</Button>
				<Banner v-if="props.deal.finishedAt || (!actions.length && !props.deal.finishedAt)" :open="true" :type="`${!props.deal.finishedAt && !actions.length ? 'error' : 'success'}`" :fixed="true">
					<span v-if="props.deal.finishedAt">Ha finalizado la gestión de la oportunidad.</span>
					<span v-if="!props.deal.finishedAt && !actions.length">No se han encontrado acciones administrativas para esta oportunidad.</span>
				</Banner>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { user } from '@/user'
import { computed } from 'vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import { Deal } from '../../interfaces/Deal'
const props = defineProps<{ deal: Deal; disableNewVisitReported: boolean }>()
const emit = defineEmits(['action'])
const actionsUpdateStatus = computed(() => (props.deal ? props.deal.actions.filter(action => action.type == 'updateStatus') : []))
const actions = computed(() => (props.deal ? props.deal.actions.filter(action => action.type != 'updateStatus') : []))
</script>
