<template>
	<Default :loading="loading">
		<div class="relative flex h-full w-full">
			<div class="bg-white grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0">
				<div class="m-3 mb-2 md:mb-0">
					<h1 class="text-2xl md:text-3xl text-gray-800 font-bold">Consultar CUPS</h1>
				</div>
				<div class="m-3 col-span-full xl:col-span-4 shadow-md rounded-sm border border-gray-200">
					<div class="p-3">
						<div class="flex gap-2 justify-start">
							<input v-model="cups" class="form-input focus:border-gray-300 w-full" type="text" placeholder="Introduce el CUPS" name="cups" id="cups" />
							<Button :loading="loading" @click="fetch()" :disabled="loading || !cups || !user.permissions?.includes('cups')">Buscar</Button>
						</div>
					</div>
				</div>
				<div class="grow p-3 bg-gray-100">
					<ContractSummaryEnergy v-if="contract && !!Object.keys(contract)?.length" :contract="contract" />
					<Banner type="error" :fixed="true" v-if="error" :open="!!error.errors?.length">
						<p v-if="error.errors" :key="i" v-for="(err, i) of error.errors">{{ err.message }}</p>
						<p>{{ error.message }}</p>
					</Banner>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { user } from '@/user'
import { ref } from 'vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import ContractSummaryEnergy from '../../components/leads/ContractSummaryEnergy.vue'

import { get } from '../../services/api'
import Default from '../Default.vue'

const loading = ref(false)
const contract = ref(null)
const cups = ref(null)
const error = ref(null)

const fetch = async () => {
	loading.value = true
	error.value = null
	contract.value = null
	try {
		const { data } = await get({ name: 'contract', params: { cups: cups.value }});
		if (Object.keys(data).length) {
			contract.value = { ...data, cups: cups.value }
		} else {
			error.value.message`No se ha encontrado información para el CUPS ${cups.value}`
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
</script>
