<template>
	<header class="sticky top-0 bg-white border-b border-gray-200 z-30">
		<div class="sm:px-3">
			<div class="flex items-center justify-between h-14 xs:mr-2">
				<!-- Header: Left side -->
				<div class="flex">
					<!-- Hamburger button -->
					<button class="p-4 text-gray-500 hover:text-gray-600 lg:hidden" @click.stop="$emit('toggle-sidebar')" aria-controls="sidebar" :aria-expanded="sidebarOpen">
						<span class="sr-only">Open sidebar</span>
						<icon name="ri-menu-line" class="ml-1" />
					</button>
				</div>

				<!-- Header: Right side -->
				<div class="flex items-center space-x-3">
					<UserMenu align="right" />
				</div>
			</div>
		</div>
	</header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import UserMenu from './DropdownProfile.vue'

const searchModalOpen = ref(false)
defineProps(['sidebarOpen'])
</script>
