<template>
	<div>
		<h2 class="text-gray-800 font-bold mb-2">{{ title }}</h2>
		<div v-if="support" class="text-xs my-1 text-orange-600">{{ support }}</div>
		<FileDropZone @files-dropped="addFiles" #default="{ dropZoneActive }">
			<label for="file-input" class="flex justify-center w-full min-h-fit xs:h-32 px-4 py-2 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-600 active:border-gray-600 focus:outline-none hover:shadow-2xl active:shadow-2xl" :class="[`${dropZoneActive && 'bg-gray-200 shadow-2xl'}`]">
				<div class="flex items-center space-x-2">
					<div>
						<p>
							<span v-if="dropZoneActive">
								<span>Suelta aquí los archivos</span>
								<span class="smaller">para añadirlos</span>
							</span>
							<span v-else>
								<span>Arrastra aquí los archivos</span>
								<span class="smaller">
									o <strong><em>haz click</em></strong> para seleccionar del dispositivo.
								</span>
							</span>
						</p>
						<template v-if="files?.length">
							<div class="flex justify-center gap-2 text-xs">
								<p class="inline-flex font-medium bg-blue-100 hover:bg-blue-200 text-blue-600 text-center rounded-xl p-2">Archivos: {{ files.length }}</p>
								<p class="inline-flex font-medium bg-blue-100 hover:bg-blue-200 text-blue-600 text-center rounded-xl p-2">Tamaño: {{ getTotalSize() }} Mb (máximo 4 Mb)</p>
							</div>
						</template>
					</div>
				</div>

				<input class="hidden" type="file" id="file-input" :multiple="multiple" @change="onInputChange" :accept="accept.join(',')" />
			</label>
		</FileDropZone>
		<div v-if="files?.length" class="mt-2">
			<ul>
				<li v-for="(file, i) in files" class="p-1 relative even:bg-gray-100 hover:bg-gray-200 flex items-center justify-between" :key="i">
					<Badge size="xs">{{ file.id }}</Badge>
					<icon name="ri-close-circle-fill" class="block cursor-pointer ml-1" @click="removeFile(file)" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup lang="ts">
import Badge from '@/apps/go/components/Badge.vue'
import { ref } from 'vue'
import FileDropZone from './FileDropZone.vue'
import useFileList from './useFileList'

const props = withDefaults(
	defineProps<{
		title?: string
		accept?: string[]
		support?: string
		modelValue: any
		required?: boolean
		multiple?: boolean
		errors?: Record<string, any>
	}>(),
	{
		title: '',
		multiple: false,
		accept: () => ['image/jpg', 'image/jpeg', 'image/png', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'text/csv'],
		required: false
	}
)

const emit = defineEmits(['update:modelValue'])

const files = ref(props.modelValue)

const { addFiles, removeFile, files: storedFiles, updateFile, onResult } = useFileList(props.accept)

const onInputChange = e => {
	if (!props.multiple && storedFiles.value.length) {
		storedFiles.value = []
	}
	addFiles(e.target.files)
	e.target.value = null
}

const toBase64 = (file: File) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve((<string>reader.result).split(',')[1])
		reader.onerror = error => reject(error)
	})
}

const updateModelValue = async () => {
	const output = []

	for (const { file } of files.value) {
		file.body = await toBase64(file)
	}

	emit('update:modelValue', files.value)
}

onResult(async data => {
	files.value = data
	await updateModelValue()
})

const getTotalSize = () => {
	const total = files.value.reduce((acc, file) => {
		return acc + file.file.size || 0
	}, 0)
	return Math.round((total / 1024 ** 2) * 100) / 100
}
</script>
