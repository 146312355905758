<template>
	<Default :loading="loading">
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Grupos de Usuarios</h1>
						<!-- More actions -->
						<div class="flex flex-col md:flex-row gap-y-2 md:gap-x-2" v-if="user.permissions?.includes('user.group.create')">
							<Button @click="router.push({ name: 'go.newUserGroup' })">
								<icon name="ri-add-circle-line" class="mr-1" />
								Añadir grupo
							</Button>
						</div>
					</div>

					<div class="md:flex md:items-center justify-end mt-3">
						<div v-if="user.projects?.length > 1" class="flex flex-col md:flex-row gap-y-2 md:gap-x-2 md:items-center">
							Proyectos:
							<Multiselect name="projects" id="projects" v-model="selectedProjects" track-by="name" label="label" placeholder="Seleccionar proyectos" :options="projects" :showNoResults="true" :clearOnSelect="false" :closeOnSelect="false" :preserveSearch="true" :hideSelected="true" :multiple="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover" @select="onSelectProject" @remove="onSelectProject">
								<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
								<template #noOptions>No se han encontrado proyectos. </template>
							</Multiselect>
						</div>
					</div>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-4">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								Resultados:
								<span class="text-slate-400">{{ total }}</span>
							</header>
							<div class="gap-2 flex items-center">
								<div>
									Mostrar:
									<select v-model="options.limit" class="ml-2 form-select flex-1 md:flex-auto" @change="onchangeLimit">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="150">150</option>
									</select>
								</div>
							</div>
						</div>

						<!-- Table -->
						<div class="overflow-x-auto" v-if="!loading && items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('label')">
													<p class="uppercase mr-1">Identificador</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'label'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-center">
												<button class="flex items-center" @click="handleSort('project')">
													<p class="uppercase mr-1">Proyecto</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'project'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-center">Nº Usuarios</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-semibold text-left">Acciones</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr class="even:bg-gray-100 hover:bg-gray-200" v-for="item in items" :key="item._id">
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">{{ item.label }}</td>
										<td class="px-2 whitespace-nowrap" align="center">
											<div class="inline-flex font-medium rounded-full text-center">
												<Badge randomColor :cachedRandomColorKey="item.project">{{ item.project }}</Badge>
											</div>
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap text-center">
											{{ item.usersCount || 0 }}
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-1 flex flex-wrap gap-2">
											<Button
												@click="
													router.push({
														name: 'go.userGroupUpdate',
														params: { _id: item._id }
													})
												"
												color="warning"
												v-if="user.permissions?.includes('user.group.update')"
											>
												<icon name="ri-pencil-line" />
											</Button>

											<Tooltip bg="dark" size="sm" position="left">
												<template v-slot:button>
													<Button :loading="loading" v-if="user.permissions?.includes('user.group.delete')" @click="remove(item)" color="danger" :disabled="loading || !!item.usersCount">
														<icon name="ri-delete-bin-7-line" />
													</Button>
												</template>
												<template v-slot:tooltip v-if="!!item.usersCount">
													<div class="text-sm text-gray-200">No es posible eliminar grupos que contengan usuarios</div>
												</template>
											</Tooltip>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- Pagination -->
					<div class="mt-8 mb-24" v-if="!loading && items.length">
						<Pagination v-show="total > options.limit" :results="items.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-if="(!loading && !items.length) || !!error.message">
						<Banner type="error" :open="!!error?.message" fixed>
							<p>{{ error.message }}</p>
							<ul v-if="error?.errors">
								<li v-for="(err, i) in error?.errors" :key="i">
									<p class="p-3">- {{ err.message }}</p>
								</li>
							</ul>
						</Banner>

						<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
							<div class="text-center py-6">
								<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
									<icon name="ri-information-line" scale="2" />
								</div>
								<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado grupos de usuarios.</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { user } from '@/user'
import { Ref, onMounted, reactive, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute, useRouter } from 'vue-router'
import Badge from '../../components/Badge.vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination.vue'
import Tooltip from '../../components/Tooltip.vue'
import { del, get } from '../../services/api'
import Default from '../Default.vue'

const route = useRoute()

const options: Record<string, any> = reactive({
	...route.query,
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || 'label',
	...(route.query.project && { project: route.query.project })
})

const loading = ref(false)
const items = ref([])
const router = useRouter()
const total = ref(0)
const error = ref<ValidationError>(<any>{})
const selectedProjects = ref(null)
const projects = ref([])
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })

const onSelectProject = () => {
	const projectList = Array.isArray(selectedProjects.value) && selectedProjects.value.length ? selectedProjects.value.map((project: Record<string, any>) => project.name) : selectedProjects.value.name ? [selectedProjects.value.name] : []
	options.project = projectList.length ? projectList.join(',') : undefined
}

const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const search = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		await router.replace({
			query: options
		})
		items.value = []
		const apiQuery = { ...route.query, projection: 'label,users,project' }
		const { data, pagination } = await get({ name: 'group', query: apiQuery })
		total.value = pagination.total
		items.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { projection: 'name,label' } })
		projects.value = data.filter(project => user.value?.projects?.includes(project.name))
		selectedProjects.value = [...((<string>route.query.project)?.split(',').length ? projects.value.filter(u => route.query.project?.includes(u.name)) : [])]
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const remove = async project => {
	if (window.confirm(`¿Estás seguro que deseas eliminar el grupo?`)) {
		loading.value = true
		try {
			await del({ name: 'group', params: { _id: project._id } })
			search()
		} catch (error) {
			alert('Error al eliminar el grupo')
		}
		loading.value = false
	}
}
const onchangeLimit = () => {
	options.skip = 0
}

watch(options, () => {
	search()
})

onMounted(() => {
	search()
	fetchProjects()
})

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
</script>
