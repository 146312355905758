<template>
    <div>
        <button @click="throwError()">Click to throw error</button>  
    </div>
</template>

<script setup lang="ts">
    function throwError() {
        throw new Error('Test error to check if sentry is well configured');
    }
</script>