export class BadRequestError extends Error {
	status: number

	constructor(message: string, public errors: { field: string; message: string; type: string }[] = []) {
		super(message)
		this.name = this.constructor.name
		this.status = 400
		this.errors = errors
		Object.setPrototypeOf(this, new.target.prototype)
	}
}
