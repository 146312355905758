<template>
	<div class="bg-white rounded-sm border border-gray-200 mt-2">
		<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
			<icon name="ri-information-line" class="text-gray-400 mr-1" />
			<p>Información</p>
		</div>
		<div>
			<div class="flex flex-col md:flex-row gap-2">
				<ul class="flex-1 columns-1 md:columns-2 gap-2 p-3">
					<li v-if="props.agent" class="flex-1">
						<sub class="text-xs">Gestor</sub>
						<div class="flex items-center">
							<icon name="ri-user-line" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ props.agent.name }} </span>
						</div>
					</li>
					<li v-if="props.agent" class="flex-1">
						<sub class="text-xs">Email</sub>
						<div class="flex items-center">
							<icon name="ri-mail-line" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ props.agent.email }} </span>
						</div>
					</li>
					<li class="flex-1">
						<sub class="text-xs">Estado</sub>
						<div class="flex items-center">
							<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ deal.statusLabel }} </span>
						</div>
					</li>
					<li v-if="deal.contract.customer">
						<sub class="text-xs">Cliente</sub>
						<div class="flex items-center">
							<icon name="ri-user-line" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ `${deal.contract.customer.name || '-'} ${deal.contract.customer.lastname || ''}` }} </span>
						</div>
					</li>
					<li v-if="props.distance">
						<sub class="text-xs">Distancia</sub>
						<div class="flex items-center">
							<icon name="ri-pin-distance-line" class="text-blue-400 mr-1" />
							<span class="mr-1 font-bold"> {{ props.distance.toFixed(1) }}m </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">Cups</sub>
						<div class="flex items-center">
							<span class="mr-1 inline-flex items-center text-sm font-medium text-gray-100 bg-gray-700 rounded-full text-center px-2 py-0.5 text-md bg-indigo-100 text-indigo-600 hover:bg-indigo-200 ml-1"> {{ props.deal.contract.cups }} </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">Tarifa</sub>
						<div class="flex items-center">
							<span class="mr-1 font-bold"> {{ parseRate(props.deal.contract.rate) }} </span>
						</div>
					</li>
					<li>
						<sub class="text-xs">kWh</sub>
						<div class="flex items-center">
							<span class="mr-1 font-bold"> {{ props.deal.contract.annualkWh || '-' }} </span>
						</div>
					</li>
				</ul>
				<div class="flex justify-center md:justify-end items-start border-t-2 border-gray-200">
					<div class="pt-4 pb-3 px-3">
						<DealExpireCountdown :deal="props.deal" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Deal } from '@/apps/go/interfaces/Deal'
import { parseCurrency } from '@/apps/go/utils/parseCurrency'
import { parseRate } from '@/apps/go/utils/parseRate'
import { dayjs } from '@/services/dayjs'
import { User } from '@sentry/vue'
import DealExpireCountdown from '@/apps/go/components/leads/DealExpireCountdown.vue'

const props = defineProps<{ deal: Deal; agent: User; distance?: number }>()
const emit = defineEmits(['onToggleOpen'])
</script>
