import { auth } from '@/auth'
import { ValidationError as ValidationError } from '@/errors/ValidationError'
import { InternalServerError } from '@/errors/internalServerError'
import { NotFoundError } from '@/errors/notFoundError'
import CryptoJS from 'crypto-js'

import apiRouter from '@/apps/go/apiRouter'
import router from '@/router/router'
import { RouteLocationRaw } from 'vue-router'

const API = import.meta.env.VITE_API

type SendOptionsType = { method?: string; path?: any; body?: any; data?: any }

async function send(options: SendOptionsType) {
	const config = {
		headers: {},
		...options
	}

	if (auth.value && auth.value.token) {
		config.headers['authorization'] = `Bearer ${auth.value.token}`
	}

	if (options.data) {
		config.headers['content-type'] = typeof options.data == 'string' ? 'text/plain' : 'application/json'
		config.body = typeof options.data == 'string' ? options.data : JSON.stringify(options.data)
	}

	let res = null
	try {
		res = await fetch(`${API}${config.path}`, config).then(response =>
			response.json().then(body => ({
				status: response.status,
				...body
			}))
		)
	} catch (error) {
		throw new InternalServerError(error.message, error)
	}

	if (res.status === 400) {
		throw new ValidationError(res.message || 'Ha ocurrido un error.', res.errors || [])
	}
	if (res.status === 401) {
		if (!['/logout', '/login'].includes(window.location.pathname)) {
			return await router.push({ name: 'go.logout', force: true, query: { authenticationRequired: (!!res?.data?.authenticationRequired).toString() } })
		}
	}
	if (res.status === 403) {
		if (!['/'].includes(window.location.pathname)) {
			window.alert(res.message)
			return await router.push({ name: 'go.map' })
		}
	}
	if (res.status === 404) {
		throw new NotFoundError(res.message || 'No se ha encontrado el recurso solicitado.')
	}
	if (res.status === 500) {
		throw new InternalServerError('Ha ocurrido un error inesperado.')
	}

	return res
}

function encode(str: string) {
	return CryptoJS.AES.encrypt(str, atob(auth.value.key)).toString()
}

export async function login(credentials: { email: string; password: string; recaptcha?: string }) {
	return post(`/auth/login`, credentials)
}

export async function get(options: RouteLocationRaw) {
	try {
		const route = apiRouter.resolve(options)
		return send({ method: 'GET', path: route.fullPath })
	} catch (error) {
		console.log(error)
	}
}

export async function del(options: RouteLocationRaw, data: Record<string, any> = {}) {
	try {
		const route = apiRouter.resolve(options)
		return send({ method: 'DELETE', path: route.fullPath, data })
	} catch (error) {
		console.log(error)
	}
}

export async function post(options: RouteLocationRaw, data: Record<string, any> = {}, encrypt = false) {
	try {
		const route = apiRouter.resolve(options)
		const payload = encrypt ? await encode(JSON.stringify(data)) : data
		return send({ method: 'POST', path: route.fullPath, data: payload })
	} catch (error) {
		console.log(error)
	}
}

export async function put(options: RouteLocationRaw, data: Record<string, any> = {}, encrypt = false) {
	try {
		const route = apiRouter.resolve(options)
		const payload = encrypt ? await encode(JSON.stringify(data)) : data
		return send({ method: 'PUT', path: route.fullPath, data: payload })
	} catch (error) {
		console.log(error)
	}
}
