import { ValidationRuleObject } from 'fastest-validator'
import objectPath from 'object-path'
import { validator } from '../validator'

export const customNumber: ValidationRuleObject = {
	type: 'custom',
	async check(
		value: any,
		errors: any[],
		checker: any,
		path: string,
		data: Record<string, any>,
		context: Record<string, any>
	) {
		let min =
			typeof checker.min === 'string'
				? objectPath.get(context, checker.min, undefined)
				: checker.min
		let max =
			typeof checker.max === 'string'
				? objectPath.get(context, checker.max, undefined)
				: checker.max
		if (min !== undefined && checker.modifyMin) {
			min = min + checker.modifyMin[0] * checker.modifyMin[1]
		}
		if (max !== undefined && checker.modifyMax) {
			max = max + checker.modifyMax[0] * checker.modifyMax[1]
		}

		const schema = {
			[path]: {
				type: 'number',
				positive: checker.positive,
				min,
				max
			}
		}

		if (typeof value === 'string') {
			value = Number(value.replace(',', ''))
		}

		const requestValidation = await validator.validate(
			{ [path]: value },
			schema
		)

		if (requestValidation.length) {
			errors.push(...requestValidation)
			return value
		}

		if (
			typeof checker.lowerThan === 'string' &&
			value >= objectPath.get(context, checker.lowerThan)
		) {
			errors.push({
				message: `Valor no por debajo del especificado`,
				type: 'lowerThanValueExceeded',
				actual: value
			})
		}

		if (checker.biggerThan !== undefined) {
			const biggerThan =
				typeof checker.biggerThan === 'string'
					? objectPath.get(context, checker.biggerThan)
					: checker.biggerThan
			if (value <= biggerThan) {
				errors.push({
					message: `Valor no superior al especificado`,
					type: 'biggerThanValueExceeded',
					actual: value
				})
			}
		}

		if (
			typeof checker.equal === 'string' &&
			value != objectPath.get(context, checker.equal)
		) {
			errors.push({
				message: `Valor no equivalente al especificado`,
				type: 'equalValueExceeded',
				actual: value
			})
		}

		if (
			checker.requiredCondition &&
			checker.requiredCondition.value ==
				objectPath.get(context, checker.requiredCondition.param) &&
			data.status.amount == undefined
		) {
			errors.push({
				message: `Este campo es requerido`,
				type: 'required',
				actual: value
			})
		}

		return value
	}
}
