import { routes } from '@/apps/go/router'
import authMiddleware from '@/middlewares/authMiddleware'
import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()
const router = createRouter({
	history: routerHistory,
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth) {
		authMiddleware({ next, router })
	}
	else if (to.meta.env && import.meta.env.VITE_ENVIRONMENT !== to.meta.env) {
		return next('/')
	}
	else return next()
})

export default router
