<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button icon="ri-arrow-left-line" color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center" v-if="payload">
							<Button :loading="saving" :disabled="saving" @click="save">
								<icon name="ri-save-2-line" class="mr-1" />
								Guardar cambios
							</Button>
						</div>
					</div>
				</div>

				<div class="grow h-full p-3 bg-gray-100" v-if="payload">
					<Banner type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Usuario {{ route.query.user ? 'actualizado' : 'creado' }} correctamente </Banner>
					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-user-line" class="text-gray-400 mr-1" />
								<div>Datos de usuario</div>
							</div>
							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div>
											<!-- Start -->
											<div class="flex items-center justify-between">
												<label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-red-500">*</span></label>
											</div>
											<input class="form-input w-full" type="text" name="name" v-model="payload.name" id="name" required="true" :class="{ 'border-red-600': !!getErrorMessage('name') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('name') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="surname">Apellidos <span class="text-red-500">*</span></label
											><input class="form-input w-full" type="text" name="surname" v-model="payload.surname" id="surname" required="true" :class="{ 'border-red-600': !!getErrorMessage('surname') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('surname') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="email">Email <span class="text-red-500">*</span></label
											><input class="form-input w-full" type="email" name="email" v-model="payload.email" id="email" required="true" :class="{ 'border-red-600': !!getErrorMessage('email') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('email') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->
											<label class="block text-sm font-medium mb-1" for="position">Posición <span class="text-red-500">*</span></label>
											<input class="form-input w-full" type="text" name="position" v-model="payload.position" id="position" required="true" :class="{ 'border-red-600': !!getErrorMessage('position') }" />
											<p class="text-red-600">
												<small>{{ getErrorMessage('position') }}</small>
											</p>
											<!-- End -->
										</div>
										<div>
											<!-- Start -->

											<label class="block text-sm font-medium mb-1" for="position">Rol <span class="text-red-500">*</span></label>
											<select name="role" id="position" class="form-select w-full" v-model="payload.role" required :class="{ 'border-red-600': !!getErrorMessage('role') }">
												<option value="" disabled selected>Seleccionar</option>
												<option v-for="role in roles" :value="role._id">{{ role.label }}</option>
											</select>
											<p class="text-red-600">
												<small>{{ getErrorMessage('role') }}</small>
											</p>

											<!-- End -->
										</div>
										<div>
											<label class="block text-sm font-medium mb-1" for="organization">Organización <span class="text-red-500">*</span></label>

											<select name="organization" id="organization" class="form-select w-full" v-model="payload.organization" :class="{ 'border-red-600': !!getErrorMessage('organization') }">
												<option value="" disabled selected>Seleccionar</option>
												<option v-for="organization in organizations" :value="organization._id">{{ organization.name }}</option>
											</select>
											<p class="text-red-600">
												<small>{{ getErrorMessage('organization') }}</small>
											</p>
										</div>
										<div>
											<label class="block text-sm font-medium mb-1" for="territory">Territorio<span class="text-red-500">*</span></label>
											<select name="territory" id="territory" class="form-select w-full" v-model="payload.territory" :class="{ 'border-red-600': !!getErrorMessage('territory') }">
												<option value="" disabled selected>Seleccionar</option>
												<option v-for="territory in territories" :value="territory.name">{{ territory.label }}</option>
											</select>
											<p class="text-red-600">
												<small>{{ getErrorMessage('territory') }}</small>
											</p>
										</div>
										<div>
											<label class="block text-sm font-medium mb-1" for="networkType">Tipo de red<span class="text-red-500">*</span></label>
											<select name="networkType" id="networkType" class="form-select w-full" v-model="payload.networkType" :class="{ 'border-red-600': !!getErrorMessage('networkType') }">
												<option value="" disabled selected>Seleccionar</option>
												<option v-for="networkType in networkTypes" :value="networkType.name">{{ networkType.label }}</option>
											</select>
											<p class="text-red-600">
												<small>{{ getErrorMessage('networkType') }}</small>
											</p>
										</div>
										<div class="flex flex-col">
											<div class="text-sm font-medium mb-1">Proyectos <span class="text-red-500">*</span></div>
											<Multiselect name="projects" id="projects" v-model="selectedProjects" track-by="name" label="label" placeholder="Seleccionar proyectos" :options="projects" :showNoResults="true" :clearOnSelect="false" :closeOnSelect="false" :preserveSearch="true" :hideSelected="true" :multiple="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para eliminar" :class="{ 'border border-red-600': !!getErrorMessage('projects') }">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado proyectos. </template>
											</Multiselect>
											<p class="text-red-600">
												<small>{{ getErrorMessage('projects') }}</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div class="w-full h-full relative md:flex">
						<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
							<div class="bg-white rounded-sm border border-gray-200 mb-3">
								<!-- Page header -->
								<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
									<icon :name="`ri-group-line`" class="text-gray-400 mr-1" />
									<div>¿A qué grupos pertenece?</div>
									<Button v-if="userGroups?.length" size="xs" class="text-xs ml-2" @click="toggleAllBelongsTo">{{ payload.belongsTo?.length === userGroups?.length ? 'Quitar selección' : 'Seleccionar todos' }}</Button>
								</div>
								<div class="p-3" v-if="!loading">
									<div class="grid text-sm gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div v-for="userGroup in userGroups" :key="userGroup.label">
											<label class="flex items-center">
												<input v-model="payload.belongsTo" :value="userGroup._id" type="checkbox" class="form-checkbox" />
												<span class="text-sm ml-2">{{ userGroup.label }} ({{ userGroup.usersCount }})</span>
											</label>
										</div>
									</div>
									<Banner v-if="!userGroups?.length" :open="true" type="warning" :fixed="true" class="mb-3">Selecciona uno o vario proyectos para poder buscar los grupos correspondientes.</Banner>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full h-full relative md:flex">
						<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
							<div class="bg-white rounded-sm border border-gray-200 mb-3">
								<!-- Page header -->
								<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
									<icon :name="`ri-group-line`" class="text-gray-400 mr-1" />
									<div>¿Qué grupos puede ver?</div>
									<Button v-if="userGroups?.length" size="xs" class="text-xs ml-2" @click="toggleAllGroups">{{ payload.groups?.length === userGroups?.length ? 'Quitar selección' : 'Seleccionar todos' }}</Button>
								</div>
								<div class="p-3" v-if="!loading">
									<div class="grid text-sm gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div v-for="userGroup in userGroups" :key="userGroup.label">
											<label class="flex items-center">
												<input v-model="payload.groups" :value="userGroup._id" type="checkbox" class="form-checkbox" />
												<span class="text-sm ml-2">{{ userGroup.label }} ({{ userGroup.usersCount }})</span>
											</label>
										</div>
									</div>
									<Banner v-if="!userGroups?.length" :open="true" type="warning" :fixed="true" class="mb-3">Selecciona uno o vario proyectos para poder buscar los grupos correspondientes.</Banner>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full h-full relative md:flex">
						<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
							<div class="bg-white rounded-sm border border-gray-200 mb-3">
								<!-- Page header -->
								<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
									<icon :name="`ri-group-line`" class="text-gray-400 mr-1" />
									<div>¿Qué usuarios específicos puede ver?</div>
								</div>
								<div class="p-3" v-if="!loading">
									<div class="flex flex-col">
										<div class="flex gap-4 h-full">
											<Multiselect :multiple="true" name="usersFilter" v-model="selectedUsers" track-by="_id" :custom-label="u => `${u.name} ${u.surname}`" placeholder="Seleccionar usuarios" :closeOnSelect="true" :options="users" :showNoResults="true" :clearOnSelect="false" :preserveSearch="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
												<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
												<template #noOptions>No se han encontrado usuarios. </template>
											</Multiselect>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="w-full h-full relative md:flex" v-if="_id">
						<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
							<div class="bg-white rounded-sm border border-gray-200 mb-3">
								<!-- Page header -->
								<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
									<icon :name="`ri-user-settings-line`" class="text-gray-400 mr-1" />
									<div>Acciones de usuario</div>
								</div>
								<div class="p-3" v-if="!loading">
									<div v-if="dealsCount && payload.active">
										<Banner :open="true" type="warning" :fixed="true" class="mb-3"
											>El usuario tiene <b>{{ dealsCount }}</b> oportunidades y no es posible desactivarlo. Primero deberás reasignar las oportunidades a otro usuario.</Banner
										>
										<Button
											@click="
												$router.push({
													name: 'go.opportunities',
													query: {
														user: payload._id,
														project: payload.projects[0]
													}
												})
											"
											>Reasignar Deals</Button
										>
									</div>
									<div v-else>
										<Button @click="activateDeactivateUser" :color="payload.active ? 'danger' : 'success'">{{ !payload.active ? 'Activar' : 'Desactivar' }} usuario</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { onMounted, reactive, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute } from 'vue-router'
import Button from '../../components/Button.vue'
import { User } from '../../interfaces/User'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const route = useRoute()
const error = ref<ValidationError>(<any>{})
const projects = ref([])
const roles = ref([])
const users = ref([])
const userGroups = ref([])
const selectedProjects: Record<string, any> = ref([])
const selectedUsers = ref([])
const organizations = ref([])
const territories = ref([])
const networkTypes = ref([])
const loading = ref(false)
const saving = ref(false)
const isSaved = ref(false)
const _id = route.query.user

let payload = reactive<User>(<any>{ groups: [], permissions: [], belongsTo: [], projects: [], users: [] })

const getErrorMessage = useValidationErrorsMessages(error)

const dealsCount = ref(0)

const toggleAllBelongsTo = () => {
	payload.belongsTo = payload.belongsTo.length === userGroups.value.length ? [] : userGroups.value.map(group => group._id)
}
const toggleAllGroups = () => {
	payload.groups = payload.groups?.length === userGroups.value.length ? [] : userGroups.value.map(group => group._id)
}

const getUserDealsCount = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		if (payload.projects) {
			for (const project of <Array<string>>payload.projects) {
				const { data } = await get({ name: 'deal.count', params: { project }, query: { user: route.query.user, finishedAt: 'false' } })
				dealsCount.value += data.count
			}
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const back = () => {
	history.back()
}
const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		const { data } = await get({ name: 'project', query: { projection: 'name,label' } })
		projects.value = data
		if (payload.projects?.length) {
			selectedProjects.value = data.filter(project => payload.projects?.includes(project.name))
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const fetchRoles = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		const { data } = await get({ name: 'role', query: { projection: 'label' } })
		roles.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const fetchOrganizations = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'organization', query: { sort: 'name' } })
		organizations.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchTerritories = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'territory', query: { projection: 'label,name' } })
		territories.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchNetworkTypes = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'network-type', query: { projection: 'label,name' } })
		networkTypes.value = data
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchUsers = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		const { data } = await get({ name: 'user', query: { limit: 5999, sort: 'name,surname', projection: 'name,surname' } })
		users.value = data
		if (payload.users?.length) {
			selectedUsers.value = data.filter(user => payload.users?.includes(user._id))
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchUserGroups = async () => {
	loading.value = true
	userGroups.value = []
	error.value = <any>{}

	try {
		if (selectedProjects.value.length) {
			payload.projects = selectedProjects.value.map(p => p.name)
			const { data } = await get({ name: 'group', query: { projection: 'label,users', project: decodeURIComponent(payload.projects.join(',')) } })
			userGroups.value = data
			payload.groups = userGroups.value.filter(g => payload.groups?.includes(g._id)).map(g => g._id)
			payload.belongsTo = userGroups.value?.filter(g => payload.belongsTo?.includes(g._id)).map(g => g._id)
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}
const fetch = async () => {
	loading.value = true
	error.value = <any>{}

	try {
		const { data } = await get({ name: 'user', params: { _id: route.query.user } })
		payload = reactive(data)
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

onMounted(async () => {
	payload.groups = []
	payload.belongsTo = []
	if (_id) {
		await fetch()
		getUserDealsCount()
	}
	await fetchOrganizations()
	await fetchTerritories()
	await fetchNetworkTypes()
	fetchProjects()
	fetchUsers()
	fetchRoles()
})

const onCloseBanner = () => {
	isSaved.value = false
}

const save = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}
	payload.groups = userGroups.value.filter(g => payload.groups?.includes(g._id)).map(g => g._id)
	payload.belongsTo = userGroups.value?.filter(g => payload.belongsTo?.includes(g._id)).map(g => g._id)
	payload.projects = selectedProjects.value.map(p => p.name)
	payload.users = selectedUsers.value.map(u => u._id)
	try {
		if (_id) {
			await put({ name: 'user', params: { _id } }, payload)
			fetch()
			getUserDealsCount()
		} else {
			await post({ name: 'user' }, payload)
			payload = reactive(<any>{ groups: [], permissions: [] })
			selectedProjects.value = []
			selectedUsers.value = []
		}
	} catch (err) {
		error.value = err
	}
	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}

const activateDeactivateUser = async () => {
	loading.value = true
	saving.value = true
	isSaved.value = false
	error.value = <any>{}
	try {
		// is Active
		if (payload?.active) {
			await put({ name: 'user.deactivate', params: { _id: route.query.user } })
			payload.active = false
		} else {
			await put({ name: 'user.activate', params: { _id: route.query.user } })
			payload.active = true
		}
	} catch (err) {
		error.value = err
	}
	fetch()
	getUserDealsCount()
	isSaved.value = !Boolean(error.value?.errors?.length)
	saving.value = false
	loading.value = false
}

watch(selectedProjects, (a, b) => {
	fetchUserGroups()
})
</script>
