<template>
	<div class="px-6 py-2 bg-gray-50 border border-gray-200 rounded-sm">
		<div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
			<nav class="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
				<ul class="flex justify-center">
					<li class="ml-3 first:ml-0">
						<Button color="secondary" size="sm" @click="prev" :disabled="page == 1">
							<icon name="ri-arrow-left-line" class="mr-1" />
							Anterior
						</Button>
					</li>
					<li class="ml-3 first:ml-0">
						<Button color="secondary" size="sm" @click="next" :disabled="page == pages">
							Siguiente
							<icon name="ri-arrow-right-line" class="ml-1" />
						</Button>
					</li>
				</ul>
			</nav>
			<div class="text-sm text-gray-500 text-center sm:text-left">
				Mostrando
				<span class="font-medium text-gray-600">{{ skip + 1 }}</span> a
				<span class="font-medium text-gray-600">{{ skip + results }}</span>
				de <span class="font-medium text-gray-600">{{ total }}</span> resultados
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import Button from './Button.vue'

const props = defineProps({
	results: Number,
	limit: Number,
	skip: Number,
	total: Number
})

const page = ref(1)
const pages = ref(1)

const emit = defineEmits(['page'])

const update = () => {
	page.value = props.skip >= props.total ? -1 : Math.ceil(props.skip / props.limit) + 1
	pages.value = Math.ceil(props.total / props.limit)
}

watch(props, () => {
	update()
})

const prev = () => {
	page.value--
	emit('page', page)
}

const next = () => {
	page.value++
	emit('page', page)
}

onMounted(() => {
	update()
})
</script>
